import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MapPin, Phone, Mail, ArrowRight } from 'lucide-react';
import PhoneInput from './PhoneInput';
import LocationMap from './LocationMap';
import ClickToCall from './ClickToCall';

const API_URL = process.env.REACT_APP_API_URL || '';

const ContactPage = () => {
  const [allCommunities, setAllCommunities] = useState([]);
  const [formData, setFormData] = useState({
    contact: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      message: '',
      consent: false
    }
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    const fetchCommunities = async () => {
      try {
        const response = await fetch(`${API_URL}/api/communities/public`);
        if (response.ok) {
          const data = await response.json();
          setAllCommunities(data.communities);
        }
      } catch (error) {
        console.error('Error fetching communities:', error);
      }
    };

    fetchCommunities();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await fetch(`${API_URL}/api/leads`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          source: 'contact_page',
          hubspot: {
            community_code: 'contact',
            manager_phone: '(425) 659-3432',
            manager_email: 'info@aegisliving.com',
            manager: 'Aegis Living'
          }
        })
      });

      if (!response.ok) {
        throw new Error('Failed to submit form');
      }

      setIsSubmitted(true);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Error submitting form. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  if (isSubmitted) {
    return (
      <div className="min-h-screen bg-gray-50">
        <div className="max-w-4xl mx-auto px-4 py-16 text-center">
          <div className="bg-white rounded-lg shadow-lg p-8">
            <div className="mb-6">
              <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-4">
                <svg className="w-8 h-8 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
              </div>
              <h2 className="text-3xl font-bold text-gray-900 mb-4">Thank You!</h2>
              <p className="text-lg text-gray-600">
                We've received your message and will get back to you shortly.
              </p>
            </div>
            <Link
              to="/locations"
              className="inline-flex items-center gap-2 bg-aegis-blue text-white px-6 py-3 rounded-lg hover:bg-aegis-blue/90 transition-colors"
            >
              Find a Community Near You
              <ArrowRight size={20} />
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <div className="relative h-[300px] md:h-[400px] overflow-hidden">
        <img 
          src="https://www.aegisliving.com/wp-content/uploads/2023/07/financial-considerations-hero.jpg.webp"
          alt="Contact Aegis Living"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black/40 flex items-center">
          <div className="max-w-7xl mx-auto px-4 text-white">
            <h1 className="text-4xl md:text-5xl font-bold mb-4">
              Contact Us
            </h1>
            <p className="text-xl md:text-2xl max-w-2xl">
              We're here to help answer your questions about senior living and care options.
            </p>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 py-12">
        <div className="max-w-5xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Contact Form */}
            <div>
              <h2 className="text-3xl font-bold mb-6">Send Us a Message</h2>
              <form onSubmit={handleSubmit} className="space-y-6">
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      First Name
                    </label>
                    <input
                      type="text"
                      required
                      value={formData.contact.firstName}
                      onChange={(e) => setFormData(prev => ({
                        ...prev,
                        contact: { ...prev.contact, firstName: e.target.value }
                      }))}
                      className="w-full px-4 py-2 border rounded-lg"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Last Name
                    </label>
                    <input
                      type="text"
                      required
                      value={formData.contact.lastName}
                      onChange={(e) => setFormData(prev => ({
                        ...prev,
                        contact: { ...prev.contact, lastName: e.target.value }
                      }))}
                      className="w-full px-4 py-2 border rounded-lg"
                    />
                  </div>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Email
                  </label>
                  <input
                    type="email"
                    required
                    value={formData.contact.email}
                    onChange={(e) => setFormData(prev => ({
                      ...prev,
                      contact: { ...prev.contact, email: e.target.value }
                    }))}
                    className="w-full px-4 py-2 border rounded-lg"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Phone
                  </label>
                  <PhoneInput
                    value={formData.contact.phone}
                    onChange={(value) => setFormData(prev => ({
                      ...prev,
                      contact: { ...prev.contact, phone: value }
                    }))}
                    className="w-full px-4 py-2 border rounded-lg"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Message
                  </label>
                  <textarea
                    rows={4}
                    value={formData.contact.message}
                    onChange={(e) => setFormData(prev => ({
                      ...prev,
                      contact: { ...prev.contact, message: e.target.value }
                    }))}
                    className="w-full px-4 py-2 border rounded-lg"
                  />
                </div>
                <label className="flex items-start gap-2">
                  <input
                    type="checkbox"
                    required
                    checked={formData.contact.consent}
                    onChange={(e) => setFormData(prev => ({
                      ...prev,
                      contact: { ...prev.contact, consent: e.target.checked }
                    }))}
                    className="mt-1"
                  />
                  <span className="text-sm text-gray-600">
                    I agree to be contacted about Aegis Living communities and understand that my consent isn't required to learn more.
                  </span>
                </label>
                <button
                  type="submit"
                  disabled={isLoading}
                  className={`w-full py-3 rounded-lg text-white font-medium
                    ${isLoading ? 'bg-gray-400' : 'bg-aegis-blue hover:bg-aegis-blue/90'}
                    transition-colors
                  `}
                >
                  {isLoading ? 'Sending...' : 'Send Message'}
                </button>
              </form>
            </div>

            {/* Contact Info */}
            <div className="bg-gray-50 p-8 rounded-lg">
              <h2 className="text-3xl font-bold mb-6">Get in Touch</h2>
              <div className="space-y-6">
                <div className="flex items-start gap-4">
                  <Phone className="w-6 h-6 text-aegis-blue flex-shrink-0" />
                  <div>
                    <h3 className="font-semibold mb-1">Call Us</h3>
                    <a href="tel:(425) 659-3432" className="text-aegis-blue hover:underline">
                      (425) 659-3432
                    </a>
                  </div>
                </div>
                <div className="flex items-start gap-4">
                  <Mail className="w-6 h-6 text-aegis-blue flex-shrink-0" />
                  <div>
                    <h3 className="font-semibold mb-1">Email Us</h3>
                    <a href="mailto:info@aegisliving.com" className="text-aegis-blue hover:underline">
                      info@aegisliving.com
                    </a>
                  </div>
                </div>
                <div className="flex items-start gap-4">
                  <MapPin className="w-6 h-6 text-aegis-blue flex-shrink-0" />
                  <div>
                    <h3 className="font-semibold mb-1">Corporate Office</h3>
                    <p>
                      800 Bellevue Way NE<br />
                      Suite 200<br />
                      Bellevue, WA 98004
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Communities Map */}
      <div className="mt-12">
        <div className="max-w-7xl mx-auto px-4 mb-8">
          <h2 className="text-3xl font-bold text-center">Our Locations</h2>
          <p className="text-xl text-gray-600 text-center mt-2">
            Find an Aegis Living community near you
          </p>
        </div>
        <div className="h-[600px]">
          <LocationMap
            allCommunities={allCommunities}
            showAllLocations={true}
            location={{
              coordinates: {
                lat: 47.6062,
                lng: -122.3321
              }
            }}
            fitAllMarkers={true}
            hideOverlay={true}
          />
        </div>
      </div>

      <ClickToCall />
    </div>
  );
};

export default ContactPage; 