import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Building2, Pencil, Menu, X, Map } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';

const API_URL = process.env.REACT_APP_API_URL || '';

const AdminToolbar = ({ communitySlug }) => {
  const { isAuthenticated } = useAuth();
  const [community, setCommunity] = useState(null);
  const [region, setRegion] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  
  const isCommunityPage = location.pathname.startsWith('/community/') && !location.pathname.includes('/region/');
  const isRegionPage = location.pathname.includes('/community/region/');
  const regionSlug = isRegionPage ? location.pathname.split('/region/')[1] : null;

  useEffect(() => {
    if (communitySlug && !isRegionPage) {
      fetchCommunity();
    }
    if (regionSlug) {
      fetchRegion();
    }
  }, [communitySlug, regionSlug]);

  const fetchCommunity = async () => {
    try {
      const response = await fetch(`${API_URL}/api/community/${communitySlug}`);
      if (response.ok) {
        const data = await response.json();
        setCommunity(data);
      }
    } catch (error) {
      console.error('Error fetching community:', error);
    }
  };

  const fetchRegion = async () => {
    try {
      const response = await fetch(`${API_URL}/api/regions/${regionSlug}`);
      if (response.ok) {
        const data = await response.json();
        setRegion(data);
      }
    } catch (error) {
      console.error('Error fetching region:', error);
    }
  };

  if (!isAuthenticated) return null;

  return (
    <div className="bg-gray-900 text-white">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex items-center justify-between h-12">
          {/* Mobile Menu Button */}
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="md:hidden p-2 hover:bg-gray-800 rounded"
          >
            {isMenuOpen ? <X size={20} /> : <Menu size={20} />}
          </button>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-6">
            <Link to="/admin" className="hover:text-gray-300">Dashboard</Link>
            <Link to="/admin/leads" className="hover:text-gray-300">Leads</Link>
            <Link to="/admin/communities" className="hover:text-gray-300">Communities</Link>
            <Link to="/admin/regions" className="hover:text-gray-300">Regions</Link>
            <Link to="/admin/settings" className="hover:text-gray-300">Settings</Link>
          </div>

          {/* Mobile Navigation */}
          {isMenuOpen && (
            <div className="absolute top-12 left-0 right-0 bg-gray-900 md:hidden z-50">
              <div className="flex flex-col p-4 space-y-3">
                <Link 
                  to="/admin" 
                  className="hover:text-gray-300 px-4 py-2 hover:bg-gray-800 rounded"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Dashboard
                </Link>
                <Link 
                  to="/admin/leads" 
                  className="hover:text-gray-300 px-4 py-2 hover:bg-gray-800 rounded"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Leads
                </Link>
                <Link 
                  to="/admin/communities" 
                  className="hover:text-gray-300 px-4 py-2 hover:bg-gray-800 rounded"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Communities
                </Link>
                <Link 
                  to="/admin/regions" 
                  className="hover:text-gray-300 px-4 py-2 hover:bg-gray-800 rounded"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Regions
                </Link>
                <Link 
                  to="/admin/settings" 
                  className="hover:text-gray-300 px-4 py-2 hover:bg-gray-800 rounded"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Settings
                </Link>
              </div>
            </div>
          )}

          {/* Community Edit Links */}
          {isCommunityPage && community && (
            <div className="flex items-center gap-4">
              <Link
                to="/admin/communities"
                className="flex items-center gap-2 text-white hover:text-gray-300"
                title="View All Communities"
              >
                <Building2 size={20} />
                <span className="hidden sm:inline">All Communities</span>
              </Link>
              <Link
                to={`/admin/communities/edit/${community.slug}`}
                className="flex items-center gap-2 text-white hover:text-gray-300"
                title="Edit Community"
              >
                <Pencil size={20} />
                <span className="hidden sm:inline">Edit Community</span>
              </Link>
            </div>
          )}

          {/* Region Edit Links */}
          {isRegionPage && region && (
            <div className="flex items-center gap-4">
              <Link
                to="/admin/regions"
                className="flex items-center gap-2 text-white hover:text-gray-300"
                title="View All Regions"
              >
                <Map size={20} />
                <span className="hidden sm:inline">All Regions</span>
              </Link>
              <Link
                to={`/admin/regions/edit/${region._id}`}
                className="flex items-center gap-2 text-white hover:text-gray-300"
                title="Edit Region"
              >
                <Pencil size={20} />
                <span className="hidden sm:inline">Edit Region</span>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminToolbar; 