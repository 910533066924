import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import { ArrowRight, Phone, Video, Loader2, Calendar, Heart, Home, MapPin, Layout, X, BedSingle, Bed, Users, ChevronLeft, ChevronRight, Clock, Utensils, Flower2, Languages, Waves, Sofa, Leaf, Dumbbell, Building2, Crown, Brain, Clapperboard, Trees, UtensilsCrossed, ShoppingBag, Building, Stethoscope, HeartPulse, Activity, Palmtree, Dog, Umbrella, Flag, Scissors, Trophy, HardHat, Footprints, Menu, Mail, Info } from 'lucide-react';
import BackgroundDecoration from './BackgroundDecoration';
import LocationMap from './LocationMap';
import { calculateDistance } from '../utils/distance';
import SuccessPage from './SuccessPage';

const AMENITY_ICONS = {
  '24/7 Care': Clock,
  'All-Day Dining': Utensils,
  'Asian Inspired': Flower2,
  'Bilingual': Languages,
  'Close to Ocean': Waves,
  'Companion Suite': Users,
  'Cottage Style': Home,
  'Cozy': Sofa,
  'Eco Friendly': Leaf,
  'Fitness Center': Dumbbell,
  'Gardening Space': Flower2,
  'Intimate Setting': Heart,
  'Large Building': Building2,
  'Luxury': Crown,
  'Memory Care Only': Brain,
  'Movie Theater': Clapperboard,
  'Nature Lovers': Trees,
  'Nearby Restaurants': UtensilsCrossed,
  'Nearby Shopping': ShoppingBag,
  'New Building': Building,
  'One Bedroom': Bed,
  'Onsite Nurses 24/7': Stethoscope,
  'Onsite Nurses Daily': HeartPulse,
  'Onsite PT Center': Activity,
  'Patio': Palmtree,
  'Pets': Dog,
  'Pool': Umbrella,
  'Putting Green': Flag,
  'Residential Area': Home,
  'Salon': Scissors,
  'Sports Den': Trophy,
  'Studio': BedSingle,
  'Two Bedrooms': Bed,
  'Under Construction': HardHat,
  'Urban': Building2,
  'Walking Paths': Footprints
};

const RegionalTemplate = ({ regionData }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [navItems, setNavItems] = useState([]);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [userLocation, setUserLocation] = useState(null);
  const [sortedCommunities, setSortedCommunities] = useState([]);
  const formRef = useRef(null);
  const [step, setStep] = useState(2);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [successData, setSuccessData] = useState(null);
  const [formData, setFormData] = useState({
    location: regionData.name,
    relationship: "",
    careType: "",
    timeline: "",
    livingArrangement: "",
    interests: [],
    contact: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      consent: false
    },
    source: `region-${regionData.slug}`,
    hubspot: {
      community_code: regionData.manager.community_code,
      manager_phone: regionData.manager.phone,
      manager_email: regionData.manager.email,
      manager: regionData.manager.name
    },
    utm: {
      source: '',
      medium: '',
      campaign: '',
      term: '',
      content: '',
      gclid: '',
      referrer: '',
      landing_page: ''
    }
  });
  const [showPhoneTooltip, setShowPhoneTooltip] = useState(false);
  const [showEmailTooltip, setShowEmailTooltip] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  // Set up navigation items
  useEffect(() => {
    const items = [
      { id: 'communities-section', label: 'Communities' }
    ];

    if (regionData.features?.length > 0) {
      items.push({ id: 'features-section', label: 'Features' });
    }

    setNavItems(items);
  }, [regionData]);

  // Handle image rotation for header
  useEffect(() => {
    if (regionData.communities?.length > 0) {
      const allImages = regionData.communities.flatMap(c => c.galleryImages || []);
      if (allImages.length > 0) {
        const interval = setInterval(() => {
          setCurrentImageIndex((prev) => (prev + 1) % allImages.length);
        }, 5000);
        return () => clearInterval(interval);
      }
    }
  }, [regionData]);

  // Update the useEffect for location and sorting
  useEffect(() => {
    // Show communities immediately
    setSortedCommunities(regionData.communities || []);

    // Then try to get location and update order
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const userCoords = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          setUserLocation(userCoords);

          // Sort communities by distance
          const communitiesWithDistance = regionData.communities.map(community => ({
            ...community,
            distance: calculateDistance(
              userCoords.lat,
              userCoords.lng,
              community.location?.coordinates?.lat,
              community.location?.coordinates?.lng
            )
          }));

          communitiesWithDistance.sort((a, b) => a.distance - b.distance);
          setSortedCommunities(communitiesWithDistance);
        },
        (error) => {
          console.error('Error getting location:', error);
          // Keep existing order if location access fails
        }
      );
    }
  }, [regionData.communities]);

  // Get current header image
  const getCurrentHeaderImage = () => {
    const allImages = regionData.communities?.flatMap(c => c.galleryImages || []) || [];
    return allImages[currentImageIndex] || '';
  };

  // Add all the form handling functions from CommunityTemplate
  const handleInputChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleContactChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      contact: {
        ...prev.contact,
        [field]: value
      }
    }));
  };

  const handleInterestToggle = (interest) => {
    setFormData(prev => ({
      ...prev,
      interests: prev.interests.includes(interest)
        ? prev.interests.filter(i => i !== interest)
        : [...prev.interests, interest]
    }));
  };

  // Add the canProceed, prevStep, nextStep functions from CommunityTemplate
  // Add the renderProgressBar and renderCurrentStep functions from CommunityTemplate

  const renderProgressBar = () => (
    <div className="w-full bg-gray-100 h-2 rounded-full mb-6">
      <div 
        className="bg-aegis-blue h-2 rounded-full transition-all duration-500"
        style={{ width: `${((step - 1) / 5) * 100}%` }}
      />
    </div>
  );

  const canProceed = () => {
    switch(step) {
      case 2: return formData.relationship.length > 0;
      case 3: return formData.careType.length > 0;
      case 4: return formData.timeline.length > 0;
      case 5: return formData.interests.length > 0;
      case 6: return (
        formData.contact.firstName.trim() && 
        formData.contact.lastName.trim() && 
        validateEmail(formData.contact.email) &&
        validatePhone(formData.contact.phone) &&
        formData.contact.consent
      );
      default: return false;
    }
  };

  const prevStep = () => {
    setStep(prev => prev - 1);
    // On mobile, scroll to top of form after step change
    if (window.innerWidth < 768) {
      formRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const nextStep = async () => {
    if (step === 6) {
      // Handle form submission
      try {
        const response = await fetch(`${API_URL}/api/leads`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formData)
        });

        if (!response.ok) throw new Error('Failed to submit form');
        
        const data = await response.json();
        setSuccessData(data);
        setIsSubmitted(true);
      } catch (error) {
        console.error('Error submitting form:', error);
        alert('There was an error submitting the form. Please try again.');
      }
    } else {
      setStep(prev => prev + 1);
      // On mobile, scroll to top of form after step change
      if (window.innerWidth < 768) {
        formRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  };

  const renderCurrentStep = () => {
    switch(step) {
      case 2:
        return (
          <div className="space-y-6 animate-fadeIn">
            <div className="text-center mb-8">
              <Heart className="w-12 h-12 mx-auto text-aegis-blue mb-4" />
              <h2 className="text-2xl font-semibold">What is your relationship to the future resident?</h2>
              <p className="text-gray-600 mt-2">Select the option that best describes you</p>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
              {[
                {
                  title: "Self",
                  description: "I'm looking for myself",
                  tooltip: "For individuals seeking senior living options for themselves"
                },
                {
                  title: "Parent",
                  description: "I'm looking for my parent",
                  tooltip: "For those seeking care options for their parent(s)"
                },
                {
                  title: "Spouse",
                  description: "I'm looking for my spouse",
                  tooltip: "For those seeking care options for their spouse or partner"
                },
                {
                  title: "Other Family",
                  description: "I'm looking for another family member",
                  tooltip: "For those seeking care for other family members"
                }
              ].map((option) => (
                <button
                  key={option.title}
                  onClick={() => {
                    handleInputChange('relationship', option.title);
                    nextStep();
                  }}
                  className={`w-full p-4 rounded-lg border-2 transition-all duration-300 text-left bg-white h-[80px] sm:h-[80px] flex flex-col justify-center group relative ${
                    formData.relationship === option.title
                      ? "border-[3px] border-aegis-blue bg-gradient-to-br from-aegis-blue/5 to-aegis-blue/10"
                      : "border-gray-200 hover:border-aegis-blue/50"
                  }`}
                >
                  <div className="font-semibold">{option.title}</div>
                  <div className="text-sm text-gray-600">{option.description}</div>
                  <div className="absolute left-full ml-2 top-1/2 -translate-y-1/2 bg-gray-900 text-white text-sm rounded px-2 py-1 invisible opacity-0 group-hover:visible group-hover:opacity-100 transition-all duration-200 whitespace-nowrap z-10">
                    {option.tooltip}
                  </div>
                </button>
              ))}
            </div>
          </div>
        );

      case 3:
        return (
          <div className="space-y-6 animate-fadeIn">
            <div className="text-center mb-8">
              <Heart className="w-12 h-12 mx-auto text-aegis-blue mb-4" />
              <h2 className="text-2xl font-semibold">What type of care are you looking for?</h2>
              <p className="text-gray-600 mt-2">Select the option that best fits your needs</p>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
              {[
                {
                  title: "Light Assisted Living",
                  description: "Basic support for daily living",
                  tooltip: "For those who need minimal assistance with daily activities"
                },
                {
                  title: "Assisted Living",
                  description: "Comprehensive care and support",
                  tooltip: "24/7 support with daily activities and medical needs"
                },
                {
                  title: "Memory Care",
                  description: "Specialized care for memory loss",
                  tooltip: "Specialized care for those with Alzheimer's or dementia"
                },
                {
                  title: "Respite Care",
                  description: "Short-term care and support",
                  tooltip: "Temporary care while primary caregivers take a break"
                }
              ].map((option) => (
                <button
                  key={option.title}
                  onClick={() => {
                    handleInputChange('careType', option.title);
                    nextStep();
                  }}
                  className={`w-full p-4 rounded-lg border-2 transition-all duration-300 text-left bg-white h-[80px] sm:h-[80px] flex flex-col justify-center group relative ${
                    formData.careType === option.title
                      ? "border-[3px] border-aegis-blue bg-gradient-to-br from-aegis-blue/5 to-aegis-blue/10"
                      : "border-gray-200 hover:border-aegis-blue/50"
                  }`}
                >
                  <div className="font-semibold">{option.title}</div>
                  <div className="text-sm text-gray-600">{option.description}</div>
                  <div className="absolute left-full ml-2 top-1/2 -translate-y-1/2 bg-gray-900 text-white text-sm rounded px-2 py-1 invisible opacity-0 group-hover:visible group-hover:opacity-100 transition-all duration-200 whitespace-nowrap z-10">
                    {option.tooltip}
                  </div>
                </button>
              ))}
            </div>
          </div>
        );

      case 4:
        return (
          <div className="space-y-6 animate-fadeIn">
            <div className="text-center mb-8">
              <Calendar className="w-12 h-12 mx-auto text-aegis-blue mb-4" />
              <h2 className="text-2xl font-semibold">When are you looking to make the move?</h2>
              <p className="text-gray-600 mt-2">This helps us prepare for your arrival</p>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
              {[
                {
                  title: "Within 1 month",
                  tooltip: "Immediate or urgent care needs"
                },
                {
                  title: "1-3 months",
                  tooltip: "Short-term planning"
                },
                {
                  title: "3-6 months",
                  tooltip: "Medium-term planning"
                },
                {
                  title: "6+ months",
                  tooltip: "Long-term planning"
                },
                {
                  title: "Just exploring",
                  tooltip: "Gathering information for future planning"
                }
              ].map((option) => (
                <button
                  key={option.title}
                  onClick={() => {
                    handleInputChange('timeline', option.title);
                    nextStep();
                  }}
                  className={`w-full p-4 rounded-lg border-2 transition-all duration-300 text-left bg-white group relative ${
                    formData.timeline === option.title
                      ? "border-[3px] border-aegis-blue bg-gradient-to-br from-aegis-blue/5 to-aegis-blue/10"
                      : "border-gray-200 hover:border-aegis-blue/50"
                  }`}
                >
                  {option.title}
                  <div className="absolute left-full ml-2 top-1/2 -translate-y-1/2 bg-gray-900 text-white text-sm rounded px-2 py-1 invisible opacity-0 group-hover:visible group-hover:opacity-100 transition-all duration-200 whitespace-nowrap z-10">
                    {option.tooltip}
                  </div>
                </button>
              ))}
            </div>
          </div>
        );

      case 5:
        return (
          <div className="space-y-6 animate-fadeIn">
            <div className="text-center mb-8">
              <Heart className="w-12 h-12 mx-auto text-aegis-blue mb-4" />
              <h2 className="text-2xl font-semibold">What interests you most?</h2>
              <p className="text-gray-600 mt-2">Select all that interest you</p>
            </div>
            <div className="grid grid-cols-2 sm:grid-cols-4 gap-2">
              {[
                {
                  title: "24/7 Care",
                  tooltip: "Round-the-clock care and support"
                },
                {
                  title: "Memory Care",
                  tooltip: "Specialized care for memory-related conditions"
                },
                {
                  title: "Respite Care",
                  tooltip: "Short-term care services"
                },
                {
                  title: "Location",
                  tooltip: "Convenient and accessible location"
                },
                {
                  title: "Fine Dining",
                  tooltip: "Restaurant-style dining experience"
                },
                {
                  title: "Social Activities",
                  tooltip: "Engaging community events and activities"
                },
                {
                  title: "Fitness Center",
                  tooltip: "Exercise and wellness facilities"
                },
                {
                  title: "Garden Access",
                  tooltip: "Beautiful outdoor spaces"
                },
                {
                  title: "Arts & Culture",
                  tooltip: "Creative and cultural programs"
                },
                {
                  title: "Transportation",
                  tooltip: "Scheduled transportation services"
                },
                {
                  title: "Pet Friendly",
                  tooltip: "Welcome to bring your pets"
                },
                {
                  title: "Security",
                  tooltip: "24/7 security and safety measures"
                }
              ].map((option) => (
                <button
                  key={option.title}
                  onClick={() => handleInterestToggle(option.title)}
                  className={`w-full p-4 rounded-lg border-2 transition-all duration-300 bg-white h-[50px] sm:h-[80px] flex items-center justify-center text-center group relative ${
                    formData.interests.includes(option.title)
                      ? "border-[3px] border-aegis-blue bg-gradient-to-br from-aegis-blue/5 to-aegis-blue/10"
                      : "border-gray-200 hover:border-aegis-blue/50"
                  }`}
                >
                  {option.title}
                  <div className="absolute left-full ml-2 top-1/2 -translate-y-1/2 bg-gray-900 text-white text-sm rounded px-2 py-1 invisible opacity-0 group-hover:visible group-hover:opacity-100 transition-all duration-200 whitespace-nowrap z-10">
                    {option.tooltip}
                  </div>
                </button>
              ))}
            </div>
          </div>
        );

      case 6:
        return (
          <div className="space-y-6 animate-fadeIn">
            <div className="text-center mb-8">
              <h2 className="text-2xl font-semibold">Learn More About Our Community</h2>
              <p className="text-gray-600 mt-2">
                Connect with us to explore your options and get answers to your questions
              </p>
            </div>
            <div className="max-w-md mx-auto space-y-4">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    First Name
                  </label>
                  <input
                    type="text"
                    value={formData.contact.firstName}
                    onChange={(e) => handleContactChange('firstName', e.target.value)}
                    className="w-full px-4 py-2 border rounded-lg"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Last Name
                  </label>
                  <input
                    type="text"
                    value={formData.contact.lastName}
                    onChange={(e) => handleContactChange('lastName', e.target.value)}
                    className="w-full px-4 py-2 border rounded-lg"
                  />
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center gap-2">
                  Email
                  <button
                    type="button"
                    className="text-gray-400 hover:text-gray-500"
                    onClick={() => setShowEmailTooltip(!showEmailTooltip)}
                  >
                    <Info size={14} />
                  </button>
                </label>
                {showEmailTooltip && (
                  <div className="text-xs text-gray-500 mb-2">
                    We'll send confirmation and additional information to this email
                  </div>
                )}
                <input
                  type="email"
                  value={formData.contact.email}
                  onChange={(e) => handleContactChange('email', e.target.value)}
                  className="w-full px-4 py-2 border rounded-lg"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center gap-2">
                  Phone
                  <button
                    type="button"
                    className="text-gray-400 hover:text-gray-500"
                    onClick={() => setShowPhoneTooltip(!showPhoneTooltip)}
                  >
                    <Info size={14} />
                  </button>
                </label>
                {showPhoneTooltip && (
                  <div className="text-xs text-gray-500 mb-2">
                    We'll use this to contact you about your inquiry
                  </div>
                )}
                <input
                  type="tel"
                  value={formData.contact.phone}
                  onChange={(e) => handleContactChange('phone', e.target.value)}
                  className="w-full px-4 py-2 border rounded-lg"
                />
              </div>
              <div className="flex items-start gap-2 mt-4">
                <input
                  type="checkbox"
                  id="consent"
                  checked={formData.contact.consent}
                  onChange={(e) => handleContactChange('consent', e.target.checked)}
                  className="mt-1"
                />
                <label htmlFor="consent" className="text-sm text-gray-600">
                  I agree to receive communications about Aegis Living communities and services.
                </label>
              </div>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  // Add these helper functions at the top of the file
  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const validatePhone = (phone) => {
    return phone.replace(/\D/g, '').length >= 10;
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="min-h-screen bg-transparent flex flex-col">
      <BackgroundDecoration />
      
      {/* Hero Section with Overlay */}
      <div className="relative">
        <div 
          className="relative h-[300px] sm:h-[450px] w-full overflow-hidden"
          style={{ 
            backgroundImage: `url(${getCurrentHeaderImage()})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            transition: 'background-image 0.5s ease-in-out'
          }}
        >
          {/* Gradient Overlay */}
          <div 
            className="absolute inset-0 bg-gradient-to-t from-black/60 via-black/30 to-transparent"
            aria-hidden="true"
          />

          {/* Title and Subtitle Overlay */}
          <div className="absolute bottom-0 left-0 right-0 p-6 sm:p-8">
            <div className="max-w-[1200px] mx-auto text-center">
              <h1 className="text-[2rem] sm:text-[2.8rem] font-bold text-white mb-2 sm:mb-3 leading-tight sm:leading-tight">
                {regionData.name}
              </h1>
              <p className="text-[1.2rem] sm:text-[1.6rem] text-white/90 max-w-3xl mx-auto leading-snug sm:leading-normal">
                {regionData.description}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Sticky Navigation */}
      {navItems.length > 0 && (
        <nav 
          className={`sticky top-0 bg-white z-40 transition-all duration-300 ${
            isScrolled ? 'shadow-md' : ''
          }`}
        >
          <div className="max-w-7xl mx-auto px-4">
            {/* Desktop Navigation */}
            <div className="hidden md:flex items-center justify-center space-x-6 h-16">
              {navItems.map((item) => (
                <ScrollLink
                  key={item.id}
                  to={item.id}
                  spy={true}
                  smooth={true}
                  offset={-64}
                  duration={500}
                  className="px-4 py-2 rounded-md text-gray-600 hover:text-aegis-blue cursor-pointer transition-all"
                  activeClass="bg-aegis-blue/10 text-aegis-blue font-medium"
                >
                  {item.label}
                </ScrollLink>
              ))}
            </div>

            {/* Mobile Navigation */}
            <div className="md:hidden flex items-center justify-between h-16">
              <button
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                className="p-2 text-gray-600 hover:text-gray-900 flex items-center gap-2"
              >
                <Menu size={24} />
                <span>Menu</span>
              </button>
            </div>
          </div>
        </nav>
      )}

      {/* Communities Section */}
      <div id="communities-section" className="w-full bg-gray-50 py-16">
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-semibold text-gray-800 mb-4">Our Communities</h2>
            <p className="text-xl text-gray-600">
              Discover our beautiful communities in the {regionData.name} area
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {sortedCommunities.map((community) => (
              <div key={community._id} className="bg-white rounded-lg shadow-lg overflow-hidden">
                <div className="relative h-48">
                  <img
                    src={community.headerImage}
                    alt={community.name}
                    className="w-full h-full object-cover"
                  />
                  {userLocation && community.distance && (
                    <div className="absolute top-4 right-4 bg-white px-3 py-1 rounded-full text-sm font-medium">
                      {community.distance.toFixed(1)} miles away
                    </div>
                  )}
                </div>
                <div className="p-6">
                  <h3 className="text-xl font-semibold mb-2">{community.name}</h3>
                  <p className="text-gray-600 mb-4 line-clamp-2">{community.subtitle}</p>
                  
                  {/* Care Types */}
                  {community.careTypes?.length > 0 && (
                    <div className="mb-4">
                      <div className="flex flex-wrap gap-2">
                        {community.careTypes.map((careType) => (
                          <span 
                            key={careType}
                            className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-aegis-blue/10 text-aegis-blue"
                          >
                            {careType}
                          </span>
                        ))}
                      </div>
                    </div>
                  )}
                  
                  {/* Amenities Section - 2 rows of 3 */}
                  {community.amenities?.length > 0 && (
                    <div className="mb-4">
                      <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
                        {community.amenities.slice(0, 6).map((amenity) => (
                          <span 
                            key={amenity.name}
                            className="inline-flex items-center gap-1 text-xs text-gray-600"
                          >
                            {AMENITY_ICONS[amenity.name] && (
                              <span className="text-aegis-blue">
                                {React.createElement(AMENITY_ICONS[amenity.name], { size: 14 })}
                              </span>
                            )}
                            {amenity.name}
                          </span>
                        ))}
                        {community.amenities.length > 6 && (
                          <span className="text-xs text-gray-500 flex items-center">
                            +{community.amenities.length - 6} more
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                  
                  {community.location && (
                    <div className="flex items-start gap-2 text-gray-600 mb-4">
                      <MapPin className="w-5 h-5 flex-shrink-0 mt-1" />
                      <div>
                        <p>{community.location.address}</p>
                        <p>{community.location.city}, {community.location.state}</p>
                      </div>
                    </div>
                  )}

                  <Link 
                    to={`/community/${community.slug}`}
                    className="inline-flex items-center justify-center w-full gap-2 px-4 py-2 bg-aegis-blue text-white rounded-lg hover:bg-aegis-blue/90 transition-colors"
                  >
                    View Location
                    <ArrowRight className="w-5 h-5" />
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Multi-step Form Section */}
      <div className="relative">

        <div 
          ref={formRef} 
          className={`relative py-16 ${
            step === 2 ? 'bg-gradient-to-br from-blue-50 via-blue-50/50 to-gray-50' :
            step === 3 ? 'bg-gradient-to-br from-emerald-50 via-emerald-50/50 to-gray-50' :
            step === 4 ? 'bg-gradient-to-br from-amber-50 via-amber-50/50 to-gray-50' :
            step === 5 ? 'bg-gradient-to-br from-rose-50 via-rose-50/50 to-gray-50' :
            'bg-gradient-to-br from-purple-50 via-purple-50/50 to-gray-50'
          }`}
        >
          <div className="absolute inset-0 bg-gradient-to-b from-gray-50 via-transparent to-white" />
          <div className="relative max-w-xl mx-auto px-4">
            {!isSubmitted ? (
              <>
                {renderProgressBar()}
                {renderCurrentStep()}
                <div className="flex justify-center gap-4 mt-8">
                  {step > 2 && (
                    <button
                      onClick={prevStep}
                      className="px-6 py-2 text-gray-600 hover:text-gray-800"
                    >
                      Back
                    </button>
                  )}
                  <button
                    onClick={nextStep}
                    disabled={!canProceed()}
                    className={`px-6 py-2 rounded-lg text-white ${
                      canProceed()
                        ? "bg-aegis-blue hover:bg-aegis-blue/90"
                        : "bg-gray-300 cursor-not-allowed"
                    }`}
                  >
                    {step === 6 ? "Submit" : "Next"}
                  </button>
                </div>
              </>
            ) : (
              <SuccessPage successData={successData} />
            )}
          </div>
        </div>
      </div>

      {/* Features Section */}
      {regionData.features?.length > 0 && (
        <div id="features-section" className="w-full bg-white py-16">
          <div className="max-w-7xl mx-auto px-4">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {regionData.features.map((feature, index) => (
                <div key={index} className="bg-white rounded-xl shadow-lg overflow-hidden">
                  {feature.image && (
                    <div className="w-full h-48 overflow-hidden">
                      <img 
                        src={feature.image} 
                        alt={feature.title}
                        className="w-full h-full object-cover transform hover:scale-105 transition-transform duration-500"
                      />
                    </div>
                  )}
                  <div className="p-6">
                    <h3 className="text-xl font-semibold text-aegis-blue">{feature.title}</h3>
                    <p className="text-sm text-gray-500 mt-1">{feature.subtitle}</p>
                    <p className="text-gray-600 mt-4">{feature.text}</p>
                    {feature.buttonText && (
                      <button
                        onClick={() => formRef.current?.scrollIntoView({ behavior: 'smooth' })}
                        className="mt-6 inline-flex items-center gap-2 text-aegis-blue hover:text-aegis-blue/80 font-medium group"
                      >
                        {feature.buttonText}
                        <ArrowRight className="w-5 h-5 transform group-hover:translate-x-1 transition-transform" />
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {/* Regional Manager Contact */}
      {regionData.manager && (
        <div className="w-full bg-gray-50 py-16">
          <div className="max-w-4xl mx-auto px-4 text-center">
            <h2 className="text-3xl font-semibold text-gray-800 mb-4">Contact Regional Manager</h2>
            <div className="bg-white rounded-lg shadow-lg p-8 mt-8">
              <h3 className="text-xl font-semibold mb-4">{regionData.manager.name}</h3>
              <div className="flex flex-col sm:flex-row items-center justify-center gap-6">
                <a 
                  href={`tel:${regionData.manager.phone}`}
                  className="flex items-center gap-2 text-aegis-blue hover:text-aegis-blue/80"
                >
                  <Phone className="w-5 h-5" />
                  {regionData.manager.phone}
                </a>
                <a 
                  href={`mailto:${regionData.manager.email}`}
                  className="flex items-center gap-2 text-aegis-blue hover:text-aegis-blue/80"
                >
                  <Mail className="w-5 h-5" />
                  {regionData.manager.email}
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RegionalTemplate; 