import React, { useState } from 'react';
import { LogIn } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';

const LoginForm = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { login } = useAuth();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (username === 'admin' && password === '123456') {
      const auth = btoa(`${username}:${password}`);
      login(auth);
    } else {
      setError('Invalid credentials');
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center">
      <div className="max-w-md w-full mx-auto px-4">
        <div className="text-center mb-8">
          <img 
            src="/images/aegis-living-logo-blue.svg"
            alt="Aegis Living"
            className="h-12 mx-auto mb-6"
          />
          <h2 className="text-2xl font-semibold text-gray-900">
            Admin Dashboard Login
          </h2>
          <p className="mt-2 text-gray-600">
            Please sign in to access the admin area
          </p>
        </div>

        <div className="bg-white rounded-lg shadow-lg p-6">
          <form onSubmit={handleSubmit} className="space-y-6">
            {error && (
              <div className="bg-red-50 text-red-500 px-4 py-3 rounded-lg text-sm">
                {error}
              </div>
            )}
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Username
              </label>
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-aegis-blue/20 focus:border-aegis-blue"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Password
              </label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-aegis-blue/20 focus:border-aegis-blue"
                required
              />
            </div>

            <button
              type="submit"
              className="w-full bg-aegis-blue text-white py-2 px-4 rounded-lg hover:bg-aegis-blue/90 transition-colors flex items-center justify-center gap-2"
            >
              <LogIn size={20} />
              Sign In
            </button>
          </form>
        </div>

        <p className="mt-4 text-center text-sm text-gray-600">
          Need help? Contact your system administrator
        </p>
      </div>
    </div>
  );
};

export default LoginForm; 