import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MapPin, Building, ArrowRight } from 'lucide-react';
import BackgroundDecoration from './BackgroundDecoration';

const API_URL = process.env.REACT_APP_API_URL || '';

const RegionsArchive = () => {
  const [regions, setRegions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchRegions();
  }, []);

  const fetchRegions = async () => {
    try {
      const response = await fetch(`${API_URL}/api/regions`);
      if (!response.ok) throw new Error('Failed to fetch regions');
      const data = await response.json();
      setRegions(data);
    } catch (error) {
      console.error('Error fetching regions:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-xl text-gray-600">Loading regions...</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <BackgroundDecoration />
      
      {/* Header */}
      <div className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 py-16 text-center">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Our Regions
          </h1>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Discover Aegis Living communities in your area
          </p>
        </div>
      </div>

      {/* Regions Grid */}
      <div className="max-w-7xl mx-auto px-4 py-16">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {regions.map((region) => (
            <div key={region._id} className="bg-white rounded-lg shadow-lg overflow-hidden">
              <div className="p-6">
                <h2 className="text-2xl font-semibold mb-4">{region.name}</h2>
                <p className="text-gray-600 mb-6 line-clamp-2">{region.description}</p>
                
                <div className="flex items-center gap-2 text-gray-600 mb-4">
                  <Building className="w-5 h-5" />
                  <span>{region.communities?.length || 0} Communities</span>
                </div>

                {region.manager && (
                  <div className="text-sm text-gray-600 mb-6">
                    <div className="font-medium">Regional Manager</div>
                    <div>{region.manager.name}</div>
                  </div>
                )}

                <Link
                  to={`/community/region/${region.slug}`}
                  className="inline-flex items-center gap-2 text-aegis-blue hover:text-aegis-blue/80"
                >
                  <span>View Region</span>
                  <ArrowRight className="w-5 h-5" />
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RegionsArchive; 