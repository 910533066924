import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Plus, X, Search, Upload, ArrowLeft, Heart, Film, Building, Home, Stethoscope, Activity, Dog, Scissors, Gamepad2, BedSingle, Bed, Utensils, Clock, Dumbbell, Building2, Crown, Phone, Video, Calendar, Trash2 } from 'lucide-react';
import { ReactSortable } from 'react-sortablejs';
import AddressAutocomplete from './AddressAutocomplete';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';

const AVAILABLE_ICONS = {
  Heart,
  Film,
  Building,
  Home,
  Stethoscope,
  Activity,
  Dog,
  Scissors,
  Gamepad2,
  BedSingle,
  Bed,
  Utensils,
  Clock,
  Dumbbell,
  Building2,
  Crown,
  Phone,
  Video,
  Calendar
};

const API_URL = process.env.REACT_APP_API_URL || '';

const AVAILABLE_AMENITIES = [
  '24/7 Care', 'All-Day Dining', 'Asian Inspired', 'Bilingual',
  'Close to Ocean', 'Companion Suite', 'Cottage Style', 'Cozy',
  'Eco Friendly', 'Fitness Center', 'Gardening Space', 'Intimate Setting',
  'Large Building', 'Luxury', 'Massage Room', 'Memory Care Only',
  'Movie Theater', 'Nature Lovers', 'Nearby Restaurants', 'Nearby Shopping',
  'New Building', 'One Bedroom', 'Onsite Nurses 24/7', 'Onsite Nurses Daily',
  'Onsite PT Center', 'Patio', 'Pets', 'Pool', 'Putting Green',
  'Residential Area', 'Salon', 'Sports Den', 'Studio', 'Two Bedrooms',
  'Under Construction', 'Urban', 'Walking Paths', 'Yoga'
];

const FLOOR_PLAN_TYPES = [
  'Studio',
  'One Bedroom',
  'Two Bedroom',
  'Companion Suite'
];

const CARE_TYPES = [
  'Assisted Living',
  'End Of Life Care',
  'Light Assisted Living',
  'Memory Care',
  'Respite Care',
  'Transitional Care'
];

const CommunityForm = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [iconSearch, setIconSearch] = useState('');
  const [showIconPicker, setShowIconPicker] = useState(false);
  const [selectedAmenityIndex, setSelectedAmenityIndex] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [imageLoading, setImageLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    headerImage: '',
    subtitle: '',
    bodyText: '',
    virtualTourId: '',
    features: [
      { 
        title: 'ITALIAN INSPIRED',
        subtitle: 'Mediterranean Design',
        image: 'https://www.aegisliving.com/wp-content/uploads/2023/07/kirkland-waterfront-gallery-14-1280x853.jpg.webp',
        text: 'Water features at the entrance, inspired by the hand-painted terracotta tiles of Italy, welcome guests. Beautifully landscaped gardens and warm, earth-tone stucco walls contrast with splashes of vibrant hues to create a traditional Mediterranean feel.',
        buttonLink: '#form',
        buttonText: 'Schedule a Tour'
      },
      { 
        title: 'ALL-DAY ACTIVITIES',
        subtitle: 'Engaging Programs',
        image: 'https://www.aegisliving.com/wp-content/uploads/2023/07/kirkland-waterfront-gallery-62-1280x853.jpg.webp',
        text: 'The daily experience here is set apart by our creative Life Enrichment team. We customize our robust activities program to fit the interests of our residents. With over 200 activities every month, there is something for everyone.',
        buttonLink: '#form',
        buttonText: 'Learn More'
      },
      { 
        title: 'CHEF-PREPARED MEALS',
        subtitle: 'Fine Dining',
        image: 'https://www.aegisliving.com/wp-content/uploads/2023/07/kirkland-waterfront-gallery-247A1110-1280x854.jpg.webp',
        text: 'Our talented chefs cater to your needs, offering flexibility in when and where you dine. Our menus are developed by registered dietitians and are as nutritious as they are delectable.',
        buttonLink: '#form',
        buttonText: 'View Menu'
      }
    ],
    amenities: [],
    floorPlans: [],
    testimonials: [],
    galleryImages: [],
    manager: {
      name: '',
      email: '',
      phone: '',
      community_code: ''
    },
    active: true,
    careTypes: []
  });

  useEffect(() => {
    if (slug) {
      fetchCommunity();
    }
  }, [slug]);

  const fetchCommunity = async () => {
    try {
      const response = await fetch(`${API_URL}/api/communities/${slug}`, {
        headers: {
          'Authorization': 'Basic ' + btoa('admin:123456')
        }
      });
      if (response.ok) {
        const data = await response.json();
        setFormData(data);
      }
    } catch (error) {
      console.error('Error fetching community:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const url = slug 
        ? `${API_URL}/api/communities/${slug}`
        : `${API_URL}/api/communities`;

      const response = await fetch(url, {
        method: slug ? 'PUT' : 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + btoa('admin:123456')
        },
        body: JSON.stringify(formData)
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.details || 'Failed to save community');
      }

      navigate('/admin/communities');
    } catch (error) {
      console.error('Error saving community:', error);
      alert(error.message || 'Error saving community. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleImageUpload = async (e, field, index = null) => {
    const file = e.target.files[0];
    if (!file) return;

    // Check if it's an image
    if (!file.type.startsWith('image/')) {
      alert('Please upload an image file');
      return;
    }

    try {
      setImageLoading(true);

      const formData = new FormData();
      formData.append('image', file);

      const response = await fetch(`${API_URL}/api/upload`, {
        method: 'POST',
        headers: {
          'Authorization': 'Basic ' + btoa('admin:123456')
        },
        body: formData
      });

      if (!response.ok) {
        throw new Error(`Upload failed: ${response.statusText}`);
      }

      const data = await response.json();
      
      if (!data.success) {
        throw new Error(data.error || 'Upload failed');
      }

      setFormData(prev => {
        const newData = { ...prev };
        
        switch (field) {
          case 'headerImage':
            newData.headerImage = data.url;
            break;
          case 'features':
            if (index !== null) {
              newData.features[index].image = data.url;
            }
            break;
          case 'galleryImages':
            newData.galleryImages = [...prev.galleryImages, data.url];
            break;
          case 'floorPlans':
            if (index !== null) {
              newData.floorPlans[index].image = data.url;
            }
            break;
        }
        
        return newData;
      });
    } catch (error) {
      console.error('Error uploading image:', error);
      alert(error.message || 'Error uploading image. Please try again.');
    } finally {
      setImageLoading(false);
    }
  };

  const handleAddAmenity = () => {
    setFormData(prev => ({
      ...prev,
      amenities: [...prev.amenities, { name: '', iconName: '' }]
    }));
  };

  const handleRemoveAmenity = (index) => {
    setFormData(prev => ({
      ...prev,
      amenities: prev.amenities.filter((_, i) => i !== index)
    }));
  };

  const handleIconSelect = (iconName) => {
    if (selectedAmenityIndex !== null) {
      setFormData(prev => ({
        ...prev,
        amenities: prev.amenities.map((amenity, index) => 
          index === selectedAmenityIndex 
            ? { ...amenity, iconName: iconName }
            : amenity
        )
      }));
      setShowIconPicker(false);
      setSelectedAmenityIndex(null);
    }
  };

  const getYouTubeEmbedUrl = (url) => {
    if (!url) return '';
    
    // Handle different YouTube URL formats
    let videoId = '';
    
    // Format: https://www.youtube.com/watch?v=VIDEO_ID
    const watchMatch = url.match(/(?:\?v=|&v=|youtu\.be\/|\/v\/|\/embed\/)([^&\n?#]+)/);
    if (watchMatch) {
      videoId = watchMatch[1];
    }

    return videoId ? `https://www.youtube.com/embed/${videoId}` : url;
  };

  const handleDelete = async () => {
    if (!window.confirm(`Are you sure you want to delete ${formData.name}? This action cannot be undone.`)) {
      return;
    }

    try {
      const response = await fetch(`${API_URL}/api/communities/${slug}`, {
        method: 'DELETE',
        headers: {
          'Authorization': 'Basic ' + btoa('admin:123456')
        }
      });

      if (response.ok) {
        navigate('/admin/communities');
      } else {
        throw new Error('Failed to delete community');
      }
    } catch (error) {
      console.error('Error deleting community:', error);
      alert('Error deleting community. Please try again.');
    }
  };

  const handleGridChange = (sourceId, sourceIndex, targetIndex) => {
    if (sourceIndex === targetIndex) return;

    setFormData(prev => ({
      ...prev,
      galleryImages: swap(prev.galleryImages, sourceIndex, targetIndex)
    }));
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    setLoading(true);
    
    try {
      const uploadPromises = acceptedFiles.map(async (file) => {
        const formData = new FormData();
        formData.append('image', file);

        const response = await fetch(`${API_URL}/api/upload`, {
          method: 'POST',
          headers: {
            'Authorization': 'Basic ' + btoa('admin:123456')
          },
          body: formData
        });

        if (!response.ok) {
          throw new Error('Upload failed');
        }

        const data = await response.json();
        return data.url;
      });

      const uploadedUrls = await Promise.all(uploadPromises);
      
      setFormData(prev => ({
        ...prev,
        galleryImages: [...prev.galleryImages, ...uploadedUrls]
      }));
    } catch (error) {
      console.error('Error uploading images:', error);
      alert('Error uploading images. Please try again.');
    } finally {
      setLoading(false);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png', '.gif', '.webp']
    },
    multiple: true
  });

  return (
    <div className="p-8">
      <div className="max-w-4xl mx-auto">
        <div className="flex items-center gap-4 mb-8">
          <button
            onClick={() => navigate('/admin/communities')}
            className="text-gray-600 hover:text-gray-800"
          >
            <ArrowLeft size={24} />
          </button>
          <h1 className="text-2xl font-semibold flex-1">
            {slug ? 'Edit Community' : 'Add New Community'}
          </h1>
        </div>

        <form onSubmit={handleSubmit} className="space-y-8">
          {/* Basic Information */}
          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-xl font-semibold mb-4">Basic Information</h2>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Community Name
                </label>
                <input
                  type="text"
                  value={formData.name}
                  onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                  className="w-full px-4 py-2 border rounded-lg"
                  required
                />
              </div>

              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Header Media
                  </label>

                  {/* Gallery Slideshow Toggle */}
                  <div className="mb-6">
                    <label className="flex items-center gap-2 cursor-pointer mb-2">
                      <input
                        type="checkbox"
                        checked={formData.useGalleryForHeader || false}
                        onChange={(e) => setFormData(prev => ({
                          ...prev,
                          useGalleryForHeader: e.target.checked
                        }))}
                        className="w-4 h-4 text-aegis-blue border-gray-300 rounded focus:ring-aegis-blue"
                      />
                      <span className="text-sm font-medium text-gray-700">
                        Use Gallery for Header Slideshow
                      </span>
                    </label>
                    <p className="text-sm text-gray-500">
                      When enabled, the header will cycle through gallery images every 5 seconds
                    </p>
                  </div>
                  
                  {/* Header Image Section */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Header Image
                    </label>
                    <p className="text-sm text-gray-500 mb-4">
                      {formData.useGalleryForHeader 
                        ? 'This image will be shown briefly while gallery images load'
                        : 'Main image shown in the header'}
                    </p>
                    <div className="flex items-center gap-4">
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleImageUpload(e, 'headerImage')}
                        className="hidden"
                        id="headerImage"
                      />
                      <label
                        htmlFor="headerImage"
                        className={`px-4 py-2 bg-gray-100 rounded-lg cursor-pointer hover:bg-gray-200 inline-flex items-center gap-2 ${
                          imageLoading ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                      >
                        <Upload size={20} />
                        {imageLoading ? 'Uploading...' : 'Upload Image'}
                      </label>
                      {formData.headerImage && (
                        <div className="flex items-center gap-2">
                          <img
                            src={formData.headerImage}
                            alt="Header"
                            className="h-12 w-20 object-cover rounded"
                          />
                          <button
                            type="button"
                            onClick={() => setFormData(prev => ({ ...prev, headerImage: '' }))}
                            className="p-1 text-red-500 hover:text-red-600"
                            title="Remove image"
                          >
                            <X size={20} />
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Subtitle
                  </label>
                  <input
                    type="text"
                    value={formData.subtitle}
                    onChange={(e) => setFormData(prev => ({ ...prev, subtitle: e.target.value }))}
                    className="w-full px-4 py-2 border rounded-lg"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Body Text
                  </label>
                  <textarea
                    value={formData.bodyText}
                    onChange={(e) => setFormData(prev => ({ ...prev, bodyText: e.target.value }))}
                    rows={6}
                    className="w-full px-4 py-2 border rounded-lg resize-none"
                    placeholder="Enter detailed community description..."
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Care Types
                  </label>
                  <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
                    {CARE_TYPES.map((careType) => (
                      <label key={careType} className="flex items-center gap-2 p-3 border rounded-lg hover:bg-gray-50">
                        <input
                          type="checkbox"
                          checked={formData.careTypes.includes(careType)}
                          onChange={(e) => {
                            setFormData(prev => ({
                              ...prev,
                              careTypes: e.target.checked
                                ? [...prev.careTypes, careType]
                                : prev.careTypes.filter(type => type !== careType)
                            }));
                          }}
                          className="w-4 h-4 text-aegis-blue border-gray-300 rounded focus:ring-aegis-blue"
                        />
                        <span className="text-sm">{careType}</span>
                      </label>
                    ))}
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Virtual Tour ID
                  </label>
                  <input
                    type="text"
                    value={formData.virtualTourId}
                    onChange={(e) => setFormData(prev => ({ ...prev, virtualTourId: e.target.value }))}
                    className="w-full px-4 py-2 border rounded-lg"
                    placeholder="Enter virtual tour ID..."
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Features Section */}
          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-xl font-semibold mb-4">Features</h2>
            <div className="space-y-6">
              {formData.features.map((feature, index) => (
                <div key={index} className="border-b pb-6 last:border-0">
                  <h3 className="font-medium mb-4">Feature {index + 1}</h3>
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Title
                      </label>
                      <input
                        type="text"
                        value={feature.title}
                        onChange={(e) => {
                          const newFeatures = [...formData.features];
                          newFeatures[index].title = e.target.value;
                          setFormData(prev => ({ ...prev, features: newFeatures }));
                        }}
                        className="w-full px-4 py-2 border rounded-lg"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Subtitle
                      </label>
                      <input
                        type="text"
                        value={feature.subtitle}
                        onChange={(e) => {
                          const newFeatures = [...formData.features];
                          newFeatures[index].subtitle = e.target.value;
                          setFormData(prev => ({ ...prev, features: newFeatures }));
                        }}
                        className="w-full px-4 py-2 border rounded-lg"
                      />
                    </div>
                    <div className="col-span-2">
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Content
                      </label>
                      <textarea
                        value={feature.text}
                        onChange={(e) => {
                          const newFeatures = [...formData.features];
                          newFeatures[index].text = e.target.value;
                          setFormData(prev => ({ ...prev, features: newFeatures }));
                        }}
                        rows={4}
                        className="w-full px-4 py-2 border rounded-lg resize-none"
                        placeholder="Enter detailed feature description..."
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Button Text
                      </label>
                      <input
                        type="text"
                        value={feature.buttonText}
                        onChange={(e) => {
                          const newFeatures = [...formData.features];
                          newFeatures[index].buttonText = e.target.value;
                          setFormData(prev => ({ ...prev, features: newFeatures }));
                        }}
                        className="w-full px-4 py-2 border rounded-lg"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Button Link
                      </label>
                      <input
                        type="text"
                        value={feature.buttonLink}
                        onChange={(e) => {
                          const newFeatures = [...formData.features];
                          newFeatures[index].buttonLink = e.target.value;
                          setFormData(prev => ({ ...prev, features: newFeatures }));
                        }}
                        className="w-full px-4 py-2 border rounded-lg"
                      />
                    </div>
                    <div className="col-span-2">
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Feature Image
                      </label>
                      <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4">
                        {feature.image && (
                          <img 
                            src={feature.image} 
                            alt={feature.title}
                            className="w-24 h-24 object-cover rounded-lg"
                          />
                        )}
                        <input
                          type="file"
                          accept="image/*"
                          onChange={(e) => handleImageUpload(e, 'features', index)}
                          className="flex-1"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Amenities Section - Updated */}
          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-xl font-semibold mb-4">Amenities</h2>
            <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
              {AVAILABLE_AMENITIES.map((amenity) => (
                <label key={amenity} className="flex items-center gap-2 p-3 border rounded-lg hover:bg-gray-50">
                  <input
                    type="checkbox"
                    checked={formData.amenities.some(a => a.name === amenity)}
                    onChange={(e) => {
                      setFormData(prev => ({
                        ...prev,
                        amenities: e.target.checked
                          ? [...prev.amenities, { name: amenity, iconName: 'Star' }]
                          : prev.amenities.filter(a => a.name !== amenity)
                      }));
                    }}
                    className="w-4 h-4 text-aegis-blue border-gray-300 rounded focus:ring-aegis-blue"
                  />
                  <span className="text-sm">{amenity}</span>
                </label>
              ))}
            </div>
          </div>

          {/* Floor Plans Section */}
          <div className="bg-white rounded-lg shadow p-6">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold">Floor Plans</h2>
              <button
                type="button"
                onClick={() => setFormData(prev => ({
                  ...prev,
                  floorPlans: [...prev.floorPlans, { type: 'Studio', name: '', image: '' }]
                }))}
                className="text-aegis-blue hover:text-aegis-blue/80 flex items-center gap-2"
              >
                <Plus size={20} />
                Add Floor Plan
              </button>
            </div>
            <div className="space-y-4">
              {formData.floorPlans.map((floorPlan, index) => (
                <div key={index} className="border rounded-lg p-4">
                  <div className="flex justify-between items-start mb-4">
                    <h3 className="font-medium">Floor Plan {index + 1}</h3>
                    <button
                      type="button"
                      onClick={() => {
                        setFormData(prev => ({
                          ...prev,
                          floorPlans: prev.floorPlans.filter((_, i) => i !== index)
                        }));
                      }}
                      className="text-red-500 hover:text-red-600"
                    >
                      <X size={20} />
                    </button>
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Type
                      </label>
                      <select
                        value={floorPlan.type}
                        onChange={(e) => {
                          const newFloorPlans = [...formData.floorPlans];
                          newFloorPlans[index].type = e.target.value;
                          setFormData(prev => ({ ...prev, floorPlans: newFloorPlans }));
                        }}
                        className="w-full px-4 py-2 border rounded-lg"
                      >
                        {FLOOR_PLAN_TYPES.map(type => (
                          <option key={type} value={type}>{type}</option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Name
                      </label>
                      <input
                        type="text"
                        value={floorPlan.name}
                        onChange={(e) => {
                          const newFloorPlans = [...formData.floorPlans];
                          newFloorPlans[index].name = e.target.value;
                          setFormData(prev => ({ ...prev, floorPlans: newFloorPlans }));
                        }}
                        className="w-full px-4 py-2 border rounded-lg"
                        placeholder="Enter floor plan name..."
                      />
                    </div>
                  </div>
                  <div className="mt-4">
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Floor Plan Image
                    </label>
                    <div className="flex items-center gap-4">
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleImageUpload(e, 'floorPlans', index)}
                        className="hidden"
                        id={`floorPlan-${index}`}
                      />
                      <label
                        htmlFor={`floorPlan-${index}`}
                        className="px-4 py-2 bg-gray-100 rounded-lg cursor-pointer hover:bg-gray-200"
                      >
                        <Upload size={20} className="inline mr-2" />
                        Upload Image
                      </label>
                      {floorPlan.image && (
                        <img
                          src={floorPlan.image}
                          alt={floorPlan.name}
                          className="h-20 w-20 object-cover rounded"
                        />
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Testimonials Section */}
          <div className="bg-white rounded-lg shadow p-6">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold">Testimonials</h2>
              <button
                type="button"
                onClick={() => setFormData(prev => ({
                  ...prev,
                  testimonials: [...prev.testimonials, { name: '', location: '', text: '' }]
                }))}
                className="text-aegis-blue hover:text-aegis-blue/80 flex items-center gap-2"
              >
                <Plus size={20} />
                Add Testimonial
              </button>
            </div>
            <div className="space-y-4">
              {formData.testimonials.map((testimonial, index) => (
                <div key={index} className="border rounded-lg p-4">
                  <div className="flex justify-between items-start mb-4">
                    <h3 className="font-medium">Testimonial {index + 1}</h3>
                    <button
                      type="button"
                      onClick={() => {
                        setFormData(prev => ({
                          ...prev,
                          testimonials: prev.testimonials.filter((_, i) => i !== index)
                        }));
                      }}
                      className="text-red-500 hover:text-red-600"
                    >
                      <X size={20} />
                    </button>
                  </div>
                  <div className="grid grid-cols-2 gap-4 mb-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Name
                      </label>
                      <input
                        type="text"
                        value={testimonial.name}
                        onChange={(e) => {
                          const newTestimonials = [...formData.testimonials];
                          newTestimonials[index].name = e.target.value;
                          setFormData(prev => ({ ...prev, testimonials: newTestimonials }));
                        }}
                        className="w-full px-4 py-2 border rounded-lg"
                        placeholder="Enter name..."
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Location
                      </label>
                      <input
                        type="text"
                        value={testimonial.location}
                        onChange={(e) => {
                          const newTestimonials = [...formData.testimonials];
                          newTestimonials[index].location = e.target.value;
                          setFormData(prev => ({ ...prev, testimonials: newTestimonials }));
                        }}
                        className="w-full px-4 py-2 border rounded-lg"
                        placeholder="Enter location..."
                      />
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Testimonial
                    </label>
                    <textarea
                      value={testimonial.text}
                      onChange={(e) => {
                        const newTestimonials = [...formData.testimonials];
                        newTestimonials[index].text = e.target.value;
                        setFormData(prev => ({ ...prev, testimonials: newTestimonials }));
                      }}
                      rows={4}
                      className="w-full px-4 py-2 border rounded-lg resize-none"
                      placeholder="Enter testimonial..."
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Gallery Images Section */}
          <div className="bg-white rounded-lg shadow p-6">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold">Gallery Images</h2>
            </div>

            {/* Drag & Drop Upload Zone */}
            <div
              {...getRootProps()}
              className={`border-2 border-dashed rounded-lg p-8 text-center cursor-pointer transition-colors mb-6
                ${isDragActive ? 'border-aegis-blue bg-aegis-blue/5' : 'border-gray-300 hover:border-aegis-blue/50'}`}
            >
              <input {...getInputProps()} />
              <div className="flex flex-col items-center gap-2">
                <Upload className={`w-8 h-8 ${isDragActive ? 'text-aegis-blue' : 'text-gray-400'}`} />
                {isDragActive ? (
                  <p className="text-aegis-blue">Drop the images here...</p>
                ) : (
                  <>
                    <p className="text-gray-600">Drag & drop images here, or click to select files</p>
                    <p className="text-sm text-gray-500">Supports: JPG, PNG, GIF, WebP</p>
                  </>
                )}
              </div>
            </div>

            {/* Gallery Grid with Drag to Reorder */}
            <ReactSortable
              list={formData.galleryImages.map(url => ({ id: url, url }))}
              setList={(newState) => {
                setFormData(prev => ({
                  ...prev,
                  galleryImages: newState.map(item => item.url)
                }));
              }}
              className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4"
              animation={200}
              ghostClass="opacity-50"
              dragClass="cursor-grabbing"
            >
              {formData.galleryImages.map((image, index) => (
                <div
                  key={image}
                  className="relative aspect-square rounded-lg overflow-hidden group cursor-grab active:cursor-grabbing"
                >
                  <img
                    src={image}
                    alt={`Gallery ${index + 1}`}
                    className="w-full h-full object-cover"
                    draggable={false}
                  />
                  <div className="absolute inset-0 bg-black/20 opacity-0 group-hover:opacity-100 transition-opacity">
                    <button
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setFormData(prev => ({
                          ...prev,
                          galleryImages: prev.galleryImages.filter((_, i) => i !== index)
                        }));
                      }}
                      className="absolute top-2 right-2 p-1.5 bg-red-500 text-white rounded-full hover:bg-red-600 shadow-md z-10"
                    >
                      <X size={16} />
                    </button>
                    <div className="absolute inset-0 flex items-center justify-center">
                      <div className="bg-black/60 px-3 py-2 rounded-lg text-white text-sm font-medium">
                        Drag to reorder
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </ReactSortable>
          </div>

          {/* Manager Information */}
          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-xl font-semibold mb-4">Manager Information</h2>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Name
                </label>
                <input
                  type="text"
                  value={formData.manager.name}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    manager: { ...prev.manager, name: e.target.value }
                  }))}
                  className="w-full px-4 py-2 border rounded-lg"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Email
                </label>
                <input
                  type="email"
                  value={formData.manager.email}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    manager: { ...prev.manager, email: e.target.value }
                  }))}
                  className="w-full px-4 py-2 border rounded-lg"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Phone
                </label>
                <input
                  type="tel"
                  value={formData.manager.phone}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    manager: { ...prev.manager, phone: e.target.value }
                  }))}
                  className="w-full px-4 py-2 border rounded-lg"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Community Code
                </label>
                <input
                  type="text"
                  value={formData.manager.community_code}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    manager: { ...prev.manager, community_code: e.target.value }
                  }))}
                  className="w-full px-4 py-2 border rounded-lg"
                />
              </div>
            </div>
          </div>

          {/* Location Information */}
          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-xl font-semibold mb-4">Location Information</h2>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Address
                </label>
                <AddressAutocomplete
                  value={formData.location?.address}
                  onChange={(addressData) => {
                    console.log('Address data received:', addressData);
                    setFormData(prev => ({
                      ...prev,
                      location: {
                        ...addressData,
                        coordinates: addressData.coordinates || {
                          lat: null,
                          lng: null
                        }
                      }
                    }));
                  }}
                />
              </div>

              <div className="grid grid-cols-3 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    City
                  </label>
                  <input
                    type="text"
                    value={formData.location?.city || ''}
                    readOnly
                    className="w-full px-4 py-2 border rounded-lg bg-gray-50"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    State
                  </label>
                  <input
                    type="text"
                    value={formData.location?.state || ''}
                    readOnly
                    className="w-full px-4 py-2 border rounded-lg bg-gray-50"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    ZIP Code
                  </label>
                  <input
                    type="text"
                    value={formData.location?.zip || ''}
                    readOnly
                    className="w-full px-4 py-2 border rounded-lg bg-gray-50"
                  />
                </div>
              </div>

              {formData.location?.coordinates && (
                <div className="text-sm text-gray-500">
                  📍 Coordinates: {formData.location.coordinates.lat}, {formData.location.coordinates.lng}
                </div>
              )}
            </div>
          </div>

          {/* Submit Button */}
          <div className="flex justify-between items-center">
            {slug && (
              <button
                onClick={handleDelete}
                className="flex items-center gap-2 px-4 py-2 text-red-600 hover:text-red-700 hover:bg-red-50 rounded-lg transition-colors"
                title="Delete Community"
              >
                <Trash2 size={20} />
                <span className="hidden md:inline">Delete Community</span>
              </button>
            )}
            <button
              type="submit"
              disabled={loading}
              className={`px-6 py-2 rounded-lg text-white ${
                loading ? 'bg-gray-400' : 'bg-aegis-blue hover:bg-aegis-blue/90'
              }`}
            >
              {loading ? 'Saving...' : 'Save Community'}
            </button>
          </div>
        </form>

        {/* Icon Picker Modal */}
        {showIconPicker && (
          <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
            <div className="bg-white rounded-lg p-6 w-full max-w-4xl max-h-[90vh] flex flex-col">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-semibold">Select Icon</h3>
                <button
                  type="button"
                  onClick={() => setShowIconPicker(false)}
                  className="text-gray-500 hover:text-gray-700"
                >
                  <X size={20} />
                </button>
              </div>
              
              <div className="sticky top-0 bg-white z-10 mb-4 pb-4 border-b">
                <input
                  type="text"
                  placeholder="Search icons..."
                  value={iconSearch}
                  onChange={(e) => setIconSearch(e.target.value)}
                  className="w-full px-4 py-2 border rounded-lg"
                />
              </div>

              <div className="overflow-y-auto min-h-0 flex-1">
                <div className="grid grid-cols-4 sm:grid-cols-6 md:grid-cols-8 gap-4">
                  {Object.entries(AVAILABLE_ICONS)
                    .filter(([name]) => 
                      name.toLowerCase().includes(iconSearch.toLowerCase())
                    )
                    .map(([name, Icon]) => (
                      <button
                        key={name}
                        type="button"
                        onClick={() => handleIconSelect(name)}
                        className="p-4 border rounded-lg hover:bg-gray-50 flex flex-col items-center gap-2"
                      >
                        <Icon size={24} className="text-gray-600" />
                        <span className="text-xs text-gray-600 text-center break-all">
                          {name}
                        </span>
                      </button>
                    ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CommunityForm; 