import React, { useState, useRef, useEffect } from "react";
import { ArrowRight, Phone, MapPin, Calendar, Heart, Home, Video, Loader2 } from "lucide-react";
import SuccessPage from './SuccessPage';
import BackgroundDecoration from './BackgroundDecoration';
import CityAutocomplete from './CityAutocomplete';
import PhoneInput from './PhoneInput';
import VideoModal from './VideoModal';

const FUNNEL_VERSION = 'v1.1.21';

const AegisFunnel = () => {
  const [step, setStep] = useState(1);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: ''
  });
  const [formData, setFormData] = useState({
    location: "",
    relationship: "",
    careType: "",
    timeline: "",
    livingArrangement: "",
    interests: [],
    contact: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      consent: false
    },
    source: 'funnel',
    hubspot: {
      community_code: 'klake001',
      manager_phone: '(425) 659-3432',
      manager_email: 'kirklandlakewa@aegisliving.com',
      manager: 'Cory Nees',
      hs_analytics_source_data_1: '',
      hs_analytics_source_data_2: ''
    },
    utm: {
      source: '',
      medium: '',
      campaign: '',
      term: '',
      content: '',
      gclid: '',
      referrer: '',
      landing_page: ''
    }
  });
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Move useEffect hooks to the top level, right after state declarations
  useEffect(() => {
    console.log('Sending GA4 form_start event');
    window.gtag('event', 'form_start', {
      'event_category': 'Form',
      'event_label': 'Form Started'
    });
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      if (!isSubmitted) {
        console.log('Sending GA4 form_abandonment event:', {
          'event_category': 'Form',
          'event_label': `Step ${step}`,
          'step_number': step,
          'step_name': getStepName(step)
        });
        
        window.gtag('event', 'form_abandonment', {
          'event_category': 'Form',
          'event_label': `Step ${step}`,
          'step_number': step,
          'step_name': getStepName(step)
        });
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [step, isSubmitted]);

  // Capture UTM parameters
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const utmParams = {
      source: params.get('utm_source') || '',
      medium: params.get('utm_medium') || '',
      campaign: params.get('utm_campaign') || '',
      term: params.get('utm_term') || '',
      content: params.get('utm_content') || '',
      gclid: params.get('gclid') || '',
      referrer: document.referrer || '',
      landing_page: window.location.href || ''
    };
    
    setFormData(prev => ({
      ...prev,
      utm: utmParams
    }));
  }, []);

  const handleInputChange = (field, value) => {
    if (field.includes('.')) {
      const [parent, child] = field.split('.');
      setFormData(prev => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: value
        }
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [field]: value
      }));
    }
  };

  const nextStep = async () => {
    if (step === 6) {
      try {
        setIsLoading(true);
        
        // Track form submission
        window.gtag('event', 'lead_submission', {
          'event_category': 'Form',
          'event_label': 'Complete',
          'location': formData.location,
          'relationship': formData.relationship,
          'care_type': formData.careType,
          'timeline': formData.timeline,
          'interests': formData.interests.join(', '),
          'first_name': formData.contact.firstName,
          'last_name': formData.contact.lastName,
          'email': formData.contact.email,
          'phone': formData.contact.phone,
          'utm_source': formData.utm?.source || '',
          'utm_medium': formData.utm?.medium || '',
          'utm_campaign': formData.utm?.campaign || '',
          'utm_term': formData.utm?.term || '',
          'utm_content': formData.utm?.content || '',
          'gclid': formData.utm?.gclid || ''
        });

        // Add Facebook Lead event tracking
        if (window.fbq) {
          window.fbq('track', 'Lead');
        }

        const response = await fetch('/api/leads', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData)
        });

        if (!response.ok) {
          const errorText = await response.text();
          console.error('Server error:', errorText);
          throw new Error(`Server error: ${response.status} ${errorText}`);
        }

        const data = await response.json();
        console.log('\n=== SERVER RESPONSE ===');
        console.log('Response:', data);
        console.log('Hubspot Sync Status:', data.hubspot?.synced ? 'Success ✅' : 'Failed ❌');
        if (data.hubspot?.error) {
          console.error('Hubspot Error:', data.hubspot.error);
        }
        console.log('=== END SERVER RESPONSE ===\n');

        if (data.success) {
          setIsSubmitted(true);
          
          // Create URL with UTM parameters
          const baseUrl = 'https://www.aegisliving.com/find-a-location/';
          const utmParams = new URLSearchParams({
            utm_source: formData.utm?.source || '',
            utm_medium: formData.utm?.medium || '',
            utm_campaign: formData.utm?.campaign || '',
            utm_term: formData.utm?.term || '',
            utm_content: formData.utm?.content || ''
          });

          // Only add non-empty UTM parameters
          const cleanUtmParams = new URLSearchParams();
          utmParams.forEach((value, key) => {
            if (value) cleanUtmParams.append(key, value);
          });

          // Add GCLID if it exists
          if (formData.utm?.gclid) {
            cleanUtmParams.append('gclid', formData.utm.gclid);
          }

          // Create final URL
          const redirectUrl = cleanUtmParams.toString() 
            ? `${baseUrl}?${cleanUtmParams.toString()}`
            : baseUrl;

          // Add a small delay before redirect to ensure logs are visible
          setTimeout(() => {
            window.location.href = redirectUrl;
          }, 500);
        } else {
          console.error('Submission failed:', data.error);
          setIsLoading(false);
        }
      } catch (error) {
        console.error('Error submitting form:', error);
        
        // Log submission error event
        console.log('Sending GA4 lead_submission_error event:', error.message);
        window.gtag('event', 'lead_submission_error', {
          'event_category': 'Form',
          'event_label': error.message
        });
        
        setIsLoading(false);
      }
    } else {
      // Track step completion with specific values
      const stepValue = getStepValue(step);
      const eventData = {
        'event_category': 'Form',
        'event_label': `Step ${step}`,
        'step_number': step,
        'step_name': getStepName(step),
        'step_value': stepValue,
        // Add specific parameter based on step
        ...(step === 1 && { 'location_selected': formData.location }),
        ...(step === 2 && { 'relationship_selected': formData.relationship }),
        ...(step === 3 && { 'care_type_selected': formData.careType }),
        ...(step === 4 && { 'timeline_selected': formData.timeline }),
        ...(step === 5 && { 'interests_selected': formData.interests.join(', ') })
      };

      console.log('Sending GA4 form_step_complete event:', eventData);
      window.gtag('event', 'form_step_complete', eventData);
      
      setStep(prev => prev + 1);
    }
  };

  const prevStep = () => {
    if (step > 1) {
      setStep(prev => prev - 1);
    }
  };

  const renderStepContent = (content) => {
    if (Array.isArray(content)) {
      return content.map((item, index) => (
        <div key={index}>
          {item}
        </div>
      ));
    }
    return content;
  };

  const renderLocationStep = () => (
    <div className="space-y-6">
      {renderStepContent(
        <div className="text-center mb-6">
          <MapPin className="w-12 h-12 mx-auto text-aegis-blue mb-3" />
          <h2 className="text-2xl font-semibold">Where would you like to live?</h2>
          <p className="text-gray-600 mt-1">We'll show you nearby Aegis Living communities</p>
        </div>,
        0
      )}
      {renderStepContent(
        <div className="max-w-2xl mx-auto">
          <div className="max-w-md mx-auto mb-8">
            <div className="bg-white rounded-xl p-6 border-2 border-aegis-blue/30 shadow-sm">
              <div className="mb-4">
                <CityAutocomplete
                  value={formData.location}
                  onChange={(value) => handleInputChange("location", value)}
                />
              </div>
              <div className="flex justify-center">
                <button
                  className={`flex items-center justify-center gap-2 px-8 py-3 rounded-lg text-lg font-semibold transition-all
                    ${canProceed() 
                      ? "bg-aegis-blue text-white hover:bg-aegis-blue/90" 
                      : "bg-gray-200 text-gray-400 cursor-not-allowed"}`}
                  onClick={nextStep}
                  disabled={!canProceed()}
                >
                  Continue
                  <ArrowRight size={20} />
                </button>
              </div>
              <div className="flex items-center justify-center gap-2 mt-4 text-aegis-blue/80 text-sm">
                <MapPin className="w-4 h-4" />
                <span>30+ West Coast Communities</span>
              </div>
            </div>
          </div>
          
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {[
              {
                name: "Sarah Thompson",
                role: "Resident Family Member",
                image: "https://images.unsplash.com/photo-1581579438747-1dc8d17bbce4",
                quote: "They helped us find the perfect community close to family. It truly feels like home."
              },
              {
                name: "Robert Chen",
                role: "Current Resident",
                image: "https://images.unsplash.com/photo-1566616213894-2d4e1baee5d8",
                quote: "The staff and community here have become my second family. Best decision I've made."
              },
              {
                name: "Emily Martinez",
                role: "Resident Daughter",
                image: "https://images.unsplash.com/photo-1544005313-94ddf0286df2",
                quote: "The care and attention my mother receives is exceptional. We couldn't be happier."
              }
            ].map((testimonial, index) => (
              <div key={index} className="bg-white rounded-lg p-4 border border-aegis-blue/10 shadow-sm">
                <div className="flex items-center gap-3 mb-3">
                  <img 
                    src={testimonial.image}
                    alt={testimonial.name}
                    className="w-12 h-12 rounded-full object-cover border border-aegis-blue/20"
                  />
                  <div>
                    <div className="font-semibold text-aegis-blue text-sm">{testimonial.name}</div>
                    <div className="text-xs text-gray-500">{testimonial.role}</div>
                  </div>
                </div>
                <div className="text-aegis-blue mb-2">★★★★★</div>
                <blockquote className="text-gray-600 text-sm">
                  "{testimonial.quote}"
                </blockquote>
              </div>
            ))}
          </div>
        </div>,
        1
      )}
    </div>
  );

  const renderRelationshipStep = () => (
    <div className="space-y-6">
      {renderStepContent(
        <div className="text-center mb-8">
          <Heart className="w-12 h-12 mx-auto text-aegis-blue mb-4" />
          <h2 className="text-2xl font-semibold">Who are you looking for?</h2>
          <p className="text-gray-600 mt-2">Help us personalize your search</p>
        </div>,
        0
      )}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 w-full max-w-2xl mx-auto">
        {renderStepContent(
          [
            {
              title: "For Myself",
              description: "I'm exploring senior living options for me"
            },
            {
              title: "For a Parent",
              description: "I'm helping my mother or father find the right place"
            },
            {
              title: "For my Spouse",
              description: "I'm looking for options for my husband or wife"
            },
            {
              title: "For Someone Else",
              description: "I'm helping another loved one or friend"
            }
          ].map((option) => (
            <button
              className={`w-full p-4 rounded-lg border-2 transition-all duration-300 text-left bg-white h-[80px] sm:h-[80px] flex flex-col justify-center ${
                formData.relationship === option.title
                  ? "border-[3px] border-aegis-blue bg-gradient-to-br from-aegis-blue/5 to-aegis-blue/10"
                  : "border-gray-200 hover:border-aegis-blue/50"
              }`}
              onClick={() => handleInputChange("relationship", option.title)}
            >
              <div className="font-semibold">{option.title}</div>
              <div className="text-sm text-gray-600">{option.description}</div>
            </button>
          )),
          1
        )}
      </div>
    </div>
  );

  const renderCareTypeStep = () => (
    <div className="space-y-6">
      {renderStepContent(
        <div className="text-center mb-8">
          <Heart className="w-12 h-12 mx-auto text-aegis-blue mb-4" />
          <h2 className="text-2xl font-semibold">What type of support are you looking for?</h2>
          <p className="text-gray-600 mt-2">Select the option that best fits your needs</p>
        </div>,
        0
      )}
      {renderStepContent(
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 w-full max-w-2xl mx-auto">
          {[
            {
              title: "Independent Living",
              description: "Active lifestyle with minimal assistance"
            },
            {
              title: "Assisted Living",
              description: "Daily support with regular activities"
            },
            {
              title: "Memory Care",
              description: "Specialized care for Alzheimer's and dementia"
            },
            {
              title: "Short-Term Stay",
              description: "Temporary care and recovery support"
            }
          ].map((option) => (
            <button
              key={option.title}
              className={`w-full p-4 rounded-lg border-2 transition-all duration-300 text-left bg-white h-[60px] sm:h-[80px] flex flex-col justify-center ${
                formData.careType === option.title
                  ? "border-[3px] border-aegis-blue bg-gradient-to-br from-aegis-blue/5 to-aegis-blue/10"
                  : "border-gray-200 hover:border-aegis-blue/50"
              }`}
              onClick={() => handleInputChange("careType", option.title)}
            >
              <div className="font-semibold">{option.title}</div>
              <div className="text-sm text-gray-600">{option.description}</div>
            </button>
          ))}
        </div>,
        1
      )}
    </div>
  );

  const renderTimelineStep = () => (
    <div className="space-y-6">
      {renderStepContent(
        <div className="text-center mb-8">
          <Calendar className="w-12 h-12 mx-auto text-aegis-blue mb-4" />
          <h2 className="text-2xl font-semibold">When are you planning to make the move?</h2>
          <p className="text-gray-600 mt-2">This helps us prepare for your arrival</p>
        </div>,
        0
      )}
      {renderStepContent(
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 w-full max-w-2xl mx-auto">
          {[
            "Within 1 month",
            "1-3 months",
            "3-6 months",
            "Just exploring"
          ].map((option) => (
            <button
              key={option}
              className={`w-full p-4 rounded-lg border-2 transition-all duration-300 bg-white h-[50px] sm:h-[80px] flex items-center justify-center ${
                formData.timeline === option
                  ? "border-[3px] border-aegis-blue bg-gradient-to-br from-aegis-blue/5 to-aegis-blue/10"
                  : "border-gray-200 hover:border-aegis-blue/50"
              }`}
              onClick={() => handleInputChange("timeline", option)}
            >
              {option}
            </button>
          ))}
        </div>,
        1
      )}
    </div>
  );

  const renderLivingStep = () => (
    <div className="space-y-6">
      {renderStepContent(
        <div className="text-center mb-8">
          <Home className="w-12 h-12 mx-auto text-aegis-blue mb-4" />
          <h2 className="text-2xl font-semibold">What's most important to you?</h2>
          <p className="text-gray-600 mt-2">Select all that interest you</p>
        </div>,
        0
      )}
      {renderStepContent(
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 w-full max-w-2xl mx-auto">
          {[
            "24/7 Care",
            "Memory Care",
            "Respite Care",
            "Location",
            "Fine Dining",
            "Social Activities",
            "Fitness Center",
            "Garden Access",
            "Arts & Culture",
            "Transportation",
            "Pet Friendly",
            "Security"
          ].map((option) => (
            <button
              key={option}
              className={`w-full p-4 rounded-lg border-2 transition-all duration-300 bg-white h-[50px] sm:h-[80px] flex items-center justify-center text-center ${
                formData.interests.includes(option)
                  ? "border-[3px] border-aegis-blue bg-gradient-to-br from-aegis-blue/5 to-aegis-blue/10"
                  : "border-gray-200 hover:border-aegis-blue/50"
              }`}
              onClick={() => {
                const newInterests = formData.interests.includes(option)
                  ? formData.interests.filter(i => i !== option)
                  : [...formData.interests, option];
                handleInputChange("interests", newInterests);
              }}
            >
              {option}
            </button>
          ))}
        </div>,
        1
      )}
    </div>
  );

  const validateEmail = (email) => {
    return email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
  };

  const validatePhone = (phone) => {
    return phone.replace(/\D/g, '').length === 10;
  };

  const validateField = (field, value) => {
    switch (field) {
      case 'contact.email':
        if (!validateEmail(value)) {
          setErrors(prev => ({ ...prev, email: 'Please enter a valid email address' }));
        } else {
          setErrors(prev => ({ ...prev, email: '' }));
        }
        break;
      case 'contact.phone':
        if (!validatePhone(value)) {
          setErrors(prev => ({ ...prev, phone: 'Please enter a valid phone number' }));
        } else {
          setErrors(prev => ({ ...prev, phone: '' }));
        }
        break;
      case 'contact.firstName':
        if (!value.trim()) {
          setErrors(prev => ({ ...prev, firstName: 'First name is required' }));
        } else {
          setErrors(prev => ({ ...prev, firstName: '' }));
        }
        break;
      case 'contact.lastName':
        if (!value.trim()) {
          setErrors(prev => ({ ...prev, lastName: 'Last name is required' }));
        } else {
          setErrors(prev => ({ ...prev, lastName: '' }));
        }
        break;
      default:
        break;
    }
  };

  const handleFieldChange = (field, value) => {
    handleInputChange(field, value);
    validateField(field, value);
  };

  const renderContactStep = () => (
    <div className="space-y-6">
      {renderStepContent(
        <div className="text-center mb-8">
          <h2 className="text-2xl font-semibold">We've found some perfect matches!</h2>
          <p className="text-gray-600 mt-2">Share your contact details to view your personalized options</p>
        </div>,
        0
      )}
      {renderStepContent(
        <div className="max-w-md mx-auto space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <input
                type="text"
                placeholder="First Name"
                className={`w-full p-4 border rounded-lg ${errors.firstName ? 'border-red-500' : 'border-gray-300'}`}
                value={formData.contact.firstName}
                onChange={(e) => handleFieldChange("contact.firstName", e.target.value)}
                autoComplete="given-name"
              />
              {errors.firstName && (
                <p className="mt-1 text-sm text-red-500">{errors.firstName}</p>
              )}
            </div>
            <div>
              <input
                type="text"
                placeholder="Last Name"
                className={`w-full p-4 border rounded-lg ${errors.lastName ? 'border-red-500' : 'border-gray-300'}`}
                value={formData.contact.lastName}
                onChange={(e) => handleFieldChange("contact.lastName", e.target.value)}
                autoComplete="family-name"
              />
              {errors.lastName && (
                <p className="mt-1 text-sm text-red-500">{errors.lastName}</p>
              )}
            </div>
          </div>
          <div>
            <input
              type="email"
              placeholder="Email Address"
              className={`w-full p-4 border rounded-lg ${errors.email ? 'border-red-500' : 'border-gray-300'}`}
              value={formData.contact.email}
              onChange={(e) => handleFieldChange("contact.email", e.target.value)}
              autoComplete="email"
            />
            {errors.email && (
              <p className="mt-1 text-sm text-red-500">{errors.email}</p>
            )}
          </div>
          <div>
            <PhoneInput
              value={formData.contact.phone}
              onChange={(value) => handleFieldChange("contact.phone", value)}
              className={`w-full p-4 border rounded-lg ${errors.phone ? 'border-red-500' : 'border-gray-300'}`}
            />
            {errors.phone && (
              <p className="mt-1 text-sm text-red-500">{errors.phone}</p>
            )}
          </div>
          <label className="flex items-start gap-2">
            <input
              type="checkbox"
              checked={formData.contact.consent}
              onChange={(e) => handleInputChange("contact.consent", e.target.checked)}
              className="mt-1"
            />
            <span className="text-sm text-gray-600">
              I agree to be contacted about Aegis Living communities and understand that my consent isn't required to learn more.
            </span>
          </label>

          {/* Hidden UTM fields */}
          <input type="hidden" name="utm_source" value={formData.utm.source} />
          <input type="hidden" name="utm_medium" value={formData.utm.medium} />
          <input type="hidden" name="utm_campaign" value={formData.utm.campaign} />
          <input type="hidden" name="utm_term" value={formData.utm.term} />
          <input type="hidden" name="utm_content" value={formData.utm.content} />
          <input type="hidden" name="gclid" value={formData.utm.gclid} />
          <input type="hidden" name="referrer" value={formData.utm.referrer} />
          <input type="hidden" name="landing_page" value={formData.utm.landing_page} />
          
          {/* Hidden Hubspot fields */}
          <input type="hidden" name="community_code" value={formData.hubspot.community_code} />
          <input type="hidden" name="manager_phone" value={formData.hubspot.manager_phone} />
          <input type="hidden" name="manager_email" value={formData.hubspot.manager_email} />
          <input type="hidden" name="manager" value={formData.hubspot.manager} />
          <input type="hidden" name="hs_analytics_source_data_1" value={formData.hubspot.hs_analytics_source_data_1} />
          <input type="hidden" name="hs_analytics_source_data_2" value={formData.hubspot.hs_analytics_source_data_2} />
        </div>,
        1
      )}
    </div>
  );

  const renderCurrentStep = () => {
    switch(step) {
      case 1: return renderLocationStep();
      case 2: return renderRelationshipStep();
      case 3: return renderCareTypeStep();
      case 4: return renderTimelineStep();
      case 5: return renderLivingStep();
      case 6: return renderContactStep();
      default: return null;
    }
  };

  const canProceed = () => {
    switch(step) {
      case 1: return formData.location.length > 0;
      case 2: return formData.relationship.length > 0;
      case 3: return formData.careType.length > 0;
      case 4: return formData.timeline.length > 0;
      case 5: return formData.interests.length > 0;
      case 6: return (
        formData.contact.firstName.trim() && 
        formData.contact.lastName.trim() && 
        validateEmail(formData.contact.email) &&
        validatePhone(formData.contact.phone) &&
        formData.contact.consent
      );
      default: return false;
    }
  };

  if (isSubmitted) {
    return <SuccessPage />;
  }

  if (isLoading) {
    return (
      <div className="min-h-screen bg-transparent flex items-center justify-center">
        <BackgroundDecoration />
        <div className="text-center transition-opacity duration-500 px-6 max-w-sm mx-auto">
          <h2 className="text-2xl font-semibold mb-6">
            Finding your optimal location match...
          </h2>
          <div className="flex items-center justify-center gap-3 text-aegis-blue">
            <Loader2 className="w-8 h-8 animate-spin" />
            <span className="text-xl min-w-[100px] text-left">Searching...</span>
          </div>
        </div>
      </div>
    );
  }

  const renderHeader = () => (
    <div className="relative">
      {/* Top Bar with Logo and Phone */}
      <div className="max-w-[1200px] mx-auto px-6 py-4 flex justify-between items-center">
        <img 
          src="https://cdn2.aegisliving.com/wp-content/uploads/2023/06/aegis-living-logo-blue.svg"
          alt="Aegis Assisted Living"
          className="h-12 sm:h-16"
        />
        <div className="flex flex-col items-end text-aegis-blue">
          <span className="text-sm font-medium">Questions?</span>
          <a href="tel:(425) 659-3432" className="flex items-center gap-2 font-semibold">
            <Phone size={18} className="sm:w-5 sm:h-5" />
            (425) 659-3432
          </a>
        </div>
      </div>

      {/* Hero Image Only - Updated height and removed flip */}
      <div className="relative h-[200px] sm:h-[400px] w-full overflow-hidden">
        <div 
          className="absolute inset-0 bg-cover bg-center"
          style={{ 
            backgroundImage: 'url(https://www.aegisliving.com/wp-content/uploads/2023/07/Resident-couple-kiss.jpg.webp)',
            backgroundPosition: '50% 50%',
            objectFit: 'cover'
          }}
        />
      </div>

      {/* Title and Subtitle (Centered below hero) */}
      <div className="max-w-[1200px] mx-auto px-6 text-center py-8">
        <h1 className="font-heading heading-text text-gray-800 mb-2 sm:mb-4">
          Find Your Perfect Senior Living Community
        </h1>
        <p className="font-body body-text text-gray-600">
          Let us help you discover a warm, welcoming place to call home
        </p>
      </div>
    </div>
  );

  const renderProgressBar = () => (
    <div className="w-full bg-gray-100 h-2 rounded-full mb-6">
      <div 
        className="bg-aegis-blue h-2 rounded-full transition-all duration-500"
        style={{ width: `${(step / 6) * 100}%` }}
      />
    </div>
  );

  const renderFloatingPhoneButton = () => (
    <a
      href="tel:8885550123"
      className="fixed bottom-6 right-6 w-14 h-14 bg-aegis-blue rounded-full shadow-lg flex items-center justify-center text-white hover:bg-aegis-blue/90 transition-colors z-50 sm:hidden"
      aria-label="Call us"
    >
      <Phone size={24} />
    </a>
  );

  const handleVideoClick = () => {
    // Track video modal open
    window.gtag('event', 'video_play', {
      'event_category': 'Engagement',
      'event_label': 'Community Video',
      'video_title': 'Aegis Living Kirkland Waterfront'
    });
    
    setIsVideoModalOpen(true);
  };

  const renderFloatingVideoButton = () => (
    <button
      onClick={handleVideoClick}
      className="fixed bottom-4 left-4 bg-white text-aegis-blue rounded-lg shadow-lg 
      hover:shadow-xl transition-all duration-300 flex items-center gap-2 px-4 py-3 z-50"
      aria-label="Watch Video"
    >
      <Video size={20} />
      <span className="font-medium">Aging is Living</span>
    </button>
  );

  // Helper function to get step names
  const getStepName = (stepNumber) => {
    switch(stepNumber) {
      case 1: return 'Location';
      case 2: return 'Relationship';
      case 3: return 'Care Type';
      case 4: return 'Timeline';
      case 5: return 'Living Arrangement';
      case 6: return 'Contact Info';
      default: return 'Unknown';
    }
  };

  // Helper function to get step values
  const getStepValue = (stepNumber) => {
    switch(stepNumber) {
      case 1: return formData.location;
      case 2: return formData.relationship;
      case 3: return formData.careType;
      case 4: return formData.timeline;
      case 5: return formData.livingArrangement;
      case 6: return `${formData.contact.firstName} ${formData.contact.lastName}`;
      default: return '';
    }
  };

  return (
    <div className="min-h-screen bg-transparent flex flex-col">
      <BackgroundDecoration />
      {renderHeader()}
      <div className="max-w-3xl mx-auto px-6 w-full flex-1 flex flex-col pb-16">
        {renderProgressBar()}
        {renderCurrentStep()}
        
        {/* Navigation Buttons - Only show if not on first step */}
        {step > 1 && (
          <div className="flex justify-center mt-8 gap-4">
            <button
              onClick={prevStep}
              className="px-6 py-2 text-aegis-blue hover:text-aegis-blue/80 font-medium flex items-center gap-2"
            >
              <ArrowRight className="w-5 h-5 rotate-180" />
              Back
            </button>
            <button
              onClick={nextStep}
              disabled={!canProceed()}
              className={`px-6 py-2 rounded-md font-medium flex items-center gap-2 
                ${canProceed() 
                  ? 'bg-aegis-blue text-white hover:bg-aegis-blue/90' 
                  : 'bg-gray-200 text-gray-400 cursor-not-allowed'
                }`}
            >
              {step === 6 ? 'Submit' : 'Continue'}
              {step !== 6 && <ArrowRight className="w-5 h-5" />}
            </button>
          </div>
        )}
      </div>
      {renderFloatingVideoButton()}
      <VideoModal 
        isOpen={isVideoModalOpen} 
        onClose={() => setIsVideoModalOpen(false)}
        videoUrl="https://www.youtube.com/embed/4EEJXnlWeiQ"
        autoPlay={true}
      />
    </div>
  );
};

export default AegisFunnel;
