import React, { useState } from 'react';
import { MapPin, Search } from 'lucide-react';
import AddressAutocomplete from './AddressAutocomplete';

const LocationSearch = ({ onLocationSelect }) => {
  const [showAddressInput, setShowAddressInput] = useState(false);
  const [error, setError] = useState(null);

  const handleGetLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          onLocationSelect({
            coordinates: {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            }
          });
          setError(null);
        },
        (error) => {
          console.error('Geolocation error:', error);
          setError('Unable to get your location. Please enter an address instead.');
          setShowAddressInput(true);
        }
      );
    } else {
      setError('Geolocation is not supported by your browser. Please enter an address instead.');
      setShowAddressInput(true);
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-4 mb-6">
      <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4">
        <h3 className="text-lg font-semibold text-center md:text-left">Find Communities Near You</h3>
        
        {!showAddressInput ? (
          <div className="flex flex-col md:flex-row items-center gap-4">
            <button
              onClick={handleGetLocation}
              className="w-full md:w-auto inline-flex items-center justify-center gap-2 bg-aegis-blue text-white px-4 py-2 rounded-lg hover:bg-aegis-blue/90 transition-colors"
            >
              <MapPin size={18} />
              Use My Location
            </button>
            <div className="flex items-center gap-2">
              <span className="text-gray-400">or</span>
              <button
                onClick={() => setShowAddressInput(true)}
                className="text-aegis-blue hover:underline"
              >
                enter an address
              </button>
            </div>
          </div>
        ) : (
          <div className="flex flex-col md:flex-row items-center gap-4 w-full md:w-auto">
            <div className="w-full md:w-auto">
              <AddressAutocomplete
                onChange={(addressData) => {
                  if (addressData.coordinates.lat && addressData.coordinates.lng) {
                    onLocationSelect(addressData);
                    setError(null);
                  } else {
                    setError('Unable to get coordinates for this address. Please try another.');
                  }
                }}
              />
            </div>
            <button
              onClick={() => {
                setShowAddressInput(false);
                setError(null);
              }}
              className="text-aegis-blue hover:underline"
            >
              use my location instead
            </button>
          </div>
        )}
      </div>
      
      {error && (
        <div className="mt-2 text-red-500 text-sm">
          {error}
        </div>
      )}
    </div>
  );
};

export default LocationSearch; 