import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CommunityTemplate from './CommunityTemplate';
import { Loader2 } from 'lucide-react';
import Navigation from './Navigation';
import { useAuth } from '../contexts/AuthContext';

const API_URL = process.env.REACT_APP_API_URL || '';

const CommunityPage = () => {
  const { slug } = useParams();
  const { isAuthenticated } = useAuth();
  const [community, setCommunity] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [allCommunities, setAllCommunities] = useState([]);

  useEffect(() => {
    fetchCommunity();
  }, [slug]);

  useEffect(() => {
    if (community?.useGalleryForHeader && community?.galleryImages?.length > 0) {
      const interval = setInterval(() => {
        setCurrentImageIndex((prevIndex) => 
          prevIndex === community.galleryImages.length - 1 ? 0 : prevIndex + 1
        );
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [community]);

  useEffect(() => {
    const fetchAllCommunities = async () => {
      try {
        const response = await fetch(`${API_URL}/api/communities/public`);
        if (response.ok) {
          const data = await response.json();
          setAllCommunities(data.communities);
        }
      } catch (error) {
        console.error('Error fetching all communities:', error);
      }
    };

    fetchAllCommunities();
  }, []);

  const fetchCommunity = async () => {
    try {
      console.log('Fetching community:', slug);
      const response = await fetch(`${API_URL}/api/community/${slug}`);
      
      if (!response.ok) {
        console.error('Community fetch error:', response.status);
        throw new Error('Community not found');
      }
      
      const data = await response.json();
      console.log('Community data:', data);
      setCommunity(data);
    } catch (error) {
      console.error('Error fetching community:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="fixed inset-0 bg-white flex flex-col items-center justify-center z-50">
        <Loader2 className="w-12 h-12 text-aegis-blue animate-spin mb-4" />
        <h2 className="text-2xl font-semibold text-gray-800 mb-2">
          The Perfect Community
        </h2>
        <p className="text-lg text-gray-600">
          is coming right up...
        </p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-xl text-red-600">Error: {error}</div>
      </div>
    );
  }

  if (!community) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-xl text-gray-600">Community not found</div>
      </div>
    );
  }

  return (
    <>
      {!isAuthenticated && <Navigation />}
      <CommunityTemplate 
        communityData={community} 
        currentImageIndex={currentImageIndex}
        allCommunities={allCommunities}
      />
    </>
  );
};

export default CommunityPage; 