import React, { useState, useEffect, useRef } from "react";
import { ArrowRight, Phone, Video, Loader2, Clock, Utensils, Dumbbell, Building2, Crown, Heart, Film, Building, Stethoscope, Activity, Dog, Scissors, Gamepad2, BedSingle, Bed, Home, Calendar, Info, Check, MapPin } from "lucide-react";
import SuccessPage from './SuccessPage';
import BackgroundDecoration from './BackgroundDecoration';
import PhoneInput from './PhoneInput';
import CityAutocomplete from './CityAutocomplete';

const CARE_TYPE_DESCRIPTIONS = {
  "Light Assisted Living": "For seniors who need minimal assistance with daily activities while maintaining their independence.",
  "Assisted Living": "Comprehensive support with daily activities, medication management, and personalized care plans.",
  "Transitional Care": "Short-term care focused on recovery and rehabilitation after hospital stays.",
  "End Of Life Care": "Compassionate care focused on comfort and dignity in life's final stages.",
  "Respite Care": "Temporary care services providing relief for primary caregivers.",
  "Memory Care": "Specialized environment and care programs for those with Alzheimer's and other forms of dementia."
};

const API_URL = process.env.REACT_APP_API_URL || '';

const styles = `
  @keyframes pulseShadow {
    0% {
      box-shadow: 0 0 0 0 rgba(59, 130, 246, 0.2);
    }
    50% {
      box-shadow: 0 0 20px 0 rgba(59, 130, 246, 0.3);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(59, 130, 246, 0.2);
    }
  }
  .pulse-1 {
    animation: pulseShadow 3s infinite;
  }
  .pulse-2 {
    animation: pulseShadow 3s infinite;
    animation-delay: 0.75s;
  }
  .pulse-3 {
    animation: pulseShadow 3s infinite;
    animation-delay: 1.5s;
  }
  .pulse-4 {
    animation: pulseShadow 3s infinite;
    animation-delay: 2.25s;
  }
  .hand-drawn-arrow {
    position: absolute;
    top: 3px;
    left: 110%;
    width: 80px;
    height: 60px;
    z-index: 10;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 60' fill='none' stroke='%233B82F6' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M0 10 Q40 10, 40 50 M25 35 L40 50 L55 35' style='stroke-dasharray: 4,4;'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
  }
  @media (max-width: 768px) {
    .start-here-container {
      display: none;
    }
  }
  @keyframes progressBar {
    0% { width: 0%; }
    100% { width: 100%; }
  }
  
  .animate-progressBar {
    animation: progressBar 5s linear forwards;
  }

  @keyframes fadeInOut {
    0%, 100% { opacity: 0.4; }
    25% { opacity: 1; }
  }

  @keyframes progress {
    0% { transform: scaleX(0); }
    100% { transform: scaleX(1); }
  }

  .animate-progress {
    transform-origin: left;
  }
`;

const VideoLP = ({ isEmbedded = false }) => {
  // Get config from window
  const config = window.AegisFormConfig || {};
  
  // Add location-specific constants
  const LOCATION_INFO = {
    name: "",
    source: "video-lp",
    manager: {
      name: "",
      phone: "",
      email: "",
      community_code: ""
    }
  };

  // Start at step 1 since we're skipping location
  const [step, setStep] = useState(1);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: ''
  });
  const [formData, setFormData] = useState({
    location: "",
    relationship: "",
    careType: "",
    timeline: "",
    ageBracket: "",
    livingArrangement: "",
    interests: [],
    contact: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      consent: true
    },
    source: LOCATION_INFO.source,
    hubspot: {
      community_code: LOCATION_INFO.manager.community_code,
      manager_phone: LOCATION_INFO.manager.phone,
      manager_email: LOCATION_INFO.manager.email,
      manager: LOCATION_INFO.manager.name,
      hs_analytics_source_data_1: '',
      hs_analytics_source_data_2: '',
      selected_location: ''
    },
    utm: {
      source: '',
      medium: '',
      campaign: '',
      term: '',
      content: '',
      gclid: '',
      referrer: '',
      landing_page: ''
    }
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showPhoneTooltip, setShowPhoneTooltip] = useState(false);
  const [showEmailTooltip, setShowEmailTooltip] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [successData, setSuccessData] = useState(null);

  // Helper function to get step names - Move this before useEffect
  const getStepName = (stepNumber) => {
    switch(stepNumber) {
      case 1: return 'Location';
      case 2: return 'Relationship';
      case 3: return 'Care Type';
      case 4: return 'Timeline';
      case 5: return 'Age Bracket';
      case 6: return 'Interests';
      case 7: return 'Contact Info';
      default: return 'Unknown';
    }
  };

  // Helper function to get step values - Move this before useEffect
  const getStepValue = (stepNumber) => {
    switch(stepNumber) {
      case 1: return formData.location;
      case 2: return formData.relationship;
      case 3: return formData.careType;
      case 4: return formData.timeline;
      case 5: return formData.ageBracket;
      case 6: return formData.interests.join(', ');
      case 7: return `${formData.contact.firstName} ${formData.contact.lastName}`;
      default: return '';
    }
  };

  // GA4 Events
  useEffect(() => {
    window.gtag('event', 'form_start', {
      'event_category': 'Form',
      'event_label': 'Form Started',
      'form_type': 'Video LP'
    });
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      if (!isSubmitted) {
        window.gtag('event', 'form_abandonment', {
          'event_category': 'Form',
          'event_label': `Step ${step}`,
          'step_number': step,
          'step_name': getStepName(step),
          'selected_location': formData.location
        });
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [step, isSubmitted, formData.location]);

  // Add these missing functions
  const validateEmail = (email) => {
    return email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
  };

  const validatePhone = (phone) => {
    return phone.replace(/\D/g, '').length === 10;
  };

  // Add UTM parameter handling
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const utmSource = urlParams.get('utm_source');
    const utmMedium = urlParams.get('utm_medium');
    const utmCampaign = urlParams.get('utm_campaign');
    const utmTerm = urlParams.get('utm_term');
    const utmContent = urlParams.get('utm_content');
    const gclid = urlParams.get('gclid');

    setFormData(prev => ({
      ...prev,
      utm: {
        source: utmSource || '',
        medium: utmMedium || '',
        campaign: utmCampaign || '',
        term: utmTerm || '',
        content: utmContent || '',
        gclid: gclid || '',
        referrer: document.referrer,
        landing_page: window.location.href
      },
      hubspot: {
        ...prev.hubspot,
        hs_analytics_source_data_1: utmCampaign || '',
        hs_analytics_source_data_2: utmSource && utmMedium ? `${utmSource}/${utmMedium}` : ''
      }
    }));
  }, []);

  // Update the submit function to include location-specific tracking
  const nextStep = async () => {
    if (step === 1) {
      // Track location selection
      const currentStepData = {
        'event_category': 'Form',
        'event_label': `Step ${step}`,
        'step_number': step,
        'step_name': getStepName(step),
        'step_value': formData.location,
        'form_type': 'Video LP',
        'selected_location': formData.location
      };
      window.gtag('event', 'form_step_complete', currentStepData);
      window.gtag('event', 'location_selected', {
        'event_category': 'Form',
        'event_label': formData.location,
        'location_value': formData.location,
        'form_type': 'Video LP'
      });
      setStep(prev => prev + 1);
      scrollToTop();
      return;
    }

    if (step === 6) {
      handleInterestsComplete();
      return;
    }
    
    if (step === 7) {
      try {
        setIsLoading(true);
        
        // Track form submission with location
        window.gtag('event', 'form_submission', {
          'event_category': 'Form',
          'event_label': 'Complete',
          'form_type': 'Video LP',
          'selected_location': formData.location,
          'relationship': formData.relationship,
          'care_type': formData.careType,
          'timeline': formData.timeline,
          'interests': formData.interests.join(', ')
        });

        // Add Facebook Lead event tracking
        if (window.fbq) {
          window.fbq('track', 'Lead');
        }

        console.log('Submitting to:', `${API_URL}/api/leads`);
        
        const response = await fetch(`${API_URL}/api/leads`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            ...formData,
            source: 'video-lp',
            selected_location: formData.location,
            hubspot: {
              ...formData.hubspot,
              selected_location: formData.location
            }
          })
        });

        if (!response.ok) {
          const errorText = await response.text();
          console.error('Server response:', errorText);
          throw new Error('Failed to submit lead');
        }

        const data = await response.json();
        console.log('Form submitted successfully:', data);

        setIsLoading(false);
        setIsSubmitted(true);
        setSuccessData({
          leadName: `${formData.contact.firstName} ${formData.contact.lastName}`,
          selectedLocation: formData.location
        });
      } catch (error) {
        console.error('Form submission error:', error);
        console.error('Error details:', error.message);
        setIsLoading(false);
        alert('There was an error submitting your information. Please try again or contact us directly.');
        
        // Track submission error with location info
        window.gtag('event', 'lead_submission_error', {
          'event_category': 'Form',
          'event_label': error.message,
          'selected_location': formData.location
        });
      }
    } else {
      // Move to next step after tracking is complete
      setStep(prev => prev + 1);
      scrollToTop();
    }
  };

  const prevStep = () => {
    setStep(prev => prev - 1);
    scrollToTop();
  };

  // Update handleInputChange to be async and return a promise
  const handleInputChange = (field, value) => {
    return new Promise((resolve) => {
      setFormData(prev => {
        const newData = {
          ...prev,
          [field]: value
        };
        resolve(newData);
        return newData;
      });
    });
  };

  const handleContactChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      contact: {
        ...prev.contact,
        [field]: value
      }
    }));
    
    // Clear error when user types
    if (errors[field]) {
      setErrors(prev => ({
        ...prev,
        [field]: ''
      }));
    }
  };

  const handleInterestToggle = (interest) => {
    // Update form data with new interest selection
    setFormData(prev => {
      const newInterests = prev.interests.includes(interest)
        ? prev.interests.filter(i => i !== interest)
        : [...prev.interests, interest];
        
      // Track the interest selection/deselection
      window.gtag('event', 'interest_toggle', {
        'event_category': 'Form',
        'event_label': interest,
        'action': prev.interests.includes(interest) ? 'removed' : 'added',
        'form_type': 'Video LP',
        'selected_location': prev.location
      });

      return {
        ...prev,
        interests: newInterests
      };
    });
    scrollToTop();
  };

  const handleInterestsComplete = () => {
    if (formData.interests.length > 0) {
      // Track step completion with all selected interests
      const currentStepData = {
        'event_category': 'Form',
        'event_label': `Step ${step}`,
        'step_number': step,
        'step_name': getStepName(step),
        'step_value': formData.interests.join(', '),
        'form_type': 'Video LP',
        'selected_location': formData.location,
        'interest_count': formData.interests.length
      };
      
      window.gtag('event', 'form_step_complete', currentStepData);
      console.log('GA Event - Step Complete (Interests):', currentStepData);

      setIsSearching(true);
      // Wait 5 seconds before moving to next step
      setTimeout(() => {
        setIsSearching(false);
        setStep(7);
      }, 5000);
    }
  };

  const canProceed = () => {
    switch(step) {
      case 1: return formData.location.length > 0;
      case 2: return formData.relationship.length > 0;
      case 3: return formData.careType.length > 0;
      case 4: return formData.timeline.length > 0;
      case 5: return formData.ageBracket.length > 0;
      case 6: return formData.interests.length > 0;
      case 7: return (
        formData.contact.firstName.trim() && 
        formData.contact.lastName.trim() && 
        validateEmail(formData.contact.email) &&
        validatePhone(formData.contact.phone) &&
        formData.contact.consent
      );
      default: return false;
    }
  };

  const renderCurrentStep = () => {
    if (isSearching) {
      return <SearchingAnimation />;
    }

    switch(step) {
      case 1:
        return (
          <div className="space-y-6">
            <div className="text-center mb-6">
              <MapPin className="w-12 h-12 mx-auto text-aegis-blue mb-3" />
              <h2 className="text-2xl font-semibold">Where would you or your loved one like to live?</h2>
              <p className="text-gray-600 mt-1">We'll show you nearby Aegis Living communities</p>
            </div>
            <style jsx>{styles}</style>
            <div className="max-w-2xl mx-auto">
              <div className="max-w-md mx-auto mb-8">
                <div className="relative">
                  <div className="absolute -top-12 -left-20 font-handwriting text-lg text-aegis-blue font-medium z-20 start-here-container">
                    Start Here
                    <div className="hand-drawn-arrow"></div>
                  </div>
                  <div className="bg-white rounded-xl p-6 border-2 border-aegis-blue/30 shadow-sm">
                    <div className="mb-4">
                      <CityAutocomplete
                        value={formData.location}
                        onChange={(value) => handleInputChange("location", value)}
                      />
                    </div>
                    <div className="flex justify-center">
                      <button
                        className={`flex items-center justify-center gap-2 px-8 py-3 rounded-lg text-lg font-semibold transition-all
                          ${formData.location 
                            ? "bg-aegis-blue text-white hover:bg-aegis-blue/90" 
                            : "bg-gray-200 text-gray-400 cursor-not-allowed"}`}
                        onClick={async () => {
                          // Track step completion
                          const currentStepData = {
                            'event_category': 'Form',
                            'event_label': `Step ${step}`,
                            'step_number': step,
                            'step_name': getStepName(step),
                            'step_value': formData.location,
                            'form_type': 'Video LP',
                            'selected_location': formData.location
                          };
                          
                          window.gtag('event', 'form_step_complete', currentStepData);
                          console.log('GA Event - Step 1 Complete:', currentStepData);

                          // Track location selection
                          window.gtag('event', 'location_selected', {
                            'event_category': 'Form',
                            'event_label': formData.location,
                            'location_value': formData.location,
                            'form_type': 'Video LP'
                          });

                          // Move to next step
                          setStep(prev => prev + 1);
                        }}
                        disabled={!formData.location}
                      >
                        Continue
                        <ArrowRight size={20} />
                      </button>
                    </div>
                    <div className="flex items-center justify-center gap-2 mt-4 text-aegis-blue/80 text-sm">
                      <MapPin className="w-4 h-4" />
                      <span>30+ West Coast Communities</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="space-y-6 animate-fadeIn">
            <div className="text-center mb-8">
              <Heart className="w-12 h-12 mx-auto text-aegis-blue mb-4" />
              <h2 className="text-2xl font-semibold">Who are you looking for?</h2>
              <p className="text-gray-600 mt-2">Help us personalize your search</p>
            </div>
            <style jsx>{styles}</style>
            <div className="relative w-full max-w-2xl mx-auto">
              <div className="relative border-2 border-gray-100 rounded-2xl p-6 bg-white/50">
                <div data-question="relationship">
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                    {[
                      {
                        title: "For Myself",
                        description: "I'm exploring senior living options for me",
                        pulseClass: "pulse-1"
                      },
                      {
                        title: "For a Parent",
                        description: "I'm helping my mother or father find the right place",
                        pulseClass: "pulse-2"
                      },
                      {
                        title: "For my Spouse",
                        description: "I'm looking for options for my husband or wife",
                        pulseClass: "pulse-3"
                      },
                      {
                        title: "For Someone Else",
                        description: "I'm helping another loved one or friend",
                        pulseClass: "pulse-4"
                      }
                    ].map((option) => (
                      <button
                        key={option.title}
                        onClick={() => handleRelationshipSelect(option.title)}
                        className={`w-full p-4 rounded-lg border-2 transition-all duration-300 text-left bg-white h-[80px] sm:h-[80px] flex flex-col justify-center ${option.pulseClass} ${
                          formData.relationship === option.title
                            ? "border-[3px] border-aegis-blue bg-gradient-to-br from-aegis-blue/5 to-aegis-blue/10"
                            : "border-gray-200 hover:border-aegis-blue/50"
                        }`}
                      >
                        <div className="font-semibold">{option.title}</div>
                        <div className="text-sm text-gray-600">{option.description}</div>
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );

      case 3:
        return (
          <div className="space-y-6 animate-fadeIn">
            <div className="text-center mb-8">
              <Heart className="w-12 h-12 mx-auto text-aegis-blue mb-4" />
              <h2 className="text-2xl font-semibold">What type of care are you looking for?</h2>
              <p className="text-gray-600 mt-2">Select the option that best fits your needs</p>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
              {[
                {
                  title: "Light Assisted Living",
                  description: "Basic support for daily living",
                  tooltip: CARE_TYPE_DESCRIPTIONS["Light Assisted Living"]
                },
                {
                  title: "Assisted Living",
                  description: "Regular support with daily care",
                  tooltip: CARE_TYPE_DESCRIPTIONS["Assisted Living"]
                },
                {
                  title: "Transitional Care",
                  description: "Recovery after hospital stays",
                  tooltip: CARE_TYPE_DESCRIPTIONS["Transitional Care"]
                },
                {
                  title: "End Of Life Care",
                  description: "Comfort care with dignity",
                  tooltip: CARE_TYPE_DESCRIPTIONS["End Of Life Care"]
                },
                {
                  title: "Respite Care",
                  description: "Short-term relief for caregivers",
                  tooltip: CARE_TYPE_DESCRIPTIONS["Respite Care"]
                },
                {
                  title: "Memory Care",
                  description: "Specialized dementia and Alzheimer's care",
                  tooltip: CARE_TYPE_DESCRIPTIONS["Memory Care"]
                }
              ].map((option) => (
                <button
                  key={option.title}
                  onClick={() => handleCareTypeSelect(option.title)}
                  className={`relative w-full p-4 rounded-lg border-2 transition-all duration-300 text-left bg-white h-[80px] sm:h-[80px] flex flex-col justify-center group ${
                    formData.careType === option.title
                      ? "border-[3px] border-aegis-blue bg-gradient-to-br from-aegis-blue/5 to-aegis-blue/10"
                      : "border-gray-200 hover:border-aegis-blue/50"
                  }`}
                >
                  {/* Info Icon with Tooltip */}
                  <div className="absolute top-2 right-2 group/tooltip">
                    <div className="w-5 h-5 rounded-full border-2 border-aegis-blue text-aegis-blue flex items-center justify-center text-xs cursor-help">
                      i
                    </div>
                    {/* Tooltip */}
                    <div className="absolute z-10 bottom-full right-0 mb-2 w-64 bg-gray-900 text-white text-sm rounded-lg p-3 
                      opacity-0 invisible group-hover/tooltip:opacity-100 group-hover/tooltip:visible 
                      transition-all duration-200 pointer-events-none">
                      {option.tooltip}
                      {/* Arrow */}
                      <div className="absolute -bottom-1 right-2 w-2 h-2 bg-gray-900 rotate-45"></div>
                    </div>
                  </div>
                  <div className="font-semibold pr-8">{option.title}</div>
                  <div className="text-sm text-gray-600">{option.description}</div>
                </button>
              ))}
            </div>
          </div>
        );

      case 4:
        return (
          <div className="space-y-6 animate-fadeIn">
            <div className="text-center mb-8">
              <Calendar className="w-12 h-12 mx-auto text-aegis-blue mb-4" />
              <h2 className="text-2xl font-semibold">When are you planning to make the move?</h2>
              <p className="text-gray-600 mt-2">This helps us prepare for your arrival</p>
            </div>
            <div data-question="timeline">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                {[
                  "Within 1 month",
                  "1-3 months",
                  "3-6 months",
                  "6+ months"
                ].map((option) => (
                  <button
                    key={option}
                    onClick={() => handleTimelineSelect(option)}
                    className={`w-full p-4 rounded-lg border-2 transition-all duration-300 bg-white h-[50px] sm:h-[80px] flex items-center justify-center ${
                      formData.timeline === option
                        ? "border-[3px] border-aegis-blue bg-gradient-to-br from-aegis-blue/5 to-aegis-blue/10"
                        : "border-gray-200 hover:border-aegis-blue/50"
                    }`}
                  >
                    {option}
                  </button>
                ))}
              </div>
            </div>
          </div>
        );

      case 5:
        return (
          <div className="space-y-6 animate-fadeIn">
            <div className="text-center mb-8">
              <Calendar className="w-12 h-12 mx-auto text-aegis-blue mb-4" />
              <h2 className="text-2xl font-semibold">What is the age of the person needing care?</h2>
              <p className="text-gray-600 mt-2">Select the appropriate age range</p>
            </div>
            <div data-question="age-bracket">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                {[
                  "50-65 years",
                  "66-75 years",
                  "76-85 years",
                  "86+ years"
                ].map((option) => (
                  <button
                    key={option}
                    onClick={() => handleAgeBracketSelect(option)}
                    className={`w-full p-4 rounded-lg border-2 transition-all duration-300 bg-white h-[50px] sm:h-[80px] flex items-center justify-center ${
                      formData.ageBracket === option
                        ? "border-[3px] border-aegis-blue bg-gradient-to-br from-aegis-blue/5 to-aegis-blue/10"
                        : "border-gray-200 hover:border-aegis-blue/50"
                    }`}
                  >
                    {option}
                  </button>
                ))}
              </div>
            </div>
          </div>
        );

      case 6:
        if (isSearching) {
          return (
            <div className="space-y-6 animate-fadeIn">
              <div className="text-center mb-8">
                <span className="text-xl text-aegis-blue">Finding your perfect match...</span>
              </div>
              <div className="w-full bg-gray-100 h-2 rounded-full">
                <div className="bg-emerald-500 h-2 rounded-full animate-progressBar" />
              </div>
            </div>
          );
        }

        return (
          <div className="space-y-6 animate-fadeIn">
            <div className="text-center mb-8">
              <Heart className="w-12 h-12 mx-auto text-aegis-blue mb-4" />
              <h2 className="text-2xl font-semibold">What interests you most?</h2>
              <p className="text-gray-600 mt-2">Select all that interest you</p>
            </div>
            <div data-question="interests">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                {[
                  "24/7 Care",
                  "Memory Care",
                  "Respite Care",
                  "Location",
                  "Fine Dining",
                  "Social Activities",
                  "Fitness Center",
                  "Garden Access",
                  "Arts & Culture",
                  "Transportation",
                  "Pet Friendly",
                  "Security"
                ].map((option) => (
                  <button
                    key={option}
                    onClick={() => handleInterestToggle(option)}
                    className={`w-full p-4 rounded-lg border-2 transition-all duration-300 bg-white h-[50px] sm:h-[80px] flex items-center justify-center text-center ${
                      formData.interests.includes(option)
                        ? "border-[3px] border-aegis-blue bg-gradient-to-br from-aegis-blue/5 to-aegis-blue/10"
                        : "border-gray-200 hover:border-aegis-blue/50"
                    }`}
                  >
                    {option}
                  </button>
                ))}
              </div>
            </div>
          </div>
        );

      case 7:
        return (
          <div className="space-y-6 animate-fadeIn">
            <div className="text-center mb-8">
              <h2 className="text-2xl font-semibold text-aegis-blue">Great News!</h2>
              <p className="text-gray-600 mt-4">
                Based on the information you provided we have matched your needs with some wonderful care options for you to review!
              </p>
              <p className="text-gray-600 mt-4 font-medium">
                Fill in your current email and phone number below to see your results:
              </p>
            </div>
            <div className="max-w-md mx-auto space-y-4">
              {/* Name fields in 2 columns */}
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <input
                    type="text"
                    placeholder="First Name"
                    value={formData.contact.firstName}
                    onChange={(e) => handleContactChange('firstName', e.target.value)}
                    className={`w-full p-4 rounded-lg border ${
                      errors.firstName ? 'border-red-500' : 'border-gray-300'
                    }`}
                  />
                  {errors.firstName && (
                    <div className="text-red-500 text-sm mt-1">{errors.firstName}</div>
                  )}
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="Last Name"
                    value={formData.contact.lastName}
                    onChange={(e) => handleContactChange('lastName', e.target.value)}
                    className={`w-full p-4 rounded-lg border ${
                      errors.lastName ? 'border-red-500' : 'border-gray-300'
                    }`}
                  />
                  {errors.lastName && (
                    <div className="text-red-500 text-sm mt-1">{errors.lastName}</div>
                  )}
                </div>
              </div>

              {/* Rest of the form fields */}
              <div>
                <input
                  type="email"
                  placeholder="Email Address"
                  value={formData.contact.email}
                  onChange={(e) => handleContactChange('email', e.target.value)}
                  className={`w-full p-4 rounded-lg border ${
                    errors.email ? 'border-red-500' : 'border-gray-300'
                  }`}
                />
                {errors.email && (
                  <div className="text-red-500 text-sm mt-1">{errors.email}</div>
                )}
              </div>
              <div>
                <PhoneInput
                  value={formData.contact.phone}
                  onChange={(value) => handleContactChange('phone', value)}
                  error={errors.phone}
                />
              </div>
              <label className="flex items-start gap-2">
                <input
                  type="checkbox"
                  checked={formData.contact.consent}
                  onChange={(e) => handleContactChange('consent', e.target.checked)}
                  className="mt-1"
                  name="consent"
                />
                <span className="text-sm text-gray-600">
                  Yes, I would like to learn more about Aegis Living communities and authorize a care specialist to reach out to me via email and/or text message.
                </span>
              </label>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  // Add ref for form scrolling
  const formRef = useRef(null);

  const scrollToForm = () => {
    formRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const renderTestimonials = () => (
    <div className="max-w-6xl mx-auto">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {[
          {
            name: "Sarah Thompson",
            role: "Resident Family Member",
            image: "https://images.unsplash.com/photo-1581579438747-1dc8d17bbce4",
            quote: "They helped us find the perfect community close to family. It truly feels like home."
          },
          {
            name: "Robert Chen",
            role: "Current Resident",
            image: "https://images.unsplash.com/photo-1566616213894-2d4e1baee5d8",
            quote: "The staff and community here have become my second family. Best decision I've made."
          },
          {
            name: "Emily Martinez",
            role: "Resident Daughter",
            image: "https://images.unsplash.com/photo-1544005313-94ddf0286df2",
            quote: "The care and attention my mother receives is exceptional. We couldn't be happier."
          }
        ].map((testimonial, index) => (
          <div key={index} className="bg-white rounded-lg p-4 border border-aegis-blue/10 shadow-sm">
            <div className="flex items-center gap-3 mb-3">
              <img 
                src={testimonial.image}
                alt={testimonial.name}
                className="w-12 h-12 rounded-full object-cover border border-aegis-blue/20"
              />
              <div>
                <div className="font-semibold text-aegis-blue text-sm">{testimonial.name}</div>
                <div className="text-xs text-gray-500">{testimonial.role}</div>
              </div>
            </div>
            <div className="text-aegis-blue mb-2">★★★★★</div>
            <blockquote className="text-gray-600 text-sm">
              "{testimonial.quote}"
            </blockquote>
          </div>
        ))}
      </div>
    </div>
  );

  // Update progress bar calculation to account for one less step
  const renderProgressBar = () => (
    <div className="w-full bg-gray-100 h-2 rounded-full mb-6">
      <div 
        className="bg-aegis-blue h-2 rounded-full transition-all duration-500"
        style={{ width: `${((step - 1) / 7) * 100}%` }}
      />
    </div>
  );

  const handleFormStart = () => {
    nextStep();
    scrollToFormTop();
  };

  const handleCareTypeSelect = (careType) => {
    handleInputChange('careType', careType);
    // Track the event after updating form data
    const currentStepData = {
      'event_category': 'Form',
      'event_label': `Step ${step}`,
      'step_number': step,
      'step_name': getStepName(step),
      'step_value': careType, // Use the value directly
      'form_type': 'Video LP',
      'selected_location': formData.location
    };
    window.gtag('event', 'form_step_complete', currentStepData);
    console.log('GA Event - Current Step Complete:', currentStepData);
    
    setStep(prev => prev + 1);
    scrollToFormTop();
  };

  const SearchingAnimation = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [showCheck, setShowCheck] = useState(false);
    const messages = [
      "Analyzing your preferences...",
      "Matching care requirements...",
      "Customizing your experience...",
      "Preparing personalized information..."
    ];

    useEffect(() => {
      let currentIndex = 0;
      const interval = setInterval(() => {
        if (currentIndex < messages.length) {
          setActiveIndex(currentIndex);
          // Show check when we reach the last message (100% progress)
          if (currentIndex === messages.length - 1) {
            setShowCheck(true);
          }
          currentIndex++;
        } else {
          clearInterval(interval);
        }
      }, 1000);

      return () => clearInterval(interval);
    }, []);

    // Calculate progress - each message is 25%
    const progress = Math.min(((activeIndex + 1) * 25), 100);

    return (
      <div className="space-y-6 animate-fadeIn">
        <div className="text-center mb-8">
          <div className="relative w-12 h-12 mx-auto mb-4">
            <div className="absolute inset-0 bg-aegis-blue/20 rounded-full animate-ping"></div>
            <div className="relative bg-aegis-blue rounded-full w-12 h-12 flex items-center justify-center">
              <Loader2 className="w-8 h-8 text-white animate-spin" />
            </div>
          </div>
          <h2 className="text-2xl font-semibold">Finding Your Perfect Match</h2>
          <p className="text-gray-600 mt-2">We're personalizing your experience based on your preferences</p>
        </div>
        
        <div className="max-w-md mx-auto space-y-4">
          <div className="relative px-8 sm:px-0">
            {/* Progress bar */}
            <div className="h-2 bg-gray-100 rounded-full overflow-hidden">
              <div 
                className="h-full bg-green-500 transition-all duration-500"
                style={{ width: `${progress}%` }}
              />
            </div>
            
            {/* Check icon */}
            <div className={`
              absolute -right-2 sm:-right-10 top-1/2 -translate-y-1/2 
              w-8 h-8 rounded-full 
              ${showCheck
                ? 'bg-green-500 after:absolute after:inset-0 after:bg-green-500 after:rounded-full after:animate-ping' 
                : 'bg-gray-200'
              }
              flex items-center justify-center
              transition-colors duration-300
            `}>
              <Check className="w-5 h-5 text-white" strokeWidth={3} />
            </div>
          </div>

          {/* Animated messages */}
          <div className="space-y-3 mt-6">
            {messages.map((text, index) => (
              <div
                key={text}
                className={`text-sm text-gray-600 transition-all duration-300 ${
                  index === activeIndex ? 'opacity-100 font-medium text-aegis-blue' : 'opacity-40'
                }`}
              >
                {text}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  // Add this function to handle scrolling on mobile
  const scrollToFormTop = () => {
    if (window.innerWidth < 768) { // Mobile breakpoint
      formRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  // Add these handler functions
  const handleRelationshipSelect = (value) => {
    handleInputChange('relationship', value);
    // Track the event after updating form data
    const currentStepData = {
      'event_category': 'Form',
      'event_label': `Step ${step}`,
      'step_number': step,
      'step_name': getStepName(step),
      'step_value': value, // Use the value directly since formData hasn't updated yet
      'form_type': 'Video LP',
      'selected_location': formData.location
    };
    window.gtag('event', 'form_step_complete', currentStepData);
    console.log('GA Event - Current Step Complete:', currentStepData);
    
    setStep(prev => prev + 1);
    scrollToFormTop();
  };

  const handleTimelineSelect = (timeline) => {
    handleInputChange('timeline', timeline);
    // Track the event after updating form data
    const currentStepData = {
      'event_category': 'Form',
      'event_label': `Step ${step}`,
      'step_number': step,
      'step_name': getStepName(step),
      'step_value': timeline, // Use the value directly
      'form_type': 'Video LP',
      'selected_location': formData.location
    };
    window.gtag('event', 'form_step_complete', currentStepData);
    console.log('GA Event - Current Step Complete:', currentStepData);
    
    setStep(prev => prev + 1);
    scrollToFormTop();
  };

  const handleAgeBracketSelect = (ageBracket) => {
    handleInputChange('ageBracket', ageBracket);
    // Track the event after updating form data
    const currentStepData = {
      'event_category': 'Form',
      'event_label': `Step ${step}`,
      'step_number': step,
      'step_name': getStepName(step),
      'step_value': ageBracket, // Use the value directly
      'form_type': 'Video LP',
      'selected_location': formData.location
    };
    window.gtag('event', 'form_step_complete', currentStepData);
    console.log('GA Event - Current Step Complete:', currentStepData);
    
    setStep(prev => prev + 1);
    scrollToFormTop();
  };

  // Add embedded-specific logic
  const handleSubmit = async (data) => {
    try {
      // Send to your API
      const response = await fetch('https://your-domain.com/api/leads', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...data,
          source: isEmbedded ? 'embedded-form' : 'video-lp',
          referrer: document.referrer,
          embedUrl: isEmbedded ? window.location.href : null
        })
      });

      // Track in GA4
      window.gtag?.('event', 'form_submission', {
        'event_category': 'Form',
        'event_label': 'Embedded Form Submit',
        'embedding_domain': window.location.hostname
      });

      // ... rest of submission handling
    } catch (error) {
      console.error('Submission error:', error);
    }
  };

  // Add scroll to top function
  const scrollToTop = () => {
    if (window.innerWidth <= 640) { // Only scroll on mobile
      formRef.current?.scrollIntoView({ 
        behavior: 'smooth',
        block: 'start'
      });
    }
  };

  if (isSubmitted) {
    return (
      <SuccessPage 
        managerName={LOCATION_INFO.manager.name}
        managerEmail={LOCATION_INFO.manager.email}
        managerPhone={LOCATION_INFO.manager.phone}
        communityName={LOCATION_INFO.name}
        leadName={`${formData.contact.firstName} ${formData.contact.lastName}`}
      />
    );
  }

  if (isLoading) {
    return (
      <div className="min-h-screen bg-transparent flex items-center justify-center">
        <BackgroundDecoration />
        <div className="text-center transition-opacity duration-500 px-6 max-w-sm mx-auto">
          <h2 className="text-2xl font-semibold mb-6">
            Processing your information...
          </h2>
          <div className="flex items-center justify-center gap-3 text-aegis-blue">
            <Loader2 className="w-8 h-8 animate-spin" />
            <span className="text-xl min-w-[100px] text-left">Please wait...</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={`min-h-screen bg-transparent flex flex-col ${isEmbedded ? 'embedded' : ''}`}>
      <BackgroundDecoration />
      
      <div className="w-full space-y-12">
        {!isEmbedded && (
          <div className="max-w-7xl mx-auto px-4 py-6">
            <div className="flex justify-center">
              <img 
                src="https://www.aegisliving.com/wp-content/uploads/2023/06/aegis-living-logo-blue.svg"
                alt="Aegis Living" 
                className="h-16 w-auto"
              />
            </div>
          </div>
        )}

        {/* Only show video if showVideo is true and it's step 1 */}
        {config.showVideo && step === 1 && (
          <div className="max-w-3xl mx-auto mt-8 mb-12">
            <div className="relative pt-[56.25%] rounded-xl overflow-hidden shadow-xl">
              <video 
                className="absolute inset-0 w-full h-full object-cover cursor-pointer"
                autoPlay
                muted
                playsInline
                loop
                controls
                controlsList="nodownload nofullscreen noplaybackrate nopictureinpicture" 
                disablePictureInPicture
                src="https://aegis-living.s3.us-west-1.amazonaws.com/uploads/videos/When+Only+The+Best+Hands+Will+Do.mp4"
                onClick={(e) => {
                  const video = e.target;
                  if (video.muted) {
                    video.muted = false;
                    // Optional: Track unmute event
                    window.gtag?.('event', 'video_unmuted', {
                      'event_category': 'Video',
                      'event_label': 'Main Video Unmuted'
                    });
                  }
                }}
              >
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        )}

        {/* Form Section */}
        <div className="relative">
          <div 
            ref={formRef} 
            className={`relative py-8 ${
              step === 2 ? 'bg-gradient-to-br from-aegis-blue/5 via-aegis-blue/10 to-transparent' :
              step === 3 ? 'bg-gradient-to-br from-emerald-50 via-emerald-50/50 to-transparent' :
              step === 4 ? 'bg-gradient-to-br from-amber-50 via-amber-50/50 to-transparent' :
              step === 5 ? 'bg-gradient-to-br from-rose-50 via-rose-50/50 to-transparent' :
              'bg-gradient-to-br from-purple-50 via-purple-50/50 to-transparent'
            } transition-colors duration-500`}
          >
            <div className="absolute inset-0 bg-gradient-to-b from-white via-transparent to-gray-50" />
            <div className="relative max-w-xl mx-auto px-4">
              {!isSubmitted ? (
                <>
                  {renderProgressBar()}
                  {renderCurrentStep()}
                  {!isSearching && step !== 1 && (
                    <div className="flex justify-center gap-4 mt-8">
                      {step > 2 && (
                        <button
                          onClick={prevStep}
                          className="px-6 py-2 text-gray-600 hover:text-gray-800"
                        >
                          Back
                        </button>
                      )}
                      <button
                        onClick={nextStep}
                        disabled={!canProceed()}
                        className={`px-6 py-2 rounded-lg text-white ${
                          canProceed()
                            ? "bg-aegis-blue hover:bg-aegis-blue/90"
                            : "bg-gray-300 cursor-not-allowed"
                        }`}
                      >
                        {step === 7 ? "Submit" : step === 6 ? "Find The Perfect Community" : "Next"}
                      </button>
                    </div>
                  )}
                </>
              ) : (
                <SuccessPage successData={successData} />
              )}
            </div>
          </div>
        </div>

        {renderTestimonials()}

        {!isEmbedded && (
          <div className="py-4 text-center text-gray-600 text-sm border-t">
            © 2024 Aegis Living. All Rights Reserved.
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoLP; 