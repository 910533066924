import React, { useState, useEffect, useRef } from "react";
import * as LucideIcons from "lucide-react";
import { ArrowRight, Phone, Video, Loader2, Calendar, Heart, Home, MapPin, Layout, X, BedSingle, Bed, Users, ChevronLeft, ChevronRight, Clock, Utensils, Flower2, Languages, Waves, Sofa, Leaf, Dumbbell, Flower, Building2, Crown, Brain, Clapperboard, Trees, UtensilsCrossed, ShoppingBag, Building, Stethoscope, HeartPulse, Activity, PalmTree, Dog, Swim, Golf, Scissors, Trophy, Beds, HardHat, Footprints, Yoga, Menu, Mail } from "lucide-react";
import SuccessPage from './SuccessPage';
import BackgroundDecoration from './BackgroundDecoration';
import PhoneInput from './PhoneInput';
import ImageGallery from './ImageGallery';
import NearbyLocations from './NearbyLocations';
import { Link as ScrollLink } from 'react-scroll';
import LocationMap from './LocationMap';
import Accessibility from './Accessibility';
import Navigation from './Navigation';

const API_URL = process.env.REACT_APP_API_URL || '';

const FLOOR_PLAN_ICONS = {
  'Studio': BedSingle,
  'One Bedroom': Bed,
  'Two Bedroom': Home,
  'Companion Suite': Users
};

const AMENITY_ICONS = {
  '24/7 Care': 'Clock',
  'All-Day Dining': 'Utensils',
  'Asian Inspired': 'Flower2',
  'Bilingual': 'Languages',
  'Close to Ocean': 'Waves',
  'Companion Suite': 'Users',
  'Cottage Style': 'Home',
  'Cozy': 'Sofa',
  'Eco Friendly': 'Leaf',
  'Fitness Center': 'Dumbbell',
  'Gardening Space': 'Flower',
  'Intimate Setting': 'Heart',
  'Large Building': 'Building2',
  'Luxury': 'Crown',
  'Massage Room': 'Massage',
  'Memory Care Only': 'Brain',
  'Movie Theater': 'Clapperboard',
  'Nature Lovers': 'Trees',
  'Nearby Restaurants': 'UtensilsCrossed',
  'Nearby Shopping': 'ShoppingBag',
  'New Building': 'Building',
  'One Bedroom': 'Bed',
  'Onsite Nurses 24/7': 'Stethoscope',
  'Onsite Nurses Daily': 'HeartPulse',
  'Onsite PT Center': 'Activity',
  'Patio': 'PalmTree',
  'Pets': 'Dog',
  'Pool': 'Swim',
  'Putting Green': 'Golf',
  'Residential Area': 'Home',
  'Salon': 'Scissors',
  'Sports Den': 'Trophy',
  'Studio': 'BedSingle',
  'Two Bedrooms': 'Beds',
  'Under Construction': 'HardHat',
  'Urban': 'Building2',
  'Walking Paths': 'Footprints',
  'Yoga': 'Yoga'
};

const CARE_TYPE_DESCRIPTIONS = {
  'Assisted Living': 'Professional support with daily activities while maintaining independence in a comfortable residential setting.',
  'End Of Life Care': 'Compassionate, specialized care focused on comfort and dignity during life\'s final stages.',
  'Light Assisted Living': 'Minimal assistance with daily tasks while emphasizing independence and active lifestyle.',
  'Memory Care': 'Specialized care and secure environment for those living with Alzheimer\'s and other forms of dementia.',
  'Respite Care': 'Short-term care providing temporary relief for primary caregivers and families.',
  'Transitional Care': 'Temporary care designed to help seniors recover and transition back home after hospital stays.'
};

const CommunityTemplate = ({ communityData, currentImageIndex, allCommunities }) => {
  const [step, setStep] = useState(2);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [isVirtualTourModalOpen, setIsVirtualTourModalOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const formRef = useRef(null);
  const [successData, setSuccessData] = useState(null);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [galleryIndex, setGalleryIndex] = useState(0);
  const [isFloorPlanModalOpen, setIsFloorPlanModalOpen] = useState(false);
  const [selectedFloorPlan, setSelectedFloorPlan] = useState(null);
  const [navItems, setNavItems] = useState([]);
  const [selectedTestimonial, setSelectedTestimonial] = useState(null);
  const [testimonialIndex, setTestimonialIndex] = useState(0);
  const [showAllGalleryImages, setShowAllGalleryImages] = useState(false);
  const [isSliding, setIsSliding] = useState(false);
  const [slideDirection, setSlideDirection] = useState('left');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [hasStarted, setHasStarted] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  // Initialize form data
  const [formData, setFormData] = useState({
    location: communityData.name,
    relationship: "",
    careType: "",
    timeline: "",
    livingArrangement: "",
    interests: [],
    contact: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      consent: false
    }
  });

  // Add UTM parameter handling
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const utmParams = {
      source: urlParams.get('utm_source'),
      medium: urlParams.get('utm_medium'),
      campaign: urlParams.get('utm_campaign'),
      term: urlParams.get('utm_term'),
      content: urlParams.get('utm_content'),
      gclid: urlParams.get('gclid'),
      referrer: document.referrer,
      landing_page: window.location.href
    };
    
    setFormData(prev => ({
      ...prev,
      utm: utmParams,
      hubspot: {
        ...prev.hubspot,
        hs_analytics_source_data_1: utmParams.campaign || '',
        hs_analytics_source_data_2: utmParams.source && utmParams.medium ? 
          `${utmParams.source}/${utmParams.medium}` : ''
      }
    }));
  }, []);

  // Add form start tracking
  useEffect(() => {
    window.gtag('event', 'form_start', {
      'event_category': 'Form',
      'event_label': 'Form Started',
      'form_type': 'Community',
      'location': communityData.name
    });
  }, []);

  // Render functions that use communityData
  const renderHeader = () => (
    <div className="relative">
      {/* Header Image */}
      <div className="relative h-[300px] sm:h-[450px] w-full overflow-hidden">
        <div 
          style={{ 
            backgroundImage: `url(${headerImage})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            transition: 'background-image 0.5s ease-in-out'
          }}
          className="absolute inset-0"
        />
        
        {/* Gradient Overlay */}
        <div 
          className="absolute inset-0 bg-gradient-to-t from-black/60 via-black/30 to-transparent"
          aria-hidden="true"
        />

        {/* Title and Subtitle Overlay */}
        <div className="absolute bottom-0 left-0 right-0 p-6 sm:p-8">
          <div className="max-w-[1200px] mx-auto text-center">
            <h1 className="text-[2rem] sm:text-[2.8rem] font-bold text-white mb-2 sm:mb-3 leading-tight sm:leading-tight">
              {communityData.name}
            </h1>
            <p className="text-[1.2rem] sm:text-[1.6rem] text-white/90 max-w-3xl mx-auto leading-snug sm:leading-normal">
              {communityData.subtitle}
            </p>
          </div>
        </div>
      </div>
    </div>
  );

  // ... rest of the render functions using communityData

  const renderProgressBar = () => (
    <div className="w-full bg-gray-100 h-2 rounded-full mb-6">
      <div 
        className="bg-aegis-blue h-2 rounded-full transition-all duration-500"
        style={{ width: `${((step - 1) / 5) * 100}%` }}
      />
    </div>
  );

  const canProceed = () => {
    switch(step) {
      case 2: return formData.relationship.length > 0;
      case 3: return formData.careType.length > 0;
      case 4: return formData.timeline.length > 0;
      case 5: return formData.interests.length > 0;
      case 6: return (
        formData.contact.firstName.trim() && 
        formData.contact.lastName.trim() && 
        validateEmail(formData.contact.email) &&
        validatePhone(formData.contact.phone) &&
        formData.contact.consent
      );
      default: return false;
    }
  };

  const prevStep = () => {
    setStep(prev => prev - 1);
    // On mobile, scroll to top of form after step change
    if (window.innerWidth < 768) {
      formRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const nextStep = async () => {
    // Track step completion
    window.gtag('event', 'form_step_complete', {
      'event_category': 'Form',
      'event_label': `Step ${step}`,
      'step_number': step,
      'step_name': getStepName(step),
      'step_value': getStepValue(step, formData),
      'community': communityData.name,
      'page_url': window.location.href
    });

    if (step === 6) {
      try {
        setIsLoading(true);
        
        // Track form submission
        window.gtag('event', 'lead_submission', {
          'event_category': 'Form',
          'event_label': `Complete - ${communityData.name}`,
          'location': formData.location,
          'relationship': formData.relationship,
          'care_type': formData.careType,
          'timeline': formData.timeline,
          'interests': formData.interests.join(', '),
          'community': communityData.name,
          'source': formData.source,
          'page_url': window.location.href
        });

        // Add Facebook Lead event tracking
        if (window.fbq) {
          window.fbq('track', 'Lead');
        }

        console.log('Submitting to:', `${API_URL}/api/leads`);
        
        const response = await fetch(`${API_URL}/api/leads`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData)
        });

        if (!response.ok) {
          throw new Error('Failed to submit lead');
        }

        const data = await response.json();
        setIsLoading(false);
        setIsSubmitted(true);
        setSuccessData({
          managerName: communityData.manager.name,
          managerEmail: communityData.manager.email,
          managerPhone: communityData.manager.phone,
          communityName: communityData.name,
          leadName: `${formData.contact.firstName} ${formData.contact.lastName}`
        });
      } catch (error) {
        // Track submission error
        window.gtag('event', 'lead_submission_error', {
          'event_category': 'Form',
          'event_label': error.message,
          'community': communityData.name,
          'page_url': window.location.href
        });
        console.error('Error submitting form:', error);
        setIsLoading(false);
        alert('Error submitting form. Please try again.');
      }
    } else {
      setStep(prev => prev + 1);
      // On mobile, scroll to top of form after step change
      if (window.innerWidth < 768) {
        formRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  };

  const renderCurrentStep = () => {
    switch(step) {
      case 2:
        return (
          <div className="space-y-6 animate-fadeIn">
            <div className="text-center mb-8">
              <Heart className="w-12 h-12 mx-auto text-aegis-blue mb-4" />
              <h2 className="text-2xl font-semibold">Who are you looking for?</h2>
              <p className="text-gray-600 mt-2">Help us personalize your search</p>
            </div>
            <style jsx>{`
              @keyframes pulseShadow {
                0% {
                  box-shadow: 0 0 0 0 rgba(59, 130, 246, 0.2);
                }
                50% {
                  box-shadow: 0 0 20px 0 rgba(59, 130, 246, 0.3);
                }
                100% {
                  box-shadow: 0 0 0 0 rgba(59, 130, 246, 0.2);
                }
              }
              .pulse-1 {
                animation: pulseShadow 3s infinite;
              }
              .pulse-2 {
                animation: pulseShadow 3s infinite;
                animation-delay: 0.75s;
              }
              .pulse-3 {
                animation: pulseShadow 3s infinite;
                animation-delay: 1.5s;
              }
              .pulse-4 {
                animation: pulseShadow 3s infinite;
                animation-delay: 2.25s;
              }
              .hand-drawn-arrow {
                position: absolute;
                top: 3px;
                left: 110%;
                width: 80px;
                height: 60px;
                z-index: 10;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 60' fill='none' stroke='%233B82F6' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M0 10 Q40 10, 40 50 M25 35 L40 50 L55 35' style='stroke-dasharray: 4,4;'/%3E%3C/svg%3E");
                background-repeat: no-repeat;
              }
              @media (max-width: 768px) {
                .start-here-container {
                  display: none;
                }
              }
            `}</style>
            <div className="relative w-full max-w-2xl mx-auto">
              <div className="absolute -top-12 -left-16 font-handwriting text-lg text-aegis-blue font-medium z-20 start-here-container">
                Start Here
                <div className="hand-drawn-arrow"></div>
              </div>
              <div className="relative border-2 border-gray-100 rounded-2xl p-6 bg-white/50">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                  {[
                    {
                      title: "For Myself",
                      description: "I'm exploring senior living options for me",
                      pulseClass: "pulse-1"
                    },
                    {
                      title: "For a Parent",
                      description: "I'm helping my mother or father find the right place",
                      pulseClass: "pulse-2"
                    },
                    {
                      title: "For my Spouse",
                      description: "I'm looking for options for my husband or wife",
                      pulseClass: "pulse-3"
                    },
                    {
                      title: "For Someone Else",
                      description: "I'm helping another loved one or friend",
                      pulseClass: "pulse-4"
                    }
                  ].map((option) => (
                    <button
                      key={option.title}
                      onClick={() => {
                        handleFormStart();
                        handleInputChange('relationship', option.title);
                      }}
                      className={`w-full p-4 rounded-lg border-2 transition-all duration-300 text-left bg-white h-[80px] sm:h-[80px] flex flex-col justify-center ${option.pulseClass} ${
                        formData.relationship === option.title
                          ? "border-[3px] border-aegis-blue bg-gradient-to-br from-aegis-blue/5 to-aegis-blue/10"
                          : "border-gray-200 hover:border-aegis-blue/50"
                      }`}
                    >
                      <div className="font-semibold">{option.title}</div>
                      <div className="text-sm text-gray-600">{option.description}</div>
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        );

      case 3:
        return (
          <div className="space-y-6 animate-fadeIn">
            <div className="text-center mb-8">
              <Heart className="w-12 h-12 mx-auto text-aegis-blue mb-4" />
              <h2 className="text-2xl font-semibold">What type of care are you looking for?</h2>
              <p className="text-gray-600 mt-2">Select the option that best fits your needs</p>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
              {[
                {
                  title: "Light Assisted Living",
                  description: "Basic support for daily living"
                },
                {
                  title: "Assisted Living",
                  description: "Regular support with daily care"
                },
                {
                  title: "Transitional Care",
                  description: "Recovery after hospital stays"
                },
                {
                  title: "End Of Life Care",
                  description: "Comfort care with dignity"
                },
                {
                  title: "Respite Care",
                  description: "Short-term relief for caregivers"
                },
                {
                  title: "Memory Care",
                  description: "Specialized dementia and Alzheimer's care"
                }
              ].map((option) => (
                <button
                  key={option.title}
                  onClick={() => handleCareTypeSelect(option.title)}
                  className={`relative w-full p-4 rounded-lg border-2 transition-all duration-300 text-left bg-white h-[80px] sm:h-[80px] flex flex-col justify-center ${
                    formData.careType === option.title
                      ? "border-[3px] border-aegis-blue bg-gradient-to-br from-aegis-blue/5 to-aegis-blue/10"
                      : "border-gray-200 hover:border-aegis-blue/50"
                  }`}
                >
                  {/* Info Icon with Tooltip - Updated positioning */}
                  <div className="absolute top-2 right-2 group/tooltip">
                    <div className="w-5 h-5 rounded-full border-2 border-aegis-blue text-aegis-blue flex items-center justify-center text-xs cursor-help">
                      i
                    </div>
                    {/* Tooltip - Updated positioning */}
                    <div className="absolute z-10 bottom-full right-0 mb-2 w-64 bg-gray-900 text-white text-sm rounded-lg p-3 
                      opacity-0 invisible group-hover/tooltip:opacity-100 group-hover/tooltip:visible 
                      transition-all duration-200 pointer-events-none">
                      {CARE_TYPE_DESCRIPTIONS[option.title]}
                      {/* Arrow - Updated positioning */}
                      <div className="absolute -bottom-1 right-2 w-2 h-2 bg-gray-900 rotate-45"></div>
                    </div>
                  </div>
                  <div className="font-semibold pr-8">{option.title}</div>
                  <div className="text-sm text-gray-600">{option.description}</div>
                </button>
              ))}
            </div>
          </div>
        );

      case 4:
        return (
          <div className="space-y-6 animate-fadeIn">
            <div className="text-center mb-8">
              <Calendar className="w-12 h-12 mx-auto text-aegis-blue mb-4" />
              <h2 className="text-2xl font-semibold">When are you planning to make the move?</h2>
              <p className="text-gray-600 mt-2">This helps us prepare for your arrival</p>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
              {[
                "Within 1 month",
                "1-3 months",
                "3-6 months",
                "Just exploring"
              ].map((option) => (
                <button
                  key={option}
                  onClick={() => handleInputChange('timeline', option)}
                  className={`w-full p-4 rounded-lg border-2 transition-all duration-300 bg-white h-[50px] sm:h-[80px] flex items-center justify-center ${
                    formData.timeline === option
                      ? "border-[3px] border-aegis-blue bg-gradient-to-br from-aegis-blue/5 to-aegis-blue/10"
                      : "border-gray-200 hover:border-aegis-blue/50"
                  }`}
                >
                  {option}
                </button>
              ))}
            </div>
          </div>
        );

      case 5:
        return (
          <div className="space-y-6 animate-fadeIn">
            <div className="text-center mb-8">
              <Home className="w-12 h-12 mx-auto text-aegis-blue mb-4" />
              <h2 className="text-2xl font-semibold">What's most important to you?</h2>
              <p className="text-gray-600 mt-2">Select all that interest you</p>
            </div>
            <div className="grid grid-cols-2 sm:grid-cols-4 gap-2">
              {[
                "24/7 Care",
                "Memory Care",
                "Respite Care",
                "Location",
                "Fine Dining",
                "Social Activities",
                "Fitness Center",
                "Garden Access",
                "Arts & Culture",
                "Transportation",
                "Pet Friendly",
                "Security"
              ].map((option) => (
                <button
                  key={option}
                  onClick={() => handleInterestToggle(option)}
                  className={`w-full p-4 rounded-lg border-2 transition-all duration-300 bg-white h-[50px] sm:h-[80px] flex items-center justify-center text-center ${
                    formData.interests.includes(option)
                      ? "border-[3px] border-aegis-blue bg-gradient-to-br from-aegis-blue/5 to-aegis-blue/10"
                      : "border-gray-200 hover:border-aegis-blue/50"
                  }`}
                >
                  {option}
                </button>
              ))}
            </div>
          </div>
        );

      case 6:
        return (
          <div className="space-y-6 animate-fadeIn">
            <div className="text-center mb-8">
              <h2 className="text-2xl font-semibold">Learn More About Our Community</h2>
              <p className="text-gray-600 mt-2">
                Connect with us to explore your options and get answers to your questions
              </p>
            </div>
            <div className="max-w-md mx-auto space-y-4">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <input
                    type="text"
                    placeholder="First Name"
                    className={`w-full p-4 border rounded-lg ${errors.firstName ? 'border-red-500' : 'border-gray-300'}`}
                    value={formData.contact.firstName}
                    onChange={(e) => handleContactChange('firstName', e.target.value)}
                    autoComplete="given-name"
                  />
                  {errors.firstName && (
                    <p className="mt-1 text-sm text-red-500">{errors.firstName}</p>
                  )}
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="Last Name"
                    className={`w-full p-4 border rounded-lg ${errors.lastName ? 'border-red-500' : 'border-gray-300'}`}
                    value={formData.contact.lastName}
                    onChange={(e) => handleContactChange('lastName', e.target.value)}
                    autoComplete="family-name"
                  />
                  {errors.lastName && (
                    <p className="mt-1 text-sm text-red-500">{errors.lastName}</p>
                  )}
                </div>
              </div>
              <div>
                <input
                  type="email"
                  placeholder="Email Address"
                  className={`w-full p-4 border rounded-lg ${errors.email ? 'border-red-500' : 'border-gray-300'}`}
                  value={formData.contact.email}
                  onChange={(e) => handleContactChange('email', e.target.value)}
                  autoComplete="email"
                />
                {errors.email && (
                  <p className="mt-1 text-sm text-red-500">{errors.email}</p>
                )}
              </div>
              <div>
                <PhoneInput
                  value={formData.contact.phone}
                  onChange={(value) => handleContactChange('phone', value)}
                  className={`w-full p-4 border rounded-lg ${errors.phone ? 'border-red-500' : 'border-gray-300'}`}
                />
                {errors.phone && (
                  <p className="mt-1 text-sm text-red-500">{errors.phone}</p>
                )}
              </div>
              <label className="flex items-start gap-2">
                <input
                  type="checkbox"
                  checked={formData.contact.consent}
                  onChange={(e) => handleContactChange('consent', e.target.checked)}
                  className="mt-1"
                />
                <span className="text-sm text-gray-600">
                  I agree to be contacted about Aegis Living communities and understand that my consent isn't required to learn more.
                </span>
              </label>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  // Add validation functions
  const validateEmail = (email) => {
    return email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
  };

  const validatePhone = (phone) => {
    return phone.replace(/\D/g, '').length === 10;
  };

  // Update the getIconComponent function
  const renderIcon = (amenityName) => {
    const iconName = AMENITY_ICONS[amenityName];
    if (!iconName) return null;
    
    const IconComponent = LucideIcons[iconName];
    if (!IconComponent) return null;
    
    return (
      <IconComponent 
        className="w-8 h-8 text-aegis-blue mb-2" 
        aria-hidden="true"
      />
    );
  };

  // Add this function after the state declarations
  const handleInputChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));

    // On mobile, scroll to top of form after selection
    if (window.innerWidth < 768) {
      formRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const handleContactChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      contact: {
        ...prev.contact,
        [field]: value
      }
    }));
    
    // Clear error when user types
    if (errors[field]) {
      setErrors(prev => ({
        ...prev,
        [field]: ''
      }));
    }
  };

  const handleInterestToggle = (interest) => {
    setFormData(prev => ({
      ...prev,
      interests: prev.interests.includes(interest)
        ? prev.interests.filter(i => i !== interest)
        : [...prev.interests, interest]
    }));
  };

  // Add these helper functions at the top
  const getStepName = (stepNumber) => {
    switch(stepNumber) {
      case 2: return 'Relationship';
      case 3: return 'Care Type';
      case 4: return 'Timeline';
      case 5: return 'Interests';
      case 6: return 'Contact Info';
      default: return 'Unknown';
    }
  };

  const getStepValue = (stepNumber, formData) => {
    switch(stepNumber) {
      case 2: return formData.relationship;
      case 3: return formData.careType;
      case 4: return formData.timeline;
      case 5: return formData.interests.join(', ');
      case 6: return `${formData.contact.firstName} ${formData.contact.lastName}`;
      default: return '';
    }
  };

  // Update the GA event tracking functions with console logs

  const handleFormStart = () => {
    if (!hasStarted) {
      setHasStarted(true);
      // Track form start
      if (window.gtag) {
        window.gtag('event', 'form_start', {
          event_category: 'Forms',
          event_label: 'Community Form Start'
        });
      }
    }
  };

  const handleVideoClick = () => {
    window.gtag('event', 'video_play', {
      'event_category': 'Engagement',
      'event_label': 'Community Video',
      'video_title': `${communityData.name} Overview`,
      'location': communityData.name
    });
    setIsVideoModalOpen(true);
  };

  const handleCallClick = () => {
    console.log('GA Event - Click to Call:', {
      'event_category': 'Engagement',
      'event_label': communityData.name,
      'phone_number': communityData.manager.phone
    });
    window.gtag('event', 'click_to_call', {
      'event_category': 'Engagement',
      'event_label': communityData.name,
      'phone_number': communityData.manager.phone
    });
  };

  const handleGalleryOpen = (index) => {
    setGalleryIndex(index);
    setIsGalleryOpen(true);
  };

  const handleGalleryClose = () => {
    setIsGalleryOpen(false);
  };

  const handlePreviousImage = () => {
    setGalleryIndex(prev => (prev > 0 ? prev - 1 : prev));
  };

  const handleNextImage = () => {
    setGalleryIndex(prev => (prev < communityData.galleryImages.length - 1 ? prev + 1 : prev));
  };

  // Update the form abandonment tracking
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (!isSubmitted && step > 2) {
        const eventData = {
          'event_category': 'Form',
          'event_label': `Step ${step}`,
          'step_number': step,
          'step_name': getStepName(step),
          'community': communityData.name
        };
        console.log('GA Event - Form Abandon:', eventData);
        window.gtag('event', 'form_abandon', eventData);
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [step, isSubmitted, communityData.name]);

  // Get the current header image based on settings
  const headerImage = communityData.useGalleryForHeader && communityData.galleryImages?.length > 0
    ? communityData.galleryImages[currentImageIndex]
    : communityData.headerImage;

  // Add this useEffect near your other useEffects
  useEffect(() => {
    if (isSubmitted && successData) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [isSubmitted, successData]);

  // Generate navigation items based on available content
  useEffect(() => {
    const items = [];
    
    if (formRef.current) {
      items.push({ id: 'contact-form', label: 'Contact Us' });
    }
    
    if (communityData.amenities?.length > 0) {
      items.push({ id: 'amenities-section', label: 'Amenities' });
    }
    
    if (communityData.floorPlans?.length > 0) {
      items.push({ id: 'floor-plans-section', label: 'Floor Plans' });
    }
    
    if (communityData.features?.length > 0) {
      items.push({ id: 'features-section', label: 'Features' });
    }
    
    if (communityData.testimonials?.length > 0) {
      items.push({ id: 'testimonials-section', label: 'Testimonials' });
    }
    
    if (communityData.galleryImages?.length > 0) {
      items.push({ id: 'gallery-section', label: 'Gallery' });
    }
    
    if (allCommunities?.length > 1) {
      items.push({ id: 'nearby-locations-section', label: 'Nearby' });
    }
    
    if (communityData.location) {
      items.push({ id: 'map-section', label: 'Location' });
    }
    
    setNavItems(items);
  }, [communityData, allCommunities]);

  // Helper function to truncate text
  const truncateText = (text, limit = 160) => {
    if (text.length <= limit) return text;
    return text.slice(0, limit) + '...';
  };

  const nextTestimonial = () => {
    if (isSliding) return;
    setIsSliding(true);
    
    const increment = window.innerWidth >= 768 ? 3 : 1;
    const maxIndex = communityData.testimonials.length - increment;
    
    setTimeout(() => {
      setTestimonialIndex((prev) => 
        prev >= maxIndex ? 0 : prev + 1
      );
      setIsSliding(false);
    }, 500);
  };

  const prevTestimonial = () => {
    if (isSliding) return;
    setIsSliding(true);
    
    setTimeout(() => {
      setTestimonialIndex((prev) => 
        prev <= 0 ? 0 : prev - 1
      );
      setIsSliding(false);
    }, 500);
  };

  // Add this function near your other handlers
  const handleModalClick = (e, onClose) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  // Add this component for mobile navigation
  const MobileNavigation = () => (
    <div className="md:hidden">
      <button
        onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        className="p-2 text-gray-600 hover:text-gray-900"
      >
        <Menu size={24} />
      </button>
      
      {isMobileMenuOpen && (
        <div className="fixed inset-0 z-50 bg-white">
          <div className="p-4 flex justify-between items-center border-b">
            <h3 className="text-lg font-semibold">Menu</h3>
            <button
              onClick={() => setIsMobileMenuOpen(false)}
              className="p-2 text-gray-600"
            >
              <X size={24} />
            </button>
          </div>
          <div className="p-4 space-y-4">
            {navItems.map((item) => (
              <ScrollLink
                key={item.id}
                to={item.id}
                spy={true}
                smooth={true}
                offset={-64}
                duration={500}
                className="block px-4 py-2 text-gray-600 hover:text-aegis-blue"
                onClick={() => setIsMobileMenuOpen(false)}
              >
                {item.label}
              </ScrollLink>
            ))}
          </div>
        </div>
      )}
    </div>
  );

  // Add this with your other useEffects
  useEffect(() => {
    const handleResize = () => {
      // Reset testimonial index when switching between mobile and desktop
      setTestimonialIndex(0);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Add these handler functions before the renderCurrentStep function
  const handleRelationshipSelect = (value) => {
    handleFormStart();
    handleInputChange('relationship', value);
  };

  const handleCareTypeSelect = (value) => {
    handleInputChange('careType', value);
  };

  const handleTimelineSelect = (value) => {
    handleInputChange('timeline', value);
  };

  // Update the auto-advancement useEffect to remove the delay
  useEffect(() => {
    if (step < 5 && canProceed()) {
      nextStep();
    }
  }, [formData.relationship, formData.careType, formData.timeline]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Update virtual tour tracking
  const handleVirtualTourClick = () => {
    window.gtag('event', 'virtual_tour_start', {
      'event_category': 'Engagement',
      'event_label': 'Virtual Tour',
      'location': communityData.name
    });
    setIsVirtualTourModalOpen(true);
  };

  if (isSubmitted && successData) {
    return <SuccessPage {...successData} />;
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <Navigation />
      <BackgroundDecoration />
      
      {/* Hero Section with Overlay */}
      <div className="relative">
        {/* Header Image */}
        <div className="relative h-[300px] sm:h-[450px] w-full overflow-hidden">
          <div 
            style={{ 
              backgroundImage: `url(${headerImage})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              transition: 'background-image 0.5s ease-in-out'
            }}
            className="absolute inset-0"
          />
          
          {/* Gradient Overlay */}
          <div 
            className="absolute inset-0 bg-gradient-to-t from-black/60 via-black/30 to-transparent"
            aria-hidden="true"
          />

          {/* Title and Subtitle Overlay */}
          <div className="absolute bottom-0 left-0 right-0 p-6 sm:p-8">
            <div className="max-w-[1200px] mx-auto text-center">
              <h1 className="text-[2rem] sm:text-[2.8rem] font-bold text-white mb-2 sm:mb-3 leading-tight sm:leading-tight">
                {communityData.name}
              </h1>
              <p className="text-[1.2rem] sm:text-[1.6rem] text-white/90 max-w-3xl mx-auto leading-snug sm:leading-normal">
                {communityData.subtitle}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Sticky Navigation */}
      {navItems.length > 0 && (
        <nav 
          className={`sticky top-0 bg-white z-40 transition-all duration-300 ${
            isScrolled ? 'shadow-md' : ''
          }`}
        >
          <div className="max-w-7xl mx-auto px-4">
            {/* Desktop Navigation */}
            <div className="hidden md:flex items-center justify-center space-x-6 h-16">
              {navItems.map((item) => (
                <ScrollLink
                  key={item.id}
                  to={item.id}
                  spy={true}
                  smooth={true}
                  offset={-64}
                  duration={500}
                  className="px-4 py-2 rounded-md text-gray-600 hover:text-aegis-blue cursor-pointer transition-all"
                  activeClass="bg-aegis-blue/10 text-aegis-blue font-medium"
                >
                  {item.label}
                </ScrollLink>
              ))}
              {communityData.virtualTourId && (
                <button
                  onClick={handleVirtualTourClick}
                  className="px-4 py-2 rounded-md text-aegis-blue hover:bg-aegis-blue/10 font-medium transition-all"
                >
                  Virtual Tour
                </button>
              )}
            </div>

            {/* Mobile Navigation */}
            <div className="md:hidden flex items-center justify-between h-16">
              <button
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                className="p-2 text-gray-600 hover:text-gray-900 flex items-center gap-2"
              >
                <Menu size={24} />
                <span>Menu</span>
              </button>
              {communityData.virtualTourId && (
                <button
                  onClick={handleVirtualTourClick}
                  className="px-4 py-2 rounded-md text-aegis-blue hover:bg-aegis-blue/10 font-medium transition-all"
                >
                  Virtual Tour
                </button>
              )}
            </div>
          </div>
        </nav>
      )}

      {/* Form Section */}
      <div 
        id="contact-form" 
        ref={formRef} 
        className={`transition-all duration-700 ease-in-out ${
          step === 2 ? 'bg-gradient-to-b from-gray-50 via-aegis-blue/5 to-gray-50' :
          step === 3 ? 'bg-gradient-to-b from-gray-50 via-indigo-50 to-gray-50' :
          step === 4 ? 'bg-gradient-to-b from-gray-50 via-cyan-50 to-gray-50' :
          step === 5 ? 'bg-gradient-to-b from-gray-50 via-sky-50 to-gray-50' :
          step === 6 ? 'bg-gradient-to-b from-gray-50 via-blue-50 to-gray-50' :
          'bg-gray-50'
        }`}
      >
        <div className="max-w-3xl mx-auto px-4 py-12">
          <div className="text-center mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-2">
              Start Your Journey
            </h2>
            <p className="text-gray-600">
              Take the first step towards finding the perfect home for you or your loved one. 
              Fill out this short questionnaire and we'll help guide you through the process.
            </p>
          </div>
          {renderProgressBar()}
          {renderCurrentStep()}
          {step > 2 && (
            <div className="flex justify-between items-center mt-8">
              <button
                onClick={prevStep}
                className="text-gray-600 hover:text-gray-800"
              >
                Back
              </button>
              {step === 6 && (
                <button
                  onClick={nextStep}
                  disabled={!canProceed() || isLoading}
                  className={`px-6 py-2 rounded-lg text-white ${
                    !canProceed() || isLoading
                      ? 'bg-gray-400'
                      : 'bg-aegis-blue hover:bg-aegis-blue/90'
                  }`}
                >
                  {isLoading ? 'Submitting...' : 'Submit'}
                </button>
              )}
            </div>
          )}
        </div>
      </div>

      {/* Description and Action Buttons Section */}
      <div className="w-full bg-gray-50 py-8">
        <div className="max-w-4xl mx-auto px-4">
          <div className="bg-white rounded-lg shadow-sm p-8">
            {communityData.bodyText && (
              <p className="text-base sm:text-lg text-gray-600 leading-relaxed text-center mb-8">
                {communityData.bodyText}
              </p>
            )}
            
            {/* Action Buttons */}
            <div className="flex flex-col sm:flex-row justify-center gap-4">
              <button
                onClick={() => formRef.current?.scrollIntoView({ behavior: 'smooth' })}
                className="inline-flex items-center justify-center gap-2 px-6 py-3 bg-aegis-blue text-white rounded-lg hover:bg-aegis-blue/90 transition-colors"
              >
                <span>Get Started</span>
                <ArrowRight className="w-5 h-5" />
              </button>
              
              {communityData.virtualTourId && (
                <button
                  onClick={handleVirtualTourClick}
                  className="inline-flex items-center justify-center gap-2 px-6 py-3 border-2 border-aegis-blue text-aegis-blue rounded-lg hover:bg-aegis-blue/5 transition-colors"
                >
                  <Layout className="w-5 h-5" />
                  <span>Virtual Tour</span>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Amenities Section */}
      {communityData.amenities?.length > 0 && (
        <div id="amenities-section" className="bg-gray-50 py-16">
          <div className="max-w-7xl mx-auto px-4">
            <div className="text-center mb-12">
              <h2 className="text-3xl font-semibold text-gray-800 mb-4">Community Amenities</h2>
              <p className="text-xl text-gray-600 mb-8">
                Every detail has been thoughtfully designed to enhance your comfort and well-being
              </p>
            </div>
            
            <div className="flex flex-wrap justify-center items-start gap-4">
              {communityData.amenities.map((amenity, index) => (
                <div 
                  key={`${amenity.name}-${index}`} 
                  className="flex flex-col items-center p-4 text-center bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow"
                  style={{ width: '160px' }}
                >
                  {renderIcon(amenity.name)}
                  <span className="text-sm font-medium mt-2">{amenity.name}</span>
                </div>
              ))}
            </div>
          </div>
          {communityData.virtualTourId && (
            <div className="text-center mt-8">
              <button
                onClick={handleVirtualTourClick}
                className="inline-flex items-center gap-2 px-6 py-3 bg-aegis-blue text-white rounded-lg hover:bg-aegis-blue/90 transition-colors"
              >
                <span>Take a Virtual Tour</span>
                <Layout className="w-5 h-5" />
              </button>
            </div>
          )}
        </div>
      )}

      {/* Floor Plans Section */}
      {communityData.floorPlans?.length > 0 && (
        <div id="floor-plans-section" className="relative">
          {/* Background Image with Overlay */}
          <div 
            className="absolute inset-0 w-full h-full"
            style={{ 
              backgroundImage: `url(${communityData.headerImage})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover'
            }}
          >
            <div className="absolute inset-0 bg-aegis-blue/80" />
          </div>

          {/* Content */}
          <div className="relative max-w-5xl mx-auto px-4 pt-16 pb-40">
            <div className="text-center mb-8">
              <h2 className="text-3xl font-semibold text-white mb-2">
                Floor Plans
              </h2>
              <p className="text-xl text-white/90">
                Find the perfect space for your lifestyle
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 max-w-4xl mx-auto">
              {communityData.floorPlans.map((plan, index) => {
                const IconComponent = FLOOR_PLAN_ICONS[plan.type];
                return (
                  <div 
                    key={index}
                    className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-all duration-300 text-center border border-gray-100 group"
                  >
                    <div className="p-3">
                      {IconComponent && (
                        <div className="mb-2 p-2 bg-aegis-blue/5 rounded-full group-hover:bg-aegis-blue/10 transition-colors inline-block">
                          <IconComponent className="w-8 h-8 text-aegis-blue" />
                        </div>
                      )}
                      <h3 className="text-xl font-semibold text-gray-800 mb-1">{plan.type}</h3>
                      <p className="text-gray-600 mb-3 text-sm">{plan.name}</p>
                      <button
                        onClick={() => {
                          setSelectedFloorPlan(plan);
                          setIsFloorPlanModalOpen(true);
                        }}
                        className="inline-flex items-center justify-center gap-2 px-4 py-2 bg-aegis-blue text-white rounded-lg hover:bg-aegis-blue/90 transition-all duration-300 w-full max-w-[160px] text-sm group-hover:scale-105"
                      >
                        <Layout className="w-4 h-4" />
                        <span>View Floor Plan</span>
                      </button>
                    </div>
                    <div className="h-0.5 w-full bg-gradient-to-r from-aegis-blue/0 via-aegis-blue to-aegis-blue/0 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}

      {/* Features Section - conditional negative margin */}
      {communityData.features?.length > 0 && (
        <div 
          id="features-section" 
          className={`max-w-7xl mx-auto px-4 ${communityData.floorPlans?.length > 0 ? '-mt-24' : 'mt-8'} relative z-10`}
        >
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {communityData.features.map((feature, index) => (
              <div key={index} className="bg-white rounded-xl shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300">
                {feature.image && (
                  <div className="w-full h-48 overflow-hidden">
                    <img 
                      src={feature.image} 
                      alt={feature.title}
                      className="w-full h-full object-cover transform hover:scale-105 transition-transform duration-500"
                    />
                  </div>
                )}
                <div className="p-6">
                  <h3 className="text-xl font-semibold text-aegis-blue">{feature.title}</h3>
                  <p className="text-sm text-gray-500 mt-1">{feature.subtitle}</p>
                  <p className="text-gray-600 mt-4">{feature.text}</p>
                  <button
                    onClick={() => formRef.current?.scrollIntoView({ behavior: 'smooth' })}
                    className="mt-6 inline-flex items-center gap-2 text-aegis-blue hover:text-aegis-blue/80 font-medium group"
                  >
                    {feature.buttonText}
                    <ArrowRight className="w-5 h-5 transform group-hover:translate-x-1 transition-transform" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Testimonials Section */}
      {communityData.testimonials?.length > 0 && (
        <div id="testimonials-section" className="bg-gray-50 py-16">
          <div className="max-w-7xl mx-auto px-4">
            <div className="text-center mb-12">
              <h2 className="text-3xl font-semibold text-gray-800 mb-4">
                What Families Are Saying
              </h2>
              <p className="text-xl text-gray-600">
                Read what our community members have to share
              </p>
            </div>

            <div className="relative max-w-6xl mx-auto">
              <div className="overflow-hidden">
                <div 
                  className="flex transition-transform duration-500 ease-in-out"
                  style={{ 
                    transform: `translateX(-${testimonialIndex * (100 / (window.innerWidth >= 768 ? 3 : 1))}%)`,
                  }}
                >
                  {communityData.testimonials.map((testimonial, index) => (
                    <div 
                      key={index}
                      className="w-full md:w-1/3 flex-shrink-0 px-4"
                      style={{ minWidth: window.innerWidth >= 768 ? '33.333%' : '100%' }}
                    >
                      <div className="bg-white rounded-lg shadow-lg p-6 h-full">
                        <div className="mb-4">
                          <svg className="w-8 h-8 text-aegis-blue/20" fill="currentColor" viewBox="0 0 32 32">
                            <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                          </svg>
                        </div>
                        <p className="text-gray-600 mb-6 italic">
                          {truncateText(testimonial.text)}
                          {testimonial.text.length > 160 && (
                            <button
                              onClick={() => setSelectedTestimonial(testimonial)}
                              className="ml-2 text-aegis-blue hover:text-aegis-blue/80 font-medium"
                            >
                              Read More
                            </button>
                          )}
                        </p>
                        <div className="mt-4">
                          <p className="font-semibold text-gray-800">{testimonial.name}</p>
                          <p className="text-gray-500">{testimonial.location}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* Navigation Arrows */}
              <button
                onClick={prevTestimonial}
                disabled={testimonialIndex === 0}
                className="absolute -left-4 md:-left-12 top-1/2 -translate-y-1/2 p-2 bg-white rounded-full shadow-lg hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed z-10"
              >
                <ChevronLeft className="w-6 h-6 text-gray-600" />
              </button>
              <button
                onClick={nextTestimonial}
                disabled={testimonialIndex >= (communityData.testimonials.length - (window.innerWidth >= 768 ? 3 : 1))}
                className="absolute -right-4 md:-right-12 top-1/2 -translate-y-1/2 p-2 bg-white rounded-full shadow-lg hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed z-10"
              >
                <ChevronRight className="w-6 h-6 text-gray-600" />
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Testimonial Modal */}
      {selectedTestimonial && (
        <div 
          className="fixed inset-0 z-50 bg-black/90 flex items-center justify-center p-4"
          onClick={(e) => handleModalClick(e, () => setSelectedTestimonial(null))}
        >
          <div className="max-w-2xl w-full bg-white rounded-lg overflow-hidden">
            <div className="p-4 flex justify-between items-center border-b">
              <h3 className="text-xl font-semibold">Testimonial</h3>
              <button
                onClick={() => setSelectedTestimonial(null)}
                className="text-gray-500 hover:text-gray-700"
              >
                <X size={24} />
              </button>
            </div>
            <div className="p-6">
              <div className="mb-6">
                <svg className="w-12 h-12 text-aegis-blue/20 mb-4" fill="currentColor" viewBox="0 0 32 32">
                  <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                </svg>
                <p className="text-gray-600 text-lg leading-relaxed italic">
                  {selectedTestimonial.text}
                </p>
              </div>
              <div className="mt-6 border-t pt-4">
                <p className="font-semibold text-gray-800 text-lg">{selectedTestimonial.name}</p>
                <p className="text-gray-500">{selectedTestimonial.location}</p>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Gallery Section */}
      {communityData.galleryImages?.length > 0 && (
        <div id="gallery-section" className="max-w-7xl mx-auto px-4 py-12">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-semibold text-gray-800 mb-4">
              {communityData.name} Photo Gallery
            </h2>
            <p className="text-xl text-gray-600 mb-8">
              Take a virtual tour of our beautiful community
            </p>
          </div>
          
          <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
            {communityData.galleryImages
              .slice(0, showAllGalleryImages ? undefined : 6)
              .map((image, index) => (
                <div 
                  key={index}
                  className="aspect-[4/3] relative group cursor-pointer overflow-hidden rounded-lg"
                  onClick={() => handleGalleryOpen(index)}
                >
                  <img
                    src={image}
                    alt={`Gallery ${index + 1}`}
                    className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-110"
                  />
                  <div className="absolute inset-0 bg-black/50 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
                    <div className="text-white text-lg font-medium">View Image</div>
                  </div>
                </div>
            ))}
          </div>

          {communityData.galleryImages.length > 6 && !showAllGalleryImages && (
            <div className="text-center mt-8">
              <button
                onClick={() => setShowAllGalleryImages(true)}
                className="inline-flex items-center gap-2 px-6 py-3 bg-aegis-blue text-white rounded-lg hover:bg-aegis-blue/90 transition-colors"
              >
                <span>Load More Images</span>
                <ArrowRight className="w-5 h-5" />
              </button>
              <p className="text-sm text-gray-500 mt-2">
                Showing 6 of {communityData.galleryImages.length} images
              </p>
            </div>
          )}
        </div>
      )}

      {/* Nearby Locations */}
      {allCommunities?.length > 1 && (
        <div id="nearby-locations-section">
          <NearbyLocations 
            currentCommunity={communityData}
            allCommunities={allCommunities}
          />
        </div>
      )}

      {/* Map Section */}
      {communityData.location && (
        <div id="map-section" className="w-full relative mb-20">
          {/* Map Container with Overlay */}
          <div className="relative w-full h-[400px] md:h-[600px] mt-[calc(350px+1rem)] md:mt-0">
            {/* Info Card - Above on mobile, overlaid on desktop */}
            <div className="absolute -top-[350px] md:top-[80px] left-0 right-0 md:left-[40px] md:right-auto z-10 px-4 md:px-0">
              <div className="bg-white rounded-lg shadow-xl p-6 md:w-[350px]">
                <h3 className="text-2xl font-semibold text-aegis-blue mb-2">
                  {communityData.name}
                </h3>
                <p className="text-gray-600 mb-4">Visit our community in {communityData.location.city}</p>
                
                <div className="space-y-4">
                  {/* Address */}
                  <div className="flex items-start gap-3">
                    <MapPin className="w-5 h-5 text-aegis-blue flex-shrink-0 mt-1" />
                    <div>
                      <p className="font-medium">{communityData.location.address}</p>
                      <p>{communityData.location.city}, {communityData.location.state} {communityData.location.zip}</p>
                    </div>
                  </div>

                  {/* Contact Info */}
                  {communityData.manager && (
                    <>
                      <div className="flex items-center gap-3">
                        <Phone className="w-5 h-5 text-aegis-blue flex-shrink-0" />
                        <a href={`tel:${communityData.manager.phone}`} className="hover:text-aegis-blue">
                          {communityData.manager.phone}
                        </a>
                      </div>
                      <div className="flex items-center gap-3">
                        <Mail className="w-5 h-5 text-aegis-blue flex-shrink-0" />
                        <a href={`mailto:${communityData.manager.email}`} className="hover:text-aegis-blue">
                          {communityData.manager.email}
                        </a>
                      </div>
                    </>
                  )}

                  {/* Get Directions Button */}
                  <a
                    href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
                      `${communityData.location.address}, ${communityData.location.city}, ${communityData.location.state} ${communityData.location.zip}`
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center justify-center gap-2 bg-aegis-blue text-white px-6 py-3 rounded-lg hover:bg-aegis-blue/90 transition-colors w-full"
                  >
                    Get Directions
                    <ArrowRight size={18} />
                  </a>
                </div>
              </div>
            </div>

            {/* Map */}
            <LocationMap 
              location={communityData.location} 
              manager={communityData.manager}
              communityName={communityData.name}
              headerImage={communityData.headerImage}
              currentCommunitySlug={communityData.slug}
              allCommunities={allCommunities}
            />
          </div>
        </div>
      )}

      {/* Virtual Tour Modal */}
      {isVirtualTourModalOpen && (
        <div 
          className="fixed inset-0 z-50 bg-black/90 flex items-center justify-center p-4"
          onClick={(e) => handleModalClick(e, () => setIsVirtualTourModalOpen(false))}
        >
          <div className="w-[80%] bg-white rounded-lg overflow-hidden">
            <div className="p-4 flex justify-between items-center border-b">
              <h3 className="text-xl font-semibold">Virtual Tour</h3>
              <button
                onClick={() => setIsVirtualTourModalOpen(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <X size={24} />
              </button>
            </div>
            <div className="aspect-[16/9] w-full">
              <iframe
                src={`//viewer.panoskin.com/?id=virtual-tour&tour=${communityData.virtualTourId}`}
                frameBorder="none"
                allowTransparency="true"
                allowFullScreen="true"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                scrolling="no"
                allow="vr,gyroscope,accelerometer"
                title="3D Virtual Tour"
                className="ps_panoskinTour w-full h-full"
                style={{ border: 'none' }}
              />
            </div>
          </div>
        </div>
      )}

      {/* Floor Plan Modal */}
      {isFloorPlanModalOpen && selectedFloorPlan && (
        <div 
          className="fixed inset-0 z-50 bg-black/90 flex items-center justify-center p-4"
          onClick={(e) => handleModalClick(e, () => setIsFloorPlanModalOpen(false))}
        >
          <div className="max-w-4xl w-full bg-white rounded-lg overflow-hidden">
            <div className="p-4 flex justify-between items-center border-b">
              <div>
                <h3 className="text-xl font-semibold">{selectedFloorPlan.type}</h3>
                <p className="text-gray-600">{selectedFloorPlan.name}</p>
              </div>
              <button
                onClick={() => setIsFloorPlanModalOpen(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <X size={24} />
              </button>
            </div>
            <div className="p-4">
              <img
                src={selectedFloorPlan.image}
                alt={`${selectedFloorPlan.type} - ${selectedFloorPlan.name}`}
                className="w-full h-auto"
              />
            </div>
          </div>
        </div>
      )}

      {/* Image Gallery Modal */}
      <ImageGallery
        isOpen={isGalleryOpen}
        onClose={handleGalleryClose}
        images={communityData.galleryImages}
        currentIndex={galleryIndex}
        onPrevious={handlePreviousImage}
        onNext={handleNextImage}
      />
    </div>
  );
};

export default CommunityTemplate; 