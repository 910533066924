import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Plus, Search, MapPin, Pencil, Trash2, Building } from 'lucide-react';

const API_URL = process.env.REACT_APP_API_URL || '';

const RegionsList = () => {
  const [regions, setRegions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    regionName: '',
    regionId: ''
  });

  useEffect(() => {
    fetchRegions();
  }, []);

  const fetchRegions = async () => {
    try {
      const response = await fetch(`${API_URL}/api/admin/regions`, {
        headers: {
          'Authorization': 'Basic ' + btoa('admin:123456')
        }
      });

      if (!response.ok) throw new Error('Failed to fetch regions');
      const data = await response.json();
      setRegions(data);
    } catch (error) {
      console.error('Error fetching regions:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      const response = await fetch(`${API_URL}/api/admin/regions/${deleteModal.regionId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': 'Basic ' + btoa('admin:123456')
        }
      });

      if (response.ok) {
        await fetchRegions();
        setDeleteModal({ isOpen: false, regionName: '', regionId: '' });
      } else {
        throw new Error('Failed to delete region');
      }
    } catch (error) {
      console.error('Error deleting region:', error);
      alert('Error deleting region. Please try again.');
    }
  };

  const filteredRegions = regions.filter(region =>
    region.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return (
      <div className="p-8">
        <div className="text-center">Loading regions...</div>
      </div>
    );
  }

  return (
    <div className="p-8">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-2xl font-semibold">Regions</h1>
        <Link
          to="/admin/regions/add"
          className="bg-aegis-blue text-white px-4 py-2 rounded-lg hover:bg-aegis-blue/90 transition-colors flex items-center gap-2"
        >
          <Plus size={20} />
          Add Region
        </Link>
      </div>

      {/* Search */}
      <div className="mb-6">
        <div className="relative">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
          <input
            type="text"
            placeholder="Search regions..."
            className="w-full pl-10 pr-4 py-2 border rounded-lg"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      {/* Regions Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredRegions.map(region => (
          <div key={region._id} className="bg-white rounded-lg shadow-lg overflow-hidden">
            <div className="p-6">
              <div className="flex justify-between items-start mb-4">
                <h2 className="text-xl font-semibold">{region.name}</h2>
                <button
                  onClick={() => setDeleteModal({
                    isOpen: true,
                    regionName: region.name,
                    regionId: region._id
                  })}
                  className="p-2 text-gray-400 hover:text-red-500 rounded-full hover:bg-red-50 transition-colors"
                >
                  <Trash2 size={18} />
                </button>
              </div>
              
              <div className="space-y-4">
                {/* Communities Count */}
                <div className="flex items-center gap-2 text-gray-600">
                  <Building size={18} />
                  <span>{region.communities?.length || 0} Communities</span>
                </div>

                {/* Manager Info */}
                {region.manager && (
                  <div className="text-sm text-gray-600">
                    <div className="font-medium">Regional Manager</div>
                    <div>{region.manager.name}</div>
                  </div>
                )}

                {/* Description Preview */}
                {region.description && (
                  <p className="text-gray-600 text-sm line-clamp-2">
                    {region.description}
                  </p>
                )}

                {/* Actions */}
                <div className="flex justify-between items-center pt-4">
                  <Link
                    to={`/community/region/${region.slug}`}
                    className="text-aegis-blue hover:text-aegis-blue/80 flex items-center gap-2"
                  >
                    <MapPin size={18} />
                    View Region
                  </Link>
                  <Link
                    to={`/admin/regions/edit/${region._id}`}
                    className="p-2 text-gray-600 hover:text-aegis-blue hover:bg-gray-50 rounded-full transition-colors"
                  >
                    <Pencil size={18} />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Delete Confirmation Modal */}
      {deleteModal.isOpen && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Delete Region
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to delete {deleteModal.regionName}? This action cannot be undone.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  onClick={handleDelete}
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Delete
                </button>
                <button
                  type="button"
                  onClick={() => setDeleteModal({ isOpen: false, regionName: '', regionId: '' })}
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RegionsList; 