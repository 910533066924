import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { X, CheckCircle2, XCircle, Search, ArrowUpDown, Filter } from 'lucide-react';

const API_URL = process.env.REACT_APP_API_URL || '';

const LeadsPage = () => {
  const [leads, setLeads] = useState([]);
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [globalSearch, setGlobalSearch] = useState('');
  const [columnFilters, setColumnFilters] = useState({});
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'asc'
  });
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    leadId: null,
    leadName: ''
  });

  useEffect(() => {
    fetchLeads();
  }, []);

  // Apply filters and sorting whenever leads or filter criteria change
  useEffect(() => {
    let result = [...leads];

    // Apply global search
    if (globalSearch) {
      const searchTerm = globalSearch.toLowerCase();
      result = result.filter(lead => {
        return (
          lead.contact.firstName.toLowerCase().includes(searchTerm) ||
          lead.contact.lastName.toLowerCase().includes(searchTerm) ||
          lead.contact.email.toLowerCase().includes(searchTerm) ||
          lead.contact.phone.toLowerCase().includes(searchTerm) ||
          lead.relationship?.toLowerCase().includes(searchTerm) ||
          lead.careType?.toLowerCase().includes(searchTerm) ||
          lead.timeline?.toLowerCase().includes(searchTerm) ||
          lead.interests?.join(' ').toLowerCase().includes(searchTerm) ||
          lead.source?.toLowerCase().includes(searchTerm) ||
          lead.location?.toLowerCase().includes(searchTerm) ||
          lead.hubspot?.manager?.toLowerCase().includes(searchTerm)
        );
      });
    }

    // Apply column filters
    Object.entries(columnFilters).forEach(([column, filterValue]) => {
      if (filterValue) {
        const filter = filterValue.toLowerCase();
        result = result.filter(lead => {
          switch(column) {
            case 'name':
              return `${lead.contact.firstName} ${lead.contact.lastName}`.toLowerCase().includes(filter);
            case 'contact':
              return lead.contact.email.toLowerCase().includes(filter) || 
                     lead.contact.phone.toLowerCase().includes(filter);
            case 'relationship':
              return lead.relationship?.toLowerCase().includes(filter);
            case 'careType':
              return lead.careType?.toLowerCase().includes(filter);
            case 'timeline':
              return lead.timeline?.toLowerCase().includes(filter);
            case 'interests':
              return lead.interests?.join(' ').toLowerCase().includes(filter);
            case 'source':
              return lead.source?.toLowerCase().includes(filter);
            case 'community':
              return lead.location?.toLowerCase().includes(filter);
            case 'manager':
              return lead.hubspot?.manager?.toLowerCase().includes(filter);
            default:
              return true;
          }
        });
      }
    });

    // Apply sorting
    if (sortConfig.key) {
      result.sort((a, b) => {
        let aValue, bValue;
        
        switch(sortConfig.key) {
          case 'name':
            aValue = `${a.contact.firstName} ${a.contact.lastName}`;
            bValue = `${b.contact.firstName} ${b.contact.lastName}`;
            break;
          case 'contact':
            aValue = a.contact.email;
            bValue = b.contact.email;
            break;
          case 'date':
            aValue = new Date(a.createdAt);
            bValue = new Date(b.createdAt);
            break;
          default:
            aValue = a[sortConfig.key];
            bValue = b[sortConfig.key];
        }

        if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
        if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
        return 0;
      });
    }

    setFilteredLeads(result);
  }, [leads, globalSearch, columnFilters, sortConfig]);

  const fetchLeads = async () => {
    try {
      const response = await fetch(`${API_URL}/api/leads/list`, {
        headers: {
          'Authorization': 'Basic ' + btoa('admin:123456')
        }
      });
      if (response.ok) {
        const data = await response.json();
        setLeads(data);
        setFilteredLeads(data);
      }
    } catch (error) {
      console.error('Error fetching leads:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSort = (key) => {
    setSortConfig(current => ({
      key,
      direction: current.key === key && current.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  const handleColumnFilter = (column, value) => {
    setColumnFilters(prev => ({
      ...prev,
      [column]: value
    }));
  };

  const renderColumnHeader = (title, key) => (
    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      <div className="space-y-2">
        <div className="flex items-center gap-2">
          <span>{title}</span>
          <button 
            onClick={() => handleSort(key)}
            className={`p-1 rounded hover:bg-gray-200 ${
              sortConfig.key === key ? 'text-aegis-blue' : ''
            }`}
          >
            <ArrowUpDown size={14} />
          </button>
        </div>
        <div className="relative">
          <Search size={14} className="absolute left-2 top-1/2 -translate-y-1/2 text-gray-400" />
          <input
            type="text"
            placeholder={`Filter ${title}`}
            className="w-full pl-8 pr-2 py-1 text-xs border rounded"
            onChange={(e) => handleColumnFilter(key, e.target.value)}
            value={columnFilters[key] || ''}
          />
        </div>
      </div>
    </th>
  );

  const handleDelete = async () => {
    try {
      const response = await fetch(`${API_URL}/api/leads/${deleteModal.leadId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': 'Basic ' + btoa('admin:123456')
        }
      });
      
      if (!response.ok) throw new Error('Failed to delete lead');
      
      await fetchLeads();
      setDeleteModal({ isOpen: false, leadId: null, leadName: '' });
    } catch (err) {
      console.error('Delete error:', err);
    }
  };

  return (
    <>
      <div className="p-8">
        <div className="max-w-[1400px] mx-auto">
          <div className="bg-white shadow-sm rounded-lg">
            <div className="px-4 py-5 sm:px-6 border-b flex justify-between items-center">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Leads</h3>
              <div className="mb-6 flex flex-col sm:flex-row gap-4">
                <div className="flex-1 relative">
                  <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
                  <input
                    type="text"
                    placeholder="Search leads..."
                    className="w-full pl-10 pr-4 py-2 border rounded-lg"
                    value={globalSearch}
                    onChange={(e) => setGlobalSearch(e.target.value)}
                  />
                </div>
                <div className="text-sm text-gray-500 text-center sm:text-right">
                  Showing <span className="font-medium">{filteredLeads.length}</span> of{' '}
                  <span className="font-medium">{leads.length}</span> leads
                </div>
              </div>
            </div>
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    {renderColumnHeader('Name', 'name')}
                    {renderColumnHeader('Contact', 'contact')}
                    {renderColumnHeader('Relationship', 'relationship')}
                    {renderColumnHeader('Care Type', 'careType')}
                    {renderColumnHeader('Timeline', 'timeline')}
                    {renderColumnHeader('Interests', 'interests')}
                    {renderColumnHeader('Source', 'source')}
                    {renderColumnHeader('Community', 'community')}
                    {renderColumnHeader('Manager', 'manager')}
                    {renderColumnHeader('Date', 'date')}
                    {renderColumnHeader('HubSpot', 'hubspot')}
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {filteredLeads.map((lead) => (
                    <tr key={lead._id} className="hover:bg-gray-50">
                      <td className="px-4 py-2 sm:px-6 sm:py-3 whitespace-nowrap">
                        <div className="text-sm font-medium text-gray-900">
                          {lead.contact.firstName} {lead.contact.lastName}
                        </div>
                      </td>
                      <td className="px-4 py-2 sm:px-6 sm:py-3 whitespace-nowrap">
                        <div className="text-sm text-gray-900">{lead.contact.email}</div>
                        <div className="text-sm text-gray-500">{lead.contact.phone}</div>
                      </td>
                      <td className="px-4 py-2 sm:px-6 sm:py-3 whitespace-nowrap text-sm text-gray-500">
                        {lead.relationship}
                      </td>
                      <td className="px-4 py-2 sm:px-6 sm:py-3 whitespace-nowrap text-sm text-gray-500">
                        {lead.careType}
                      </td>
                      <td className="px-4 py-2 sm:px-6 sm:py-3 whitespace-nowrap text-sm text-gray-500">
                        {lead.timeline}
                      </td>
                      <td className="px-4 py-2 sm:px-6 sm:py-3 text-sm text-gray-500">
                        {lead.interests?.join(', ')}
                      </td>
                      <td className="px-4 py-2 sm:px-6 sm:py-3 whitespace-nowrap">
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                          {lead.source}
                        </span>
                      </td>
                      <td className="px-4 py-2 sm:px-6 sm:py-3 whitespace-nowrap text-sm text-gray-500">
                        {lead.location}
                      </td>
                      <td className="px-4 py-2 sm:px-6 sm:py-3">
                        <div className="text-sm text-gray-900">{lead.hubspot?.manager}</div>
                        <div className="text-sm text-gray-500">{lead.hubspot?.manager_phone}</div>
                      </td>
                      <td className="px-4 py-2 sm:px-6 sm:py-3 whitespace-nowrap text-sm text-gray-500">
                        {new Date(lead.createdAt).toLocaleDateString()}
                      </td>
                      <td className="px-4 py-2 sm:px-6 sm:py-3 whitespace-nowrap">
                        {lead.sync?.hubspot?.synced ? (
                          <div className="flex items-center text-green-600" title="Synced to HubSpot">
                            <CheckCircle2 size={20} />
                          </div>
                        ) : (
                          <div className="flex items-center text-red-600" title={lead.sync?.hubspot?.error || "Not synced to HubSpot"}>
                            <XCircle size={20} />
                          </div>
                        )}
                      </td>
                      <td className="px-4 py-2 sm:px-6 sm:py-3 whitespace-nowrap text-sm font-medium">
                        <button
                          onClick={() => setDeleteModal({
                            isOpen: true,
                            leadId: lead._id,
                            leadName: `${lead.contact.firstName} ${lead.contact.lastName}`
                          })}
                          className="text-red-600 hover:text-red-900"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* Delete Confirmation Modal */}
      {deleteModal.isOpen && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Delete Lead
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to delete the lead for {deleteModal.leadName}? This action cannot be undone.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  onClick={handleDelete}
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Delete
                </button>
                <button
                  type="button"
                  onClick={() => setDeleteModal({ isOpen: false, leadId: null, leadName: '' })}
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LeadsPage; 