import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft, Save } from 'lucide-react';

const API_URL = process.env.REACT_APP_API_URL || '';

const Settings = () => {
  const [settings, setSettings] = useState({
    toEmails: '',
    ccEmails: '',
    bccEmails: '',
    fromName: '',
    fromEmail: '',
  });
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    try {
      const response = await fetch(`${API_URL}/api/admin/settings`, {
        headers: {
          'Authorization': 'Basic ' + btoa('admin:123456')
        }
      });
      if (response.ok) {
        const data = await response.json();
        console.log('Fetched settings:', data);
        setSettings(data);
      }
    } catch (error) {
      console.error('Error fetching settings:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSaving(true);

    try {
      const response = await fetch(`${API_URL}/api/admin/settings`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + btoa('admin:123456')
        },
        body: JSON.stringify(settings)
      });

      if (response.ok) {
        alert('Settings saved successfully');
      } else {
        throw new Error('Failed to save settings');
      }
    } catch (error) {
      console.error('Error saving settings:', error);
      alert('Error saving settings. Please try again.');
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-xl text-gray-600">Loading...</div>
      </div>
    );
  }

  return (
    <>
      <div className="p-8">
        <div className="max-w-4xl mx-auto">
          <div className="flex items-center gap-4 mb-8">
            <Link
              to="/admin"
              className="text-gray-600 hover:text-gray-800"
            >
              <ArrowLeft size={24} />
            </Link>
            <h1 className="text-2xl font-semibold">Email Settings</h1>
          </div>

          <form onSubmit={handleSubmit} className="space-y-8">
            <div className="bg-white rounded-lg shadow p-6">
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    To Emails
                  </label>
                  <input
                    type="text"
                    value={settings.toEmails}
                    onChange={(e) => setSettings(prev => ({
                      ...prev,
                      toEmails: e.target.value
                    }))}
                    placeholder="Primary recipient email addresses"
                    className="w-full px-4 py-2 border rounded-lg"
                  />
                  <p className="mt-1 text-sm text-gray-500">
                    Separate multiple email addresses with commas
                  </p>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    CC Emails
                  </label>
                  <input
                    type="text"
                    value={settings.ccEmails}
                    onChange={(e) => setSettings(prev => ({
                      ...prev,
                      ccEmails: e.target.value
                    }))}
                    placeholder="Carbon copy email addresses"
                    className="w-full px-4 py-2 border rounded-lg"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    BCC Emails
                  </label>
                  <input
                    type="text"
                    value={settings.bccEmails}
                    onChange={(e) => setSettings(prev => ({
                      ...prev,
                      bccEmails: e.target.value
                    }))}
                    placeholder="Blind carbon copy email addresses"
                    className="w-full px-4 py-2 border rounded-lg"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    From Name
                  </label>
                  <input
                    type="text"
                    value={settings.fromName}
                    onChange={(e) => setSettings(prev => ({
                      ...prev,
                      fromName: e.target.value
                    }))}
                    placeholder="Sender name"
                    className="w-full px-4 py-2 border rounded-lg"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    From Email
                  </label>
                  <input
                    type="email"
                    value={settings.fromEmail}
                    onChange={(e) => setSettings(prev => ({
                      ...prev,
                      fromEmail: e.target.value
                    }))}
                    placeholder="Sender email address"
                    className="w-full px-4 py-2 border rounded-lg"
                  />
                </div>
              </div>
            </div>

            <div className="flex justify-end">
              <button
                type="submit"
                disabled={saving}
                className={`inline-flex items-center gap-2 px-6 py-2 rounded-lg text-white ${
                  saving ? 'bg-gray-400' : 'bg-aegis-blue hover:bg-aegis-blue/90'
                }`}
              >
                <Save size={20} />
                {saving ? 'Saving...' : 'Save Settings'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Settings; 