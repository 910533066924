import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Loader2 } from 'lucide-react';
import RegionalTemplate from './RegionalTemplate';

const API_URL = process.env.REACT_APP_API_URL || '';

const RegionPage = () => {
  const { slug } = useParams();
  const [region, setRegion] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchRegion();
  }, [slug]);

  const fetchRegion = async () => {
    try {
      console.log('Fetching region:', slug);
      const response = await fetch(`${API_URL}/api/regions/${slug}`);
      
      if (!response.ok) {
        console.error('Region fetch error:', response.status);
        throw new Error('Region not found');
      }
      
      const data = await response.json();
      console.log('Region data:', data);
      setRegion(data);
    } catch (error) {
      console.error('Error fetching region:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="fixed inset-0 bg-white flex flex-col items-center justify-center z-50">
        <Loader2 className="w-12 h-12 text-aegis-blue animate-spin mb-4" />
        <h2 className="text-2xl font-semibold text-gray-800 mb-2">
          Loading Region
        </h2>
        <p className="text-lg text-gray-600">
          Please wait...
        </p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-xl text-red-600">Error: {error}</div>
      </div>
    );
  }

  if (!region) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-xl text-gray-600">Region not found</div>
      </div>
    );
  }

  return (
    <div className="w-full overflow-x-hidden">
      <div className="max-w-[100vw] mx-auto">
        <RegionalTemplate regionData={region} />
      </div>
    </div>
  );
};

export default RegionPage; 