import React from 'react';
import { Link } from 'react-router-dom';
import { Building2, Users, Heart, Award, Leaf } from 'lucide-react';
import ClickToCall from './ClickToCall';

const AboutPage = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <div className="relative h-[400px] md:h-[500px] overflow-hidden">
        <img 
          src="https://www.aegisliving.com/wp-content/uploads/2023/10/Aegis-Employees-25-years.jpg"
          alt="Aegis Living Team"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black/40 flex items-center">
          <div className="max-w-7xl mx-auto px-4 text-white">
            <h1 className="text-4xl md:text-5xl font-bold mb-4">
              About Aegis Living
            </h1>
            <p className="text-xl md:text-2xl max-w-2xl">
              Discover where we came from and where we are headed. With over 25 years of experience 
              and many lessons learned, Aegis Living has grown into a business with a strong culture, 
              a unique point of view, and an eye for innovation.
            </p>
          </div>
        </div>
      </div>

      {/* Stats Section */}
      <div className="bg-white py-16">
        <div className="max-w-7xl mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            <div className="text-center">
              <div className="text-4xl font-bold text-aegis-blue mb-2">36+</div>
              <div className="text-gray-600">Communities</div>
            </div>
            <div className="text-center">
              <div className="text-4xl font-bold text-aegis-blue mb-2">2,500+</div>
              <div className="text-gray-600">Residents</div>
            </div>
            <div className="text-center">
              <div className="text-4xl font-bold text-aegis-blue mb-2">2,500+</div>
              <div className="text-gray-600">Staff Members</div>
            </div>
            <div className="text-center">
              <div className="text-4xl font-bold text-aegis-blue mb-2">25+</div>
              <div className="text-gray-600">Years of Experience</div>
            </div>
          </div>
        </div>
      </div>

      {/* Founder Section */}
      <div className="py-16">
        <div className="max-w-7xl mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
            <div>
              <h2 className="text-3xl font-bold mb-6">Our Founder</h2>
              <p className="text-xl text-gray-600 mb-6">
                CEO and Founder Dwayne Clark created Aegis Living as an answer to the question:
              </p>
              <p className="text-2xl font-semibold text-aegis-blue mb-6">
                What is possible in senior living?
              </p>
              <p className="text-gray-600 mb-6">
                Never happy with the industry status quo, Dwayne looked for ways to transform standards, 
                elevate care, and exceed the expectations of our families and residents.
              </p>
              <p className="text-gray-600">
                He knew there was a better way to serve our aging loved ones and has worked tirelessly 
                to reach that goal.
              </p>
            </div>
            <div>
              <img 
                src="https://www.aegisliving.com/wp-content/uploads/2023/08/Dwayne-Clark_2019-hires-600x600.jpg.webp"
                alt="Dwayne Clark"
                className="rounded-lg shadow-lg"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Values Section */}
      <div className="bg-white py-16">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Our Core Values</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="text-center p-6">
              <Heart className="w-12 h-12 text-aegis-blue mx-auto mb-4" />
              <h3 className="text-xl font-semibold mb-4">Employee First</h3>
              <p className="text-gray-600">
                We focus on the dedicated employees who serve and care for our residents. 
                We listen to our employees, incorporate their innovative ideas, and help them 
                carve a career path to success.
              </p>
            </div>
            <div className="text-center p-6">
              <Users className="w-12 h-12 text-aegis-blue mx-auto mb-4" />
              <h3 className="text-xl font-semibold mb-4">Dare To Care</h3>
              <p className="text-gray-600">
                At Aegis Living, we are united by our passion for service and caring for others. 
                We not only serve our residents, but we also serve our employees, creating a 
                supportive workplace.
              </p>
            </div>
            <div className="text-center p-6">
              <Award className="w-12 h-12 text-aegis-blue mx-auto mb-4" />
              <h3 className="text-xl font-semibold mb-4">Innovation</h3>
              <p className="text-gray-600">
                We continuously push the boundaries of what's possible in senior living, 
                from innovative care models and building design to novel approaches in 
                employee engagement.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Awards Section */}
      <div className="py-16">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Recognition & Awards</h2>
          <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-8">
            {/* Add award logos here */}
            <img src="/images/awards/great-place-to-work.png" alt="Great Place to Work" className="h-16 object-contain mx-auto" />
            <img src="/images/awards/glassdoor.png" alt="Glassdoor" className="h-16 object-contain mx-auto" />
            <img src="/images/awards/fortune.png" alt="Fortune" className="h-16 object-contain mx-auto" />
            <img src="/images/awards/us-news.png" alt="US News" className="h-16 object-contain mx-auto" />
            <img src="/images/awards/argentum.png" alt="Argentum" className="h-16 object-contain mx-auto" />
            <img src="/images/awards/seattle-business.png" alt="Seattle Business" className="h-16 object-contain mx-auto" />
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-aegis-blue text-white py-16">
        <div className="max-w-7xl mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-6">Experience the Aegis Difference</h2>
          <p className="text-xl mb-8">
            Find an Aegis Living community near you and discover what makes us different.
          </p>
          <Link
            to="/locations"
            className="inline-flex items-center gap-2 bg-white text-aegis-blue px-8 py-3 rounded-lg hover:bg-gray-100 transition-colors"
          >
            <Building2 size={20} />
            Find a Community
          </Link>
        </div>
      </div>

      {/* Sustainability Section */}
      <div className="py-16">
        <div className="max-w-7xl mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
            <div>
              <img 
                src="https://www.aegisliving.com/wp-content/uploads/2024/04/Sustainability.jpg.webp"
                alt="Sustainability"
                className="rounded-lg shadow-lg"
              />
            </div>
            <div>
              <div className="flex items-center gap-4 mb-6">
                <Leaf className="w-8 h-8 text-aegis-blue" />
                <h2 className="text-3xl font-bold">Sustainability</h2>
              </div>
              <p className="text-gray-600 mb-6">
                We're committed to sustainable practices and reducing our environmental impact. 
                Our communities are designed with energy efficiency in mind, and we continuously 
                work to minimize waste and conserve resources.
              </p>
              <ul className="space-y-4 text-gray-600">
                <li className="flex items-start gap-2">
                  <div className="w-6 h-6 rounded-full bg-aegis-blue/10 flex items-center justify-center flex-shrink-0 mt-1">
                    <div className="w-2 h-2 rounded-full bg-aegis-blue"></div>
                  </div>
                  Energy-efficient building design and operations
                </li>
                <li className="flex items-start gap-2">
                  <div className="w-6 h-6 rounded-full bg-aegis-blue/10 flex items-center justify-center flex-shrink-0 mt-1">
                    <div className="w-2 h-2 rounded-full bg-aegis-blue"></div>
                  </div>
                  Waste reduction and recycling programs
                </li>
                <li className="flex items-start gap-2">
                  <div className="w-6 h-6 rounded-full bg-aegis-blue/10 flex items-center justify-center flex-shrink-0 mt-1">
                    <div className="w-2 h-2 rounded-full bg-aegis-blue"></div>
                  </div>
                  Water conservation initiatives
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <ClickToCall />
    </div>
  );
};

export default AboutPage; 