import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { VERSION } from '../version';

const PublicFooter = () => {
  const location = useLocation();
  
  // Don't render the footer on the Waterfront funnel page
  if (location.pathname === '/waterfront-kirkland') {
    return null;
  }

  return (
    <footer className="bg-gray-50 border-t">
      <div className="max-w-7xl mx-auto px-4 py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8 mb-8">
          <div>
            <img 
              src="/images/aegis-living-logo-blue.svg" 
              alt="Aegis Living"
              className="h-12 sm:h-16 mb-4"
            />
            <p className="text-gray-600">
              Transforming the aging experience.
            </p>
          </div>
          
          <div>
            <h3 className="font-semibold text-gray-800 mb-4">Navigation</h3>
            <div className="space-y-2">
              <Link to="/locations" className="block text-gray-600 hover:text-aegis-blue">
                Our Communities
              </Link>
              <Link to="/about" className="block text-gray-600 hover:text-aegis-blue">
                About Us
              </Link>
              <Link to="/contact" className="block text-gray-600 hover:text-aegis-blue">
                Contact
              </Link>
            </div>
          </div>
          
          <div>
            <h3 className="font-semibold text-gray-800 mb-4">Legal</h3>
            <div className="space-y-2">
              <a 
                href="https://www.aegisliving.com/privacy-policy" 
                target="_blank" 
                rel="noopener noreferrer"
                className="block text-gray-600 hover:text-aegis-blue"
              >
                Privacy Policy
              </a>
              <a 
                href="https://www.aegisliving.com/ccpa-cpra-policy"
                target="_blank"
                rel="noopener noreferrer"
                className="block text-gray-600 hover:text-aegis-blue"
              >
                CCPA/CPRA Policy
              </a>
            </div>
          </div>
          
          <div>
            <h3 className="font-semibold text-gray-800 mb-4">Contact</h3>
            <div className="space-y-2">
              <a href="tel:+18556173677" className="block text-gray-600 hover:text-aegis-blue">
                (855) 617-3677
              </a>
              <a href="mailto:info@aegisliving.com" className="block text-gray-600 hover:text-aegis-blue">
                info@aegisliving.com
              </a>
            </div>
          </div>
        </div>
        
        <div className="border-t pt-8 text-center">
          <p className="text-gray-600">© {new Date().getFullYear()} Aegis Living. All rights reserved.</p>
          <p className="text-xs text-gray-400 mt-2">Version {VERSION}</p>
        </div>
      </div>
    </footer>
  );
};

export default PublicFooter; 