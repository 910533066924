import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Plus, Search, Filter, X, Pencil, Trash2, ExternalLink } from 'lucide-react';

const API_URL = process.env.REACT_APP_API_URL || '';

const CommunitiesList = () => {
  const [communities, setCommunities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({
    amenities: [],
    active: true
  });
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    communityName: '',
    communitySlug: ''
  });

  useEffect(() => {
    fetchCommunities();
  }, []);

  const fetchCommunities = async () => {
    try {
      console.log('Fetching from:', `${API_URL}/api/communities`);
      const response = await fetch(`${API_URL}/api/communities`, {
        headers: {
          'Authorization': 'Basic ' + btoa('admin:123456'),
          'Accept': 'application/json'
        }
      });

      if (!response.ok) {
        const text = await response.text();
        console.error('Server response:', text);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Received data:', data);
      setCommunities(data);
    } catch (error) {
      console.error('Error fetching communities:', error);
      console.error('Error details:', error.message);
    } finally {
      setLoading(false);
    }
  };

  const filteredCommunities = communities.filter(community => {
    const matchesSearch = community.name.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesActive = !filters.active || community.active;
    const matchesAmenities = filters.amenities.length === 0 || 
      filters.amenities.every(amenity => 
        community.amenities.some(a => a.name === amenity)
      );
    
    return matchesSearch && matchesActive && matchesAmenities;
  });

  const handleDelete = async () => {
    try {
      const response = await fetch(`${API_URL}/api/communities/${deleteModal.communitySlug}`, {
        method: 'DELETE',
        headers: {
          'Authorization': 'Basic ' + btoa('admin:123456')
        }
      });

      if (response.ok) {
        // Refresh the communities list
        await fetchCommunities();
        setDeleteModal({ isOpen: false, communityName: '', communitySlug: '' });
      } else {
        throw new Error('Failed to delete community');
      }
    } catch (error) {
      console.error('Error deleting community:', error);
      alert('Error deleting community. Please try again.');
    }
  };

  return (
    <>
      <div className="p-8">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-2xl font-semibold">Communities</h1>
          <Link
            to="/admin/communities/add"
            className="bg-aegis-blue text-white px-4 py-2 rounded-lg hover:bg-aegis-blue/90 transition-colors"
          >
            Add Community
          </Link>
        </div>

        {/* Search and Filters */}
        <div className="mb-6 flex gap-4">
          <div className="flex-1 relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
            <input
              type="text"
              placeholder="Search communities..."
              className="w-full pl-10 pr-4 py-2 border rounded-lg"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          {/* Add filter components here */}
        </div>

        {/* Communities Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredCommunities.map(community => (
            <div key={community._id} className="bg-white rounded-lg shadow-lg overflow-hidden">
              <div className="relative">
                <img
                  src={community.headerImage}
                  alt={community.name}
                  className="w-full h-48 object-cover"
                />
                <button
                  onClick={() => setDeleteModal({
                    isOpen: true,
                    communityName: community.name,
                    communitySlug: community.slug
                  })}
                  className="absolute top-4 right-4 p-2 bg-white rounded-full shadow-md text-gray-600 hover:text-red-600 hover:bg-red-50 transition-colors"
                  title="Delete Community"
                >
                  <Trash2 size={18} />
                </button>
              </div>
              <div className="p-6">
                <h2 className="text-xl font-semibold mb-2">{community.name}</h2>
                <p className="text-gray-600 mb-4">{community.subtitle}</p>
                <div className="flex flex-wrap gap-2 mb-4">
                  {community.amenities.slice(0, 3).map(amenity => (
                    <span key={amenity.name} className="bg-gray-100 px-3 py-1 rounded-full text-sm">
                      {amenity.name}
                    </span>
                  ))}
                  {community.amenities.length > 3 && (
                    <span className="text-sm text-gray-500">
                      +{community.amenities.length - 3} more
                    </span>
                  )}
                </div>
                <div className="flex justify-between items-center">
                  <Link
                    to={`/community/${community.slug}`}
                    className="inline-flex items-center gap-2 px-4 py-2 bg-aegis-blue text-white rounded-lg hover:bg-aegis-blue/90 transition-colors"
                  >
                    <span>View Community</span>
                    <ExternalLink size={16} />
                  </Link>
                  <Link
                    to={`/admin/communities/edit/${community.slug}`}
                    className="p-2 text-gray-600 hover:text-aegis-blue hover:bg-gray-50 rounded-full transition-colors"
                    title="Edit Community"
                  >
                    <Pencil size={18} />
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Delete Confirmation Modal */}
      {deleteModal.isOpen && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Delete Community
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to delete {deleteModal.communityName}? This action cannot be undone.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  onClick={handleDelete}
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Delete
                </button>
                <button
                  type="button"
                  onClick={() => setDeleteModal({ isOpen: false, communityName: '', communitySlug: '' })}
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CommunitiesList; 