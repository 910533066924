import React, { useState, useEffect, useRef, forwardRef } from 'react';

const CityAutocomplete = forwardRef(({ value, onChange, onSelect, onFocus, className, isChat, autoFocus }, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [inputValue, setInputValue] = useState(value);
  const [isGoogleLoaded, setIsGoogleLoaded] = useState(false);
  const wrapperRef = useRef(null);
  const inputRef = useRef(null);
  const autocompleteService = useRef(null);

  // Merge refs
  const mergedRef = (element) => {
    [ref, inputRef].forEach((ref) => {
      if (!ref) return;
      if (typeof ref === 'function') {
        ref(element);
      } else {
        ref.current = element;
      }
    });
  };

  useEffect(() => {
    // Auto focus when component mounts
    if (autoFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [autoFocus]);

  useEffect(() => {
    const checkGoogleMapsLoaded = () => {
      if (window.google && window.google.maps && window.google.maps.places) {
        try {
          autocompleteService.current = new window.google.maps.places.AutocompleteService();
          setIsGoogleLoaded(true);
        } catch (error) {
          console.error('Error initializing Google Places:', error);
          setIsGoogleLoaded(false);
        }
      } else {
        console.warn('Google Maps not loaded');
        setIsGoogleLoaded(false);
      }
    };

    checkGoogleMapsLoaded();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const fetchPredictions = (input) => {
    if (!input || !isGoogleLoaded || !autocompleteService.current) {
      setSuggestions([]);
      setIsOpen(false);
      return;
    }

    const request = {
      input,
      types: ['(cities)'],
      componentRestrictions: { country: 'us' }
    };

    try {
      autocompleteService.current.getPlacePredictions(request, (predictions, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
          const formattedPredictions = predictions.map(prediction => {
            const parts = prediction.description.split(',').map(part => part.trim());
            const city = parts[0];
            const stateWithCountry = parts[1];
            const state = stateWithCountry ? stateWithCountry.split(' ')[0] : '';
            
            return {
              fullText: `${city}, ${state}`,
              description: prediction.description
            };
          });
          
          setSuggestions(formattedPredictions.slice(0, 5));
          setIsOpen(true);
        } else {
          setSuggestions([]);
          setIsOpen(false);
        }
      });
    } catch (error) {
      console.error('Error fetching predictions:', error);
      setSuggestions([]);
      setIsOpen(false);
    }
  };

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    onChange(newValue);
    if (newValue.trim()) {
      fetchPredictions(newValue);
    } else {
      setSuggestions([]);
      setIsOpen(false);
    }
  };

  const handleSelect = (suggestion) => {
    setInputValue(suggestion.fullText);
    onChange(suggestion.fullText);
    setIsOpen(false);
    if (onSelect && isChat) {
      onSelect(suggestion.fullText);
    }
  };

  const suggestionsClass = isChat
    ? "absolute bottom-full mb-2 w-full bg-white border-2 border-gray-100 rounded-2xl shadow-lg max-h-60 overflow-y-auto flex flex-col-reverse"
    : "absolute z-10 w-full mt-2 bg-white border-2 border-gray-100 rounded-lg shadow-lg max-h-60 overflow-y-auto";

  const defaultClassName = isChat 
    ? "w-full p-6 text-lg border-2 border-gray-200 rounded-2xl focus:ring-2 focus:ring-aegis-blue/20 focus:border-aegis-blue transition-all shadow-sm hover:shadow-md"
    : "w-full p-4 border rounded-lg text-lg focus:ring-2 focus:ring-aegis-blue";

  return (
    <div className="relative" ref={wrapperRef}>
      <input
        ref={mergedRef}
        type="text"
        placeholder="Enter your preferred city"
        className={className || defaultClassName}
        value={inputValue}
        onChange={handleInputChange}
        onFocus={(e) => {
          if (onFocus) onFocus(e);
          if (inputValue?.trim()) {
            fetchPredictions(inputValue);
          }
        }}
        autoFocus={autoFocus}
      />
      
      {isOpen && suggestions.length > 0 && (
        <div className={suggestionsClass}>
          {suggestions.map((suggestion, index) => (
            <button
              key={index}
              className={`w-full px-6 py-4 text-left hover:bg-aegis-blue/5 transition-colors border-b last:border-b-0 ${
                isChat ? 'text-lg' : 'text-base'
              }`}
              onClick={() => handleSelect(suggestion)}
            >
              {suggestion.fullText}
            </button>
          ))}
        </div>
      )}
    </div>
  );
});

CityAutocomplete.displayName = 'CityAutocomplete';

export default CityAutocomplete; 