import React from 'react';
import { Phone, ArrowRight } from 'lucide-react';

const SuccessPage = ({ managerName, managerPhone, managerEmail, communityName, leadName }) => {
  return (
    <div className="min-h-screen bg-gray-50">
      {/* Add logo section */}
      <div className="w-full bg-white py-6 shadow-sm">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex justify-center">
            <img 
              src="https://www.aegisliving.com/wp-content/uploads/2023/06/aegis-living-logo-blue.svg"
              alt="Aegis Living"
              className="h-16 w-auto"
            />
          </div>
        </div>
      </div>

      {/* Success message */}
      <div className="flex-1 flex flex-col items-center justify-center p-4">
        <div className="max-w-2xl w-full bg-white rounded-2xl shadow-lg overflow-hidden">
          {/* Success check mark animation */}
          <div className="bg-aegis-blue text-white p-8 text-center">
            <div className="flex justify-center mb-4">
              <div className="rounded-full bg-white/10 p-3">
                <svg className="w-12 h-12" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    strokeWidth="2" 
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>
            </div>
            <h1 className="text-2xl font-semibold mb-2">Thank You, {leadName}!</h1>
            <p className="text-white/90">
              Your information has been received successfully.
            </p>
          </div>

          <div className="p-8">
            <div className="text-center mb-8">
              <p className="text-gray-600 text-lg">
                A representative from {communityName} will be calling you shortly to assist you with your needs and answer any questions you may have.
              </p>
            </div>

            {/* Contact section with manager info */}
            <div className="flex flex-col md:flex-row items-center gap-8 bg-gray-50 rounded-xl p-6">
              <div className="w-32 h-32 rounded-full overflow-hidden flex-shrink-0 border-4 border-white shadow-lg">
                <img
                  src="/images/happy-couple.jpg"
                  alt="Community Manager"
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="flex-1 text-center md:text-left">
                <h2 className="text-xl font-semibold text-gray-800 mb-2">
                  {managerName}
                </h2>
                <p className="text-gray-600 mb-4">
                  If you need immediate assistance, please don't hesitate to call us directly.
                </p>
                <div className="space-y-2">
                  <a
                    href={`tel:${managerPhone}`}
                    className="inline-flex items-center gap-2 bg-aegis-blue text-white px-6 py-3 rounded-lg hover:bg-aegis-blue/90 transition-colors"
                  >
                    <Phone className="w-5 h-5" />
                    <span>{managerPhone}</span>
                  </a>
                  <div className="text-sm text-gray-500">
                    or email: <a href={`mailto:${managerEmail}`} className="text-aegis-blue hover:underline">{managerEmail}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Additional Resources Section */}
        <div className="max-w-2xl w-full mt-8">
          <h2 className="text-2xl font-semibold text-gray-800 mb-6 text-center">
            Continue Your Journey
          </h2>

          {/* Dr. Raj Resource Card */}
          <div className="bg-white rounded-2xl shadow-lg overflow-hidden p-6 mb-6">
            <div className="flex flex-col md:flex-row gap-6">
              <div className="w-full md:w-1/3">
                <img
                  src="https://www.aegisliving.com/wp-content/uploads/2024/04/Dr-Raj-photo.jpg.webp"
                  alt="Dr. Raj"
                  className="w-full h-48 md:h-full object-cover rounded-xl"
                />
              </div>
              <div className="w-full md:w-2/3 flex flex-col justify-between">
                <div>
                  <h3 className="text-xl font-semibold text-aegis-blue mb-3">
                    Living Well with Dr. Raj
                  </h3>
                  <p className="text-gray-600 mb-4">
                    Discover innovative wellness and longevity programs designed to extend our residents' healthspan—living better for longer. From prioritizing quality sleep to advancements in cognitive behavioral therapy and heart-healthy programs, our forward-thinking methodology unlocks potential in every detail.
                  </p>
                </div>
                <a
                  href="https://www.aegisliving.com/living-well-with-dr-raj/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center gap-2 text-aegis-blue hover:text-aegis-blue/80 font-medium group"
                >
                  Learn More 
                  <ArrowRight className="w-5 h-5 transform group-hover:translate-x-1 transition-transform" />
                </a>
              </div>
            </div>
          </div>

          {/* Additional Resources Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-8">
            {/* The Aegis Living Difference */}
            <div className="bg-white rounded-xl shadow-lg overflow-hidden p-6">
              <h3 className="text-lg font-semibold text-aegis-blue mb-3">
                The Aegis Living Difference
              </h3>
              <p className="text-gray-600 mb-4">
                Discover how our innovative approach to senior living sets new standards in care and comfort.
              </p>
              <a
                href="https://www.aegisliving.com/where-to-begin/the-aegis-living-difference/"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center gap-2 text-aegis-blue hover:text-aegis-blue/80 font-medium group"
              >
                Learn More 
                <ArrowRight className="w-5 h-5 transform group-hover:translate-x-1 transition-transform" />
              </a>
            </div>

            {/* Financial Considerations */}
            <div className="bg-white rounded-xl shadow-lg overflow-hidden p-6">
              <h3 className="text-lg font-semibold text-aegis-blue mb-3">
                Financial Considerations
              </h3>
              <p className="text-gray-600 mb-4">
                Understanding the costs and financial options available for senior living care.
              </p>
              <a
                href="https://www.aegisliving.com/where-to-begin/financial-considerations/"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center gap-2 text-aegis-blue hover:text-aegis-blue/80 font-medium group"
              >
                Learn More 
                <ArrowRight className="w-5 h-5 transform group-hover:translate-x-1 transition-transform" />
              </a>
            </div>

            {/* How to Choose a Community */}
            <div className="bg-white rounded-xl shadow-lg overflow-hidden p-6">
              <h3 className="text-lg font-semibold text-aegis-blue mb-3">
                How to Choose a Community
              </h3>
              <p className="text-gray-600 mb-4">
                A step-by-step guide to finding the perfect senior living community for your loved one.
              </p>
              <a
                href="https://www.aegisliving.com/where-to-begin/how-to-choose-a-community/"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center gap-2 text-aegis-blue hover:text-aegis-blue/80 font-medium group"
              >
                Learn More 
                <ArrowRight className="w-5 h-5 transform group-hover:translate-x-1 transition-transform" />
              </a>
            </div>

            {/* Having the Conversation */}
            <div className="bg-white rounded-xl shadow-lg overflow-hidden p-6">
              <h3 className="text-lg font-semibold text-aegis-blue mb-3">
                Having the Conversation
              </h3>
              <p className="text-gray-600 mb-4">
                Tips and guidance for discussing senior living options with your loved ones.
              </p>
              <a
                href="https://www.aegisliving.com/where-to-begin/having-the-conversation/"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center gap-2 text-aegis-blue hover:text-aegis-blue/80 font-medium group"
              >
                Learn More 
                <ArrowRight className="w-5 h-5 transform group-hover:translate-x-1 transition-transform" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage; 