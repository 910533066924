import { useState, useEffect } from 'react';
import { Phone, X } from 'lucide-react';
import PhoneInput from './PhoneInput';

const gradientAnimation = `
@keyframes gradientBG {
  0% { background-position: 0% 50%; }
  25% { background-position: 100% 50%; }
  50% { background-position: 50% 0%; }
  75% { background-position: 50% 100%; }
  100% { background-position: 0% 50%; }
}
`;

const ClickToCall = ({ communitySlug }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [phone, setPhone] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [callInitiated, setCallInitiated] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [isClosing, setIsClosing] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isIconVisible, setIsIconVisible] = useState(true);

  // Check for mobile screen size and handle viewport height
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 640);
    };
    
    // Update CSS custom property for viewport height
    const updateVH = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    // Initial checks
    checkMobile();
    updateVH();

    // Event listeners
    window.addEventListener('resize', checkMobile);
    window.addEventListener('resize', updateVH);
    window.addEventListener('orientationchange', updateVH);

    // Cleanup
    return () => {
      window.removeEventListener('resize', checkMobile);
      window.removeEventListener('resize', updateVH);
      window.removeEventListener('orientationchange', updateVH);
    };
  }, []);

  // Hide button after call initiation
  useEffect(() => {
    if (callInitiated) {
      const timer = setTimeout(() => {
        setShowButton(false);
      }, 30000); // 30 seconds
      return () => clearTimeout(timer);
    }
  }, [callInitiated]);

  // Add function to get UTM parameters
  const getUtmParams = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return {
      source: urlParams.get('utm_source'),
      medium: urlParams.get('utm_medium'),
      campaign: urlParams.get('utm_campaign'),
      term: urlParams.get('utm_term'),
      content: urlParams.get('utm_content'),
      gclid: urlParams.get('gclid')
    };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      // Track call initiation
      window.gtag('event', 'click_to_call_submit', {
        'event_category': 'Engagement',
        'event_label': 'Call Request',
        'phone_number': phone,
        'community_slug': communitySlug || 'general',
        'page_url': window.location.href
      });

      const response = await fetch('/api/call', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          phone,
          communitySlug,
          pageUrl: window.location.href,
          utm: getUtmParams()
        })
      });

      if (!response.ok) {
        throw new Error('Failed to initiate call');
      }

      // Track successful call connection
      window.gtag('event', 'call_connected', {
        'event_category': 'Engagement',
        'event_label': 'Call Connected',
        'phone_number': phone,
        'community_slug': communitySlug || 'general',
        'page_url': window.location.href
      });

      setCallInitiated(true);
      handleClose();
      setPhone('');
    } catch (err) {
      // Track call failure
      window.gtag('event', 'call_failed', {
        'event_category': 'Engagement',
        'event_label': 'Call Failed',
        'error_message': err.message,
        'community_slug': communitySlug || 'general',
        'page_url': window.location.href
      });

      setError('Failed to initiate call. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleWidgetClick = () => {
    const eventData = {
      'event_category': 'Engagement',
      'event_label': 'Call Widget Opened',
      'location': communitySlug || 'Main Site'
    };
    console.log('GA Event - Call Widget Click:', eventData);
    window.gtag('event', 'call_widget_click', eventData);
    setIsOpen(true);
  };

  const handleCallInitiation = (phoneNumber) => {
    const eventData = {
      'event_category': 'Engagement',
      'event_label': 'Call Started',
      'phone_number': phoneNumber,
      'location': communitySlug || 'Main Site'
    };
    console.log('GA Event - Call Initiated:', eventData);
    window.gtag('event', 'call_initiated', eventData);
  };

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsOpen(false);
      setIsClosing(false);
    }, 300);
  };

  if (!showButton) return null;

  return (
    <>
      <style>
        {`
          ${gradientAnimation}
          
          .mobile-fixed-bottom {
            position: fixed;
            bottom: calc(var(--vh, 1vh) * 6);
          }
        `}
      </style>
      <div className={`
        fixed ${isMobile ? 'bottom-4 left-1/2 -translate-x-1/2' : 'bottom-4 right-24'} 
        z-[60]
      `}>
        <button
          onClick={isOpen ? handleClose : handleWidgetClick}
          className={`
            flex items-center justify-center gap-2 
            ${isOpen 
              ? 'w-14 h-14 bg-gray-100 hover:bg-gray-200 border-2 border-gray-400' 
              : 'w-auto h-14 px-6 bg-[#003f72] text-white shadow-lg hover:bg-[#003f72]/90'
            }
            rounded-full transition-all transform hover:scale-105 hover:shadow-xl
            ${isIconVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'}
          `}
          style={{ transition: 'all 0.3s ease-in-out' }}
          aria-label={isOpen ? "Close call form" : "Click to call"}
        >
          {isOpen ? (
            <X className="w-5 h-5 text-gray-600" />
          ) : (
            <>
              <Phone className="w-5 h-5" />
              <span className="font-medium">Click 2 Call</span>
            </>
          )}
        </button>

        {isOpen && (
          <div 
            className={`
              absolute bottom-16
              ${isMobile 
                ? 'left-1/2 -translate-x-1/2' 
                : 'right-0'
              }
              bg-white rounded-xl shadow-2xl p-6 w-80
              transition-all duration-300 ease-in-out
              ${isClosing ? 'opacity-0 translate-y-4' : 'opacity-100 translate-y-0'}
            `}
            style={{ zIndex: 99999999 }}
          >
            {/* Chat bubble arrow */}
            <div 
              className={`
                absolute -bottom-2
                ${isMobile ? 'left-1/2 -translate-x-1/2' : 'right-6'}
              `}
              style={{
                width: '0',
                height: '0',
                borderLeft: '8px solid transparent',
                borderRight: '8px solid transparent',
                borderTop: '8px solid white',
                filter: 'drop-shadow(0 2px 2px rgba(0, 0, 0, 0.1))'
              }}
            />

            <div className="flex justify-between items-center mb-6">
              <h3 className="text-xl font-semibold text-aegis-blue">Request a Call</h3>
              <button
                onClick={handleClose}
                className="text-gray-400 hover:text-gray-600 transition-colors p-1 hover:bg-gray-100 rounded-full"
              >
                <X className="w-5 h-5" />
              </button>
            </div>

            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Your Phone Number
                </label>
                <PhoneInput
                  value={phone}
                  onChange={setPhone}
                  placeholder="(425) 659-3432"
                  required
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-aegis-blue/20 focus:border-aegis-blue"
                />
              </div>

              {error && (
                <p className="text-red-500 text-sm">{error}</p>
              )}

              <button
                type="submit"
                disabled={isLoading || !phone}
                className={`
                  w-full py-3 px-4 rounded-md font-medium
                  transition-all duration-300 ease-in-out
                  flex items-center justify-center gap-2
                  ${isLoading || !phone
                    ? 'bg-gray-200 text-gray-400 cursor-not-allowed'
                    : 'bg-aegis-blue text-white hover:bg-aegis-blue/90 transform hover:scale-105'
                  }
                `}
              >
                {isLoading ? (
                  <>
                    <Phone className="w-5 h-5 animate-pulse" />
                    <span>Initiating Call...</span>
                  </>
                ) : (
                  <>
                    <Phone className="w-5 h-5" />
                    <span>Call Me</span>
                  </>
                )}
              </button>

              <p className="text-xs text-gray-500 text-center mt-4">
                Our team will call you right away to discuss your needs
              </p>
            </form>
          </div>
        )}
      </div>
    </>
  );
};

export default ClickToCall; 