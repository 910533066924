import React from 'react';

const BackgroundDecoration = () => {
  return (
    <div 
      className="fixed inset-0 -z-10 pointer-events-none"
      style={{
        background: 'linear-gradient(to bottom, white, rgba(0,64,115,0.03), rgba(0,64,115,0.05))'
      }}
    />
  );
};

export default BackgroundDecoration; 