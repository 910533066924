import React from 'react';
import { MapPin, Phone, Mail, ArrowRight, X } from 'lucide-react';

const LocationMap = ({ 
  location, 
  manager, 
  communityName, 
  headerImage, 
  currentCommunitySlug,
  allCommunities,
  fitAllMarkers = false,
  hideOverlay = true
}) => {
  const mapUrl = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
  const mapRef = React.useRef(null);
  const [map, setMap] = React.useState(null);
  const [markers, setMarkers] = React.useState([]);
  const [activeInfoWindow, setActiveInfoWindow] = React.useState(null);

  const removeDefaultCloseButton = `
    <style>
      .gm-style-iw {
        padding: 0 !important;
      }
      .gm-style-iw-d {
        overflow: hidden !important;
        padding: 0 !important;
      }
      .gm-style-iw-c {
        padding: 0 !important;
        border-radius: 8px !important;
      }
      .gm-ui-hover-effect {
        display: none !important;
      }
      .gm-style-iw-tc {
        display: none !important;
      }
      .gm-style-iw-chr {
        display: none !important;
      }
      .gm-style-iw-ch {
        display: none !important;
      }
      .gm-style .gm-style-iw-t::after {
        background: white !important;
        box-shadow: -2px 2px 2px 0 rgba(178, 178, 178, 0.4) !important;
        content: "";
        height: 15px !important;
        left: 0 !important;
        position: absolute !important;
        top: 0 !important;
        transform: translate(-50%, -50%) rotate(-45deg) !important;
        width: 15px !important;
      }
      .gm-style-iw-t {
        bottom: 0 !important;
        padding-bottom: 15px !important;
      }
    </style>
  `;

  const createMarkerContent = (community, isCurrent = false) => `
    ${removeDefaultCloseButton}
    <div class="bg-white rounded-lg shadow-lg overflow-hidden" style="min-width: 280px; max-width: 320px; margin: 0; padding: 0;">
      <div class="relative">
        <img 
          src="${community.headerImage}" 
          alt="${community.name}"
          style="width: 100%; height: 120px; object-fit: cover;"
        />
        <button
          class="absolute top-2 right-2 bg-white rounded-full p-1 shadow-lg hover:bg-gray-100 transition-colors"
          style="width: 24px; height: 24px; display: flex; align-items: center; justify-content: center;"
          onclick="this.closest('.gm-style-iw-c').querySelector('.gm-ui-hover-effect').click();"
        >
          <svg width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
            <path d="M18 6L6 18M6 6l12 12"/>
          </svg>
        </button>
      </div>
      <div class="p-4">
        <h3 class="text-base font-semibold text-aegis-blue mb-2 line-clamp-2" style="color: #004073; overflow-wrap: break-word; word-break: break-word;">
          ${community.name}
        </h3>
        <div class="text-sm mb-4" style="overflow-wrap: break-word; word-break: break-word;">
          ${community.location.address}<br/>
          ${community.location.city}, ${community.location.state} ${community.location.zip}
        </div>
        <div class="flex items-center justify-between gap-2 flex-wrap">
          <a 
            href="https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
              `${community.location.address}, ${community.location.city}, ${community.location.state} ${community.location.zip}`
            )}"
            target="_blank"
            rel="noopener noreferrer"
            class="inline-flex items-center gap-1 text-aegis-blue hover:underline text-sm"
            style="color: #004073;"
          >
            Get Directions
            <svg width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
              <path d="M5 12h14M12 5l7 7-7 7"/>
            </svg>
          </a>
          ${!isCurrent ? `
            <a 
              href="/community/${community.slug}"
              class="inline-flex items-center gap-1 bg-aegis-blue text-white px-3 py-1 rounded hover:bg-aegis-blue/90 transition-colors text-sm whitespace-nowrap"
              style="text-decoration: none;"
            >
              View Details
              <svg width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" style="color: white;">
                <path d="M5 12h14M12 5l7 7-7 7"/>
              </svg>
            </a>
          ` : ''}
        </div>
      </div>
    </div>
  `;

  React.useEffect(() => {
    const loadMap = () => {
      if (!location?.coordinates?.lat || !location?.coordinates?.lng) return;

      const mapOptions = {
        center: { 
          lat: location.coordinates.lat, 
          lng: location.coordinates.lng 
        },
        zoom: 11,
        styles: [
          {
            featureType: "all",
            elementType: "labels.text.fill",
            stylers: [{ color: "#666666" }]
          },
          {
            featureType: "poi",  // Points of interest
            elementType: "all",
            stylers: [{ visibility: "off" }]
          },
          {
            featureType: "transit",  // Transit stations
            elementType: "all",
            stylers: [{ visibility: "off" }]
          },
          {
            featureType: "road",
            elementType: "labels.icon",
            stylers: [{ visibility: "off" }]
          },
          {
            featureType: "landscape",
            elementType: "all",
            stylers: [{ color: "#f5f5f5" }]
          },
          {
            featureType: "road",
            elementType: "geometry",
            stylers: [{ color: "#ffffff" }]
          },
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [{ color: "#e9e9e9" }]
          }
        ],
        disableDefaultUI: true,
        zoomControl: true
      };

      const newMap = new window.google.maps.Map(mapRef.current, mapOptions);
      setMap(newMap);

      // Create markers for all communities
      const newMarkers = allCommunities.map(community => {
        if (!community.location?.coordinates?.lat || !community.location?.coordinates?.lng) {
          return null;
        }

        const isCurrent = community.slug === currentCommunitySlug;
        
        // Create the marker
        const marker = new window.google.maps.Marker({
          position: { 
            lat: community.location.coordinates.lat, 
            lng: community.location.coordinates.lng 
          },
          map: newMap,
          icon: {
            path: window.google.maps.SymbolPath.CIRCLE,
            scale: 8,
            fillColor: "#004073",
            fillOpacity: 1,
            strokeColor: "#ffffff",
            strokeWeight: 2,
          }
        });

        // Add pulsing effect for current community
        if (isCurrent) {
          let scale = 8;
          let increasing = true;

          const pulse = setInterval(() => {
            if (increasing) {
              scale += 0.2;
              if (scale >= 12) {
                increasing = false;
              }
            } else {
              scale -= 0.2;
              if (scale <= 8) {
                increasing = true;
              }
            }

            marker.setIcon({
              path: window.google.maps.SymbolPath.CIRCLE,
              scale: scale,
              fillColor: "#004073",
              fillOpacity: 1,
              strokeColor: "#ffffff",
              strokeWeight: 2,
            });
          }, 50);

          // Clean up interval when marker is removed
          marker.addListener('click', () => {
            clearInterval(pulse);
          });
        }

        const infoWindow = new window.google.maps.InfoWindow({
          content: createMarkerContent(community, isCurrent),
          maxWidth: 350,
          pixelOffset: new window.google.maps.Size(0, -15)
        });

        // Store the infoWindow reference with the marker
        marker.infoWindow = infoWindow;

        marker.addListener('click', () => {
          // Close all info windows
          newMarkers.forEach(m => {
            if (m && m.infoWindow) {
              m.infoWindow.close();
            }
          });
          
          // Open this marker's info window
          infoWindow.open(newMap, marker);

          // Center the map on the marker with a slight offset for the info window
          const center = new window.google.maps.LatLng(
            community.location.coordinates.lat,
            community.location.coordinates.lng
          );
          
          // Calculate offset to center both vertically and horizontally
          const offsetX = -175; // Half of max-width (350px)
          const offsetY = -250; // Adjust this value to account for infoWindow height
          
          // Get the map's projection
          const projection = newMap.getProjection();
          const point = projection.fromLatLngToPoint(center);
          const newPoint = new window.google.maps.Point(
            point.x + (offsetX / Math.pow(2, newMap.getZoom())),
            point.y + (offsetY / Math.pow(2, newMap.getZoom()))
          );
          
          const newCenter = projection.fromPointToLatLng(newPoint);
          newMap.panTo(newCenter);
        });

        // Open info window for current community by default
        if (isCurrent) {
          infoWindow.open(newMap, marker);
        }

        return marker;
      }).filter(Boolean);

      setMarkers(newMarkers);

      // If fitAllMarkers is true, adjust the map to show all markers
      if (fitAllMarkers && newMarkers.length > 0) {
        const bounds = new window.google.maps.LatLngBounds();
        newMarkers.forEach(marker => {
          bounds.extend(marker.getPosition());
        });
        newMap.fitBounds(bounds);
        
        // Add a small padding to the bounds
        const padding = { 
          top: 50, 
          right: 50, 
          bottom: 50, 
          left: 50 
        };
        newMap.fitBounds(bounds, padding);
      }
    };

    if (window.google && window.google.maps) {
      loadMap();
    } else {
      const script = document.createElement('script');
      script.src = mapUrl;
      script.async = true;
      script.defer = true;
      script.addEventListener('load', loadMap);
      document.head.appendChild(script);
    }

    // Cleanup
    return () => {
      markers.forEach(marker => marker.setMap(null));
    };
  }, [location, mapUrl, communityName, headerImage, allCommunities, currentCommunitySlug, fitAllMarkers]);

  if (!location?.coordinates?.lat || !location?.coordinates?.lng) {
    return null;
  }

  return (
    <div className="relative w-full">
      {/* Map Container */}
      <div ref={mapRef} className="w-full h-[500px]" />

      {/* Location Info Overlay - Only show if hideOverlay is false */}
      {!hideOverlay && (
        <div className="absolute top-8 left-8 bg-white rounded-lg shadow-xl p-6 max-w-[350px]">
          <h3 className="text-2xl font-semibold text-aegis-blue mb-2">
            Aegis Living {communityName}
          </h3>
          <p className="text-gray-600 mb-4">Visit our community in {location.city}</p>
          
          <div className="space-y-4">
            {/* Address */}
            <div className="flex items-start gap-3">
              <MapPin className="w-5 h-5 text-aegis-blue flex-shrink-0 mt-1" />
              <div>
                <p className="font-medium">{location.address}</p>
                <p>{location.city}, {location.state} {location.zip}</p>
              </div>
            </div>

            {/* Contact Info */}
            {manager && (
              <>
                <div className="flex items-center gap-3">
                  <Phone className="w-5 h-5 text-aegis-blue flex-shrink-0" />
                  <a 
                    href={`tel:${manager.phone}`}
                    className="hover:text-aegis-blue"
                  >
                    {manager.phone}
                  </a>
                </div>
                <div className="flex items-center gap-3">
                  <Mail className="w-5 h-5 text-aegis-blue flex-shrink-0" />
                  <a 
                    href={`mailto:${manager.email}`}
                    className="hover:text-aegis-blue"
                  >
                    {manager.email}
                  </a>
                </div>
              </>
            )}

            {/* Get Directions Button */}
            <a
              href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
                `${location.address}, ${location.city}, ${location.state} ${location.zip}`
              )}`}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center gap-2 bg-aegis-blue text-white px-6 py-3 rounded-lg hover:bg-aegis-blue/90 transition-colors mt-4"
            >
              Get Directions
              <ArrowRight size={18} />
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default LocationMap; 