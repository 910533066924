import React, { useEffect, useRef } from 'react';
import { X } from 'lucide-react';

const VideoModal = ({ isOpen, onClose, videoUrl, preload = 'none', autoPlay = false }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    // Handle cleanup when modal closes
    return () => {
      if (videoRef.current) {
        videoRef.current.pause();
      }
    };
  }, [isOpen]);

  if (!isOpen) return null;

  // Function to convert YouTube URL to embed URL
  const getYouTubeEmbedUrl = (url) => {
    if (!url) return '';
    
    // Handle different YouTube URL formats
    let videoId = '';
    
    // Format: https://www.youtube.com/watch?v=VIDEO_ID
    const watchMatch = url.match(/(?:\?v=|&v=|youtu\.be\/|\/v\/|\/embed\/)([^&\n?#]+)/);
    if (watchMatch) {
      videoId = watchMatch[1];
    }

    return videoId ? `https://www.youtube.com/embed/${videoId}` : url;
  };

  // Check if it's a YouTube URL
  const isYouTube = videoUrl?.toLowerCase().includes('youtube.com') || 
                    videoUrl?.toLowerCase().includes('youtu.be');

  // Get proper video URL
  const processedVideoUrl = isYouTube ? getYouTubeEmbedUrl(videoUrl) : videoUrl;

  return (
    <div className="fixed inset-0 bg-black/70 flex items-center justify-center z-50 p-4">
      <div className="relative w-full max-w-4xl bg-white rounded-lg shadow-lg">
        <button
          onClick={onClose}
          className="absolute -top-4 -right-4 w-8 h-8 bg-aegis-blue text-white rounded-full flex items-center justify-center hover:bg-aegis-blue/90 transition-colors"
          aria-label="Close video"
        >
          <X size={20} />
        </button>
        <div className="aspect-video w-full">
          {isYouTube ? (
            <iframe
              width="100%"
              height="100%"
              src={`${processedVideoUrl}?autoplay=1`}
              title="Video"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="rounded-lg"
            />
          ) : (
            <video
              ref={videoRef}
              className="w-full h-full rounded-lg"
              controls
              autoPlay={autoPlay}
              preload={preload}
            >
              <source src={processedVideoUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </div>
      </div>
    </div>
  );
};

export default VideoModal; 