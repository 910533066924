import React, { useState, useEffect } from 'react';
import { useDialog } from '@react-aria/dialog';
import { useOverlay, useModal, OverlayContainer } from '@react-aria/overlays';
import { useButton } from '@react-aria/button';

const AccessibilityIcon = () => (
  <svg 
    width="24" 
    height="24" 
    viewBox="0 0 24 24" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    className="text-aegis-blue"
  >
    <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2"/>
    <circle cx="12" cy="7" r="2" fill="currentColor"/>
    <path 
      d="M8 12h8M12 11v6" 
      stroke="currentColor" 
      strokeWidth="2" 
      strokeLinecap="round"
    />
  </svg>
);

const Accessibility = ({ hasVideoButton = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [settings, setSettings] = useState({
    fontSize: 'normal',
    contrast: 'normal',
    cursor: 'normal',
    spacing: 'normal',
    dyslexicFont: false,
    reducedMotion: false
  });

  // Load settings from localStorage on mount
  useEffect(() => {
    const savedSettings = localStorage.getItem('accessibility');
    if (savedSettings) {
      const parsedSettings = JSON.parse(savedSettings);
      setSettings(parsedSettings);
      applySettings(parsedSettings);
    }
  }, []);

  // Save settings to localStorage when they change
  useEffect(() => {
    localStorage.setItem('accessibility', JSON.stringify(settings));
    applySettings(settings);
  }, [settings]);

  const applySettings = (newSettings) => {
    // Font Size
    document.documentElement.style.fontSize = {
      'normal': '16px',
      'large': '18px',
      'larger': '20px'
    }[newSettings.fontSize];

    // Contrast
    document.documentElement.setAttribute('data-contrast', newSettings.contrast);

    // Letter Spacing
    document.documentElement.style.letterSpacing = {
      'normal': 'normal',
      'wide': '0.05em',
      'wider': '0.1em'
    }[newSettings.spacing];

    // Dyslexic Font
    if (newSettings.dyslexicFont) {
      document.documentElement.style.fontFamily = 'OpenDyslexic, sans-serif';
    } else {
      document.documentElement.style.fontFamily = 'var(--body-font)';
    }

    // Reduced Motion
    if (newSettings.reducedMotion) {
      document.documentElement.style.setProperty('--transition-duration', '0s');
    } else {
      document.documentElement.style.removeProperty('--transition-duration');
    }
  };

  const resetSettings = () => {
    const defaultSettings = {
      fontSize: 'normal',
      contrast: 'normal',
      cursor: 'normal',
      spacing: 'normal',
      dyslexicFont: false,
      reducedMotion: false
    };
    setSettings(defaultSettings);
  };

  // Accessibility hooks
  const overlayRef = React.useRef(null);
  const { overlayProps } = useOverlay(
    { isOpen, onClose: () => setIsOpen(false) },
    overlayRef
  );
  const { modalProps } = useModal();
  const { dialogProps } = useDialog({}, overlayRef);

  const toggleButtonRef = React.useRef(null);
  const { buttonProps } = useButton({
    onPress: () => setIsOpen(true)
  }, toggleButtonRef);

  return (
    <>
      <div 
        {...buttonProps}
        ref={toggleButtonRef}
        className={`fixed ${hasVideoButton ? 'bottom-20' : 'bottom-4'} left-4 bg-white text-aegis-blue rounded-full shadow-lg p-3 hover:bg-gray-50 flex items-center gap-2`}
        style={{ zIndex: 999999 }}
        aria-label="Accessibility Settings"
      >
        <AccessibilityIcon />
        <span className="sr-only">Accessibility Options</span>
      </div>

      {isOpen && (
        <OverlayContainer>
          <div className="fixed inset-0 bg-black/50" style={{ zIndex: 9999999 }}>
            <div
              {...overlayProps}
              {...dialogProps}
              {...modalProps}
              ref={overlayRef}
              className="fixed inset-0 flex items-center justify-center p-4"
            >
              <div 
                className="bg-white rounded-lg shadow-xl max-w-md w-full p-6"
                onClick={e => e.stopPropagation()}
              >
                <div className="flex justify-between items-center mb-6">
                  <h2 className="text-xl font-semibold">
                    Accessibility Settings
                  </h2>
                  <button
                    onClick={() => setIsOpen(false)}
                    className="text-gray-500 hover:text-gray-700"
                    aria-label="Close accessibility settings"
                  >
                    ×
                  </button>
                </div>

                <div className="space-y-6">
                  {/* Font Size */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Font Size
                    </label>
                    <select
                      value={settings.fontSize}
                      onChange={(e) => setSettings(prev => ({ ...prev, fontSize: e.target.value }))}
                      className="w-full p-2 border rounded-lg"
                    >
                      <option value="normal">Normal</option>
                      <option value="large">Large</option>
                      <option value="larger">Larger</option>
                    </select>
                  </div>

                  {/* Contrast */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Contrast
                    </label>
                    <select
                      value={settings.contrast}
                      onChange={(e) => setSettings(prev => ({ ...prev, contrast: e.target.value }))}
                      className="w-full p-2 border rounded-lg"
                    >
                      <option value="normal">Normal</option>
                      <option value="high">High Contrast</option>
                      <option value="inverted">Inverted</option>
                    </select>
                  </div>

                  {/* Letter Spacing */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Letter Spacing
                    </label>
                    <select
                      value={settings.spacing}
                      onChange={(e) => setSettings(prev => ({ ...prev, spacing: e.target.value }))}
                      className="w-full p-2 border rounded-lg"
                    >
                      <option value="normal">Normal</option>
                      <option value="wide">Wide</option>
                      <option value="wider">Wider</option>
                    </select>
                  </div>

                  {/* Dyslexic Font */}
                  <div>
                    <label className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        checked={settings.dyslexicFont}
                        onChange={(e) => setSettings(prev => ({ ...prev, dyslexicFont: e.target.checked }))}
                        className="w-4 h-4 text-aegis-blue border-gray-300 rounded focus:ring-aegis-blue"
                      />
                      <span className="text-sm font-medium text-gray-700">
                        Use Dyslexia-Friendly Font
                      </span>
                    </label>
                  </div>

                  {/* Reduced Motion */}
                  <div>
                    <label className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        checked={settings.reducedMotion}
                        onChange={(e) => setSettings(prev => ({ ...prev, reducedMotion: e.target.checked }))}
                        className="w-4 h-4 text-aegis-blue border-gray-300 rounded focus:ring-aegis-blue"
                      />
                      <span className="text-sm font-medium text-gray-700">
                        Reduce Motion
                      </span>
                    </label>
                  </div>

                  {/* Reset Button */}
                  <button
                    onClick={resetSettings}
                    className="w-full py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200"
                  >
                    Reset to Default
                  </button>
                </div>
              </div>
            </div>
          </div>
        </OverlayContainer>
      )}
    </>
  );
};

export default Accessibility; 