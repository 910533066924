import React from 'react';
import { Link } from 'react-router-dom';
import { Users, Building2, Cog, Map } from 'lucide-react';

const AdminPanel = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 py-12">
        <h1 className="text-3xl font-semibold mb-8">Admin Dashboard</h1>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          <Link
            to="/admin/leads"
            className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow"
          >
            <div className="flex items-center gap-4">
              <Users className="w-8 h-8 text-aegis-blue" />
              <div>
                <h2 className="text-xl font-semibold">Leads</h2>
                <p className="text-gray-600">View and manage leads</p>
              </div>
            </div>
          </Link>

          <Link
            to="/admin/communities"
            className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow"
          >
            <div className="flex items-center gap-4">
              <Building2 className="w-8 h-8 text-aegis-blue" />
              <div>
                <h2 className="text-xl font-semibold">Communities</h2>
                <p className="text-gray-600">Manage community pages</p>
              </div>
            </div>
          </Link>

          <Link
            to="/admin/regions"
            className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow"
          >
            <div className="flex items-center gap-4">
              <Map className="w-8 h-8 text-aegis-blue" />
              <div>
                <h2 className="text-xl font-semibold">Regions</h2>
                <p className="text-gray-600">Manage regional pages</p>
              </div>
            </div>
          </Link>

          <Link
            to="/admin/settings"
            className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow"
          >
            <div className="flex items-center gap-4">
              <Cog className="w-8 h-8 text-aegis-blue" />
              <div>
                <h2 className="text-xl font-semibold">Settings</h2>
                <p className="text-gray-600">Configure system settings</p>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AdminPanel; 