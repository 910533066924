import React, { useState, useEffect, useRef } from 'react';

const AddressAutocomplete = ({ value, onChange }) => {
  const [isGoogleLoaded, setIsGoogleLoaded] = useState(false);
  const autocompleteRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (window.google && window.google.maps && window.google.maps.places) {
      initializeAutocomplete();
      setIsGoogleLoaded(true);
    }
  }, []);

  const initializeAutocomplete = () => {
    if (!inputRef.current) return;

    autocompleteRef.current = new window.google.maps.places.Autocomplete(inputRef.current, {
      componentRestrictions: { country: 'us' },
      fields: ['address_components', 'formatted_address', 'geometry'],
      types: ['address']
    });

    autocompleteRef.current.addListener('place_changed', handlePlaceSelect);
  };

  const handlePlaceSelect = () => {
    const place = autocompleteRef.current.getPlace();
    console.log('Selected place:', place);

    if (!place.address_components) {
      console.warn('No address components found');
      return;
    }

    let addressData = {
      address: '',
      city: '',
      state: '',
      zip: '',
      coordinates: {
        lat: place.geometry?.location?.lat() || null,
        lng: place.geometry?.location?.lng() || null
      }
    };

    // Create a map for address components
    const componentMap = {};
    place.address_components.forEach(component => {
      component.types.forEach(type => {
        componentMap[type] = component;
      });
    });

    // Extract street number and route
    const streetNumber = componentMap['street_number']?.long_name || '';
    const route = componentMap['route']?.long_name || '';
    addressData.address = streetNumber && route ? `${streetNumber} ${route}` : place.formatted_address.split(',')[0];

    // Extract city (locality or sublocality)
    addressData.city = componentMap['locality']?.long_name || 
                      componentMap['sublocality']?.long_name || 
                      componentMap['neighborhood']?.long_name || '';

    // Extract state
    addressData.state = componentMap['administrative_area_level_1']?.short_name || '';

    // Extract ZIP code
    addressData.zip = componentMap['postal_code']?.long_name || '';

    console.log('Processed address data:', addressData);

    // Verify all required fields are present
    if (!addressData.address || !addressData.city || !addressData.state || !addressData.zip) {
      console.warn('Missing required address components:', addressData);
    }

    // Verify coordinates
    if (!addressData.coordinates.lat || !addressData.coordinates.lng) {
      console.warn('Missing coordinates:', addressData.coordinates);
    }

    onChange(addressData);
  };

  return (
    <div className="relative">
      <input
        ref={inputRef}
        type="text"
        placeholder="Enter address"
        defaultValue={value}
        className="w-full px-4 py-2 border rounded-lg"
      />
      {!isGoogleLoaded && (
        <div className="text-sm text-gray-500 mt-1">
          Loading Google Places...
        </div>
      )}
    </div>
  );
};

export default AddressAutocomplete; 