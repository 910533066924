import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft, Plus, X, Upload } from 'lucide-react';

const API_URL = process.env.REACT_APP_API_URL || '';

const RegionForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [communities, setCommunities] = useState([]);
  
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    communities: [],
    features: [
      {
        title: '',
        subtitle: '',
        image: '',
        text: '',
        buttonLink: '',
        buttonText: ''
      },
      {
        title: '',
        subtitle: '',
        image: '',
        text: '',
        buttonLink: '',
        buttonText: ''
      },
      {
        title: '',
        subtitle: '',
        image: '',
        text: '',
        buttonLink: '',
        buttonText: ''
      }
    ],
    manager: {
      name: '',
      email: '',
      phone: '',
      community_code: ''
    }
  });

  useEffect(() => {
    fetchCommunities();
    if (id) {
      fetchRegion();
    }
  }, [id]);

  const fetchCommunities = async () => {
    try {
      const response = await fetch(`${API_URL}/api/communities`, {
        headers: {
          'Authorization': 'Basic ' + btoa('admin:123456')
        }
      });
      if (response.ok) {
        const data = await response.json();
        setCommunities(data);
      }
    } catch (error) {
      console.error('Error fetching communities:', error);
    }
  };

  const fetchRegion = async () => {
    try {
      console.log('Fetching region with ID:', id);
      const response = await fetch(`${API_URL}/api/admin/regions/${id}`, {
        headers: {
          'Authorization': 'Basic ' + btoa('admin:123456')
        }
      });
      
      if (!response.ok) {
        throw new Error('Failed to fetch region');
      }
      
      const data = await response.json();
      console.log('Fetched region data:', data);
      
      setFormData({
        name: data.name || '',
        description: data.description || '',
        communities: data.communities?.map(c => c._id) || [],
        features: data.features || [
          { title: '', subtitle: '', image: '', text: '', buttonLink: '', buttonText: '' },
          { title: '', subtitle: '', image: '', text: '', buttonLink: '', buttonText: '' },
          { title: '', subtitle: '', image: '', text: '', buttonLink: '', buttonText: '' }
        ],
        manager: {
          name: data.manager?.name || '',
          email: data.manager?.email || '',
          phone: data.manager?.phone || '',
          community_code: data.manager?.community_code || ''
        }
      });
    } catch (error) {
      console.error('Error fetching region:', error);
      alert('Error loading region data. Please try again.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const url = id 
        ? `${API_URL}/api/admin/regions/${id}`
        : `${API_URL}/api/admin/regions`;

      const response = await fetch(url, {
        method: id ? 'PUT' : 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + btoa('admin:123456')
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        navigate('/admin/regions');
      } else {
        throw new Error('Failed to save region');
      }
    } catch (error) {
      console.error('Error saving region:', error);
      alert('Error saving region. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleImageUpload = async (e, featureIndex) => {
    const file = e.target.files[0];
    if (!file) return;

    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('image', file);

      const response = await fetch(`${API_URL}/api/upload`, {
        method: 'POST',
        headers: {
          'Authorization': 'Basic ' + btoa('admin:123456')
        },
        body: formData
      });

      if (!response.ok) throw new Error('Upload failed');
      
      const data = await response.json();
      
      setFormData(prev => {
        const newFeatures = [...prev.features];
        newFeatures[featureIndex].image = data.url;
        return { ...prev, features: newFeatures };
      });
    } catch (error) {
      console.error('Error uploading image:', error);
      alert('Error uploading image. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-8">
      <div className="max-w-4xl mx-auto">
        <div className="flex items-center gap-4 mb-8">
          <button
            onClick={() => navigate('/admin/regions')}
            className="text-gray-600 hover:text-gray-800"
          >
            <ArrowLeft size={24} />
          </button>
          <h1 className="text-2xl font-semibold flex-1">
            {id ? 'Edit Region' : 'Add New Region'}
          </h1>
        </div>

        <form onSubmit={handleSubmit} className="space-y-8">
          {/* Basic Information */}
          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-xl font-semibold mb-4">Basic Information</h2>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Region Name
                </label>
                <input
                  type="text"
                  value={formData.name}
                  onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                  className="w-full px-4 py-2 border rounded-lg"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Description
                </label>
                <textarea
                  value={formData.description}
                  onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
                  rows={4}
                  className="w-full px-4 py-2 border rounded-lg resize-none"
                />
              </div>
            </div>
          </div>

          {/* Communities Selection */}
          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-xl font-semibold mb-4">Communities</h2>
            <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
              {communities.map(community => (
                <label key={community._id} className="flex items-center gap-2 p-3 border rounded-lg hover:bg-gray-50">
                  <input
                    type="checkbox"
                    checked={formData.communities.includes(community._id)}
                    onChange={(e) => {
                      setFormData(prev => ({
                        ...prev,
                        communities: e.target.checked
                          ? [...prev.communities, community._id]
                          : prev.communities.filter(id => id !== community._id)
                      }));
                    }}
                    className="w-4 h-4 text-aegis-blue border-gray-300 rounded focus:ring-aegis-blue"
                  />
                  <span className="text-sm">{community.name}</span>
                </label>
              ))}
            </div>
          </div>

          {/* Features Section */}
          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-xl font-semibold mb-4">Features</h2>
            <div className="space-y-6">
              {formData.features.map((feature, index) => (
                <div key={index} className="border-b pb-6 last:border-0">
                  <h3 className="font-medium mb-4">Feature {index + 1}</h3>
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Title
                      </label>
                      <input
                        type="text"
                        value={feature.title}
                        onChange={(e) => {
                          const newFeatures = [...formData.features];
                          newFeatures[index].title = e.target.value;
                          setFormData(prev => ({ ...prev, features: newFeatures }));
                        }}
                        className="w-full px-4 py-2 border rounded-lg"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Subtitle
                      </label>
                      <input
                        type="text"
                        value={feature.subtitle}
                        onChange={(e) => {
                          const newFeatures = [...formData.features];
                          newFeatures[index].subtitle = e.target.value;
                          setFormData(prev => ({ ...prev, features: newFeatures }));
                        }}
                        className="w-full px-4 py-2 border rounded-lg"
                      />
                    </div>
                    <div className="col-span-2">
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Content
                      </label>
                      <textarea
                        value={feature.text}
                        onChange={(e) => {
                          const newFeatures = [...formData.features];
                          newFeatures[index].text = e.target.value;
                          setFormData(prev => ({ ...prev, features: newFeatures }));
                        }}
                        rows={4}
                        className="w-full px-4 py-2 border rounded-lg resize-none"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Button Text
                      </label>
                      <input
                        type="text"
                        value={feature.buttonText}
                        onChange={(e) => {
                          const newFeatures = [...formData.features];
                          newFeatures[index].buttonText = e.target.value;
                          setFormData(prev => ({ ...prev, features: newFeatures }));
                        }}
                        className="w-full px-4 py-2 border rounded-lg"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Button Link
                      </label>
                      <input
                        type="text"
                        value={feature.buttonLink}
                        onChange={(e) => {
                          const newFeatures = [...formData.features];
                          newFeatures[index].buttonLink = e.target.value;
                          setFormData(prev => ({ ...prev, features: newFeatures }));
                        }}
                        className="w-full px-4 py-2 border rounded-lg"
                      />
                    </div>
                    <div className="col-span-2">
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Feature Image
                      </label>
                      <div className="flex items-center gap-4">
                        <input
                          type="file"
                          accept="image/*"
                          onChange={(e) => handleImageUpload(e, index)}
                          className="hidden"
                          id={`feature-${index}`}
                        />
                        <label
                          htmlFor={`feature-${index}`}
                          className="px-4 py-2 bg-gray-100 rounded-lg cursor-pointer hover:bg-gray-200"
                        >
                          <Upload size={20} className="inline mr-2" />
                          Upload Image
                        </label>
                        {feature.image && (
                          <img
                            src={feature.image}
                            alt={feature.title}
                            className="h-12 w-20 object-cover rounded"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Manager Information */}
          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-xl font-semibold mb-4">Regional Manager Information</h2>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Name
                </label>
                <input
                  type="text"
                  value={formData.manager.name}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    manager: { ...prev.manager, name: e.target.value }
                  }))}
                  className="w-full px-4 py-2 border rounded-lg"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Email
                </label>
                <input
                  type="email"
                  value={formData.manager.email}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    manager: { ...prev.manager, email: e.target.value }
                  }))}
                  className="w-full px-4 py-2 border rounded-lg"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Phone
                </label>
                <input
                  type="tel"
                  value={formData.manager.phone}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    manager: { ...prev.manager, phone: e.target.value }
                  }))}
                  className="w-full px-4 py-2 border rounded-lg"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Community Code
                </label>
                <input
                  type="text"
                  value={formData.manager.community_code}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    manager: { ...prev.manager, community_code: e.target.value }
                  }))}
                  className="w-full px-4 py-2 border rounded-lg"
                />
              </div>
            </div>
          </div>

          {/* Submit Button */}
          <div className="flex justify-end">
            <button
              type="submit"
              disabled={loading}
              className={`px-6 py-2 rounded-lg text-white ${
                loading ? 'bg-gray-400' : 'bg-aegis-blue hover:bg-aegis-blue/90'
              }`}
            >
              {loading ? 'Saving...' : 'Save Region'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RegionForm; 