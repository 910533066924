import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, X, MapPin } from 'lucide-react';

const Navigation = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <nav className="bg-white shadow-md">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex items-center justify-between h-20">
          {/* Logo - Updated height */}
          <Link to="/" className="flex-shrink-0">
            <img 
              src="/images/aegis-living-logo-blue.svg" 
              alt="Aegis Living"
              className="h-12 sm:h-16"
            />
          </Link>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-8">
            <Link 
              to="/locations" 
              className="text-gray-600 hover:text-aegis-blue"
            >
              Our Communities
            </Link>
            <Link 
              to="/about" 
              className="text-gray-600 hover:text-aegis-blue"
            >
              About Us
            </Link>
            <Link 
              to="/contact" 
              className="text-gray-600 hover:text-aegis-blue"
            >
              Contact
            </Link>
            <Link
              to="/locations"
              className="inline-flex items-center gap-2 bg-aegis-blue text-white px-4 py-2 rounded-lg hover:bg-aegis-blue/90 transition-colors"
            >
              <MapPin size={18} />
              Find A Location
            </Link>
          </div>

          {/* Mobile Menu Button */}
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="md:hidden p-2 text-gray-600 hover:text-gray-900 hover:bg-gray-100 rounded-lg"
          >
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>

        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className="md:hidden py-4 border-t">
            <div className="space-y-2">
              <Link
                to="/locations"
                className="block px-4 py-2 text-gray-600 hover:bg-gray-50 rounded-lg"
                onClick={() => setIsMenuOpen(false)}
              >
                Our Communities
              </Link>
              <Link
                to="/about"
                className="block px-4 py-2 text-gray-600 hover:bg-gray-50 rounded-lg"
                onClick={() => setIsMenuOpen(false)}
              >
                About Us
              </Link>
              <Link
                to="/contact"
                className="block px-4 py-2 text-gray-600 hover:bg-gray-50 rounded-lg"
                onClick={() => setIsMenuOpen(false)}
              >
                Contact
              </Link>
              <Link
                to="/locations"
                className="block px-4 py-2 text-white bg-aegis-blue hover:bg-aegis-blue/90 rounded-lg mt-4"
                onClick={() => setIsMenuOpen(false)}
              >
                Find A Location
              </Link>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navigation; 