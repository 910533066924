import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Search, MapPin, X, ArrowRight, Filter } from 'lucide-react';
import * as LucideIcons from 'lucide-react';
import LocationSearch from './LocationSearch';
import { calculateDistance } from '../utils/distance';
import ClickToCall from './ClickToCall';

const API_URL = process.env.REACT_APP_API_URL || '';

const CommunitiesArchive = () => {
  const [communities, setCommunities] = useState([]);
  const [filteredCommunities, setFilteredCommunities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  
  // Filter states
  const [selectedAmenities, setSelectedAmenities] = useState([]);
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  
  // Derived states for filter options
  const [availableAmenities, setAvailableAmenities] = useState([]);
  const [availableStates, setAvailableStates] = useState([]);
  const [availableCities, setAvailableCities] = useState([]);

  // Add new state for user location
  const [userLocation, setUserLocation] = useState(null);

  // Add new state for mobile filters
  const [showMobileFilters, setShowMobileFilters] = useState(false);

  // Add this to your state declarations
  const [amenityFilterType, setAmenityFilterType] = useState('AND'); // 'AND' or 'OR'

  useEffect(() => {
    fetchCommunities();
  }, []);

  useEffect(() => {
    if (communities.length > 0) {
      // Extract unique amenities
      const amenities = new Set();
      communities.forEach(community => {
        community.amenities.forEach(amenity => {
          amenities.add(amenity.name);
        });
      });
      setAvailableAmenities(Array.from(amenities).sort());

      // Extract unique states
      const states = new Set(communities.map(c => c.location?.state).filter(Boolean));
      setAvailableStates(Array.from(states).sort());

      // Extract unique cities based on selected state
      const cities = new Set(
        communities
          .filter(c => !selectedState || c.location?.state === selectedState)
          .map(c => c.location?.city)
          .filter(Boolean)
      );
      setAvailableCities(Array.from(cities).sort());
    }
  }, [communities, selectedState]);

  // Apply filters whenever filter criteria change
  useEffect(() => {
    let filtered = [...communities];

    // Apply search term
    if (searchTerm) {
      const search = searchTerm.toLowerCase();
      filtered = filtered.filter(community => 
        community.name.toLowerCase().includes(search) ||
        community.subtitle?.toLowerCase().includes(search) ||
        community.location?.city?.toLowerCase().includes(search) ||
        community.location?.state?.toLowerCase().includes(search)
      );
    }

    // Apply amenity filters with AND/OR logic
    if (selectedAmenities.length > 0) {
      filtered = filtered.filter(community => {
        if (amenityFilterType === 'AND') {
          // All selected amenities must be present
          return selectedAmenities.every(amenity =>
            community.amenities.some(a => a.name === amenity)
          );
        } else {
          // At least one selected amenity must be present
          return selectedAmenities.some(amenity =>
            community.amenities.some(a => a.name === amenity)
          );
        }
      });
    }

    // Apply location filters
    if (selectedState) {
      filtered = filtered.filter(community => 
        community.location?.state === selectedState
      );
    }
    if (selectedCity) {
      filtered = filtered.filter(community => 
        community.location?.city === selectedCity
      );
    }

    // Sort by distance if user location is available
    if (userLocation?.coordinates) {
      filtered = filtered.map(community => ({
        ...community,
        distance: calculateDistance(
          userLocation.coordinates.lat,
          userLocation.coordinates.lng,
          community.location?.coordinates?.lat,
          community.location?.coordinates?.lng
        )
      })).sort((a, b) => a.distance - b.distance);
    }

    setFilteredCommunities(filtered);
  }, [communities, searchTerm, selectedAmenities, selectedState, selectedCity, userLocation, amenityFilterType]);

  const fetchCommunities = async () => {
    try {
      console.log('Fetching from:', `${API_URL}/api/communities/public`);
      const response = await fetch(`${API_URL}/api/communities/public`);
      if (response.ok) {
        const data = await response.json();
        console.log('Received data:', data);
        setCommunities(data.communities);
        setFilteredCommunities(data.communities);
        
        // Set available filter options
        setAvailableStates(data.filters.states);
        setAvailableCities(data.filters.cities);
        setAvailableAmenities(data.filters.amenities);
      } else {
        console.error('Error response:', await response.text());
      }
    } catch (error) {
      console.error('Error fetching communities:', error);
    } finally {
      setLoading(false);
    }
  };

  const clearFilters = () => {
    setSearchTerm('');
    setSelectedAmenities([]);
    setSelectedState('');
    setSelectedCity('');
  };

  // Add this helper function at the top of your component
  const renderIcon = (iconName) => {
    if (!iconName || typeof iconName !== 'string') return null;
    const IconComponent = LucideIcons[iconName];
    if (!IconComponent) return null;
    
    return (
      <IconComponent 
        className="w-5 h-5 text-aegis-blue" 
        aria-hidden="true"
      />
    );
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-xl text-gray-600">Loading...</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 py-8">
        {/* Location Search - For both mobile and desktop */}
        <div className="mb-8">
          <LocationSearch onLocationSelect={setUserLocation} />
        </div>

        <div className="flex flex-col md:flex-row gap-8">
          {/* Filters Sidebar - Desktop */}
          <div className="hidden md:block w-64 flex-shrink-0">
            <div className="bg-white rounded-lg shadow p-6 sticky top-8">
              <h2 className="text-lg font-semibold mb-4">Filter Communities</h2>
              
              {/* Search Box - Desktop */}
              <div className="mb-6">
                <div className="relative">
                  <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
                  <input
                    type="text"
                    placeholder="Search communities..."
                    className="w-full pl-10 pr-4 py-2 border rounded-lg"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
              </div>

              {/* Location Filters */}
              <div className="mb-6">
                <h3 className="font-medium mb-2">Location</h3>
                <select
                  className="w-full p-2 border rounded-lg mb-2"
                  value={selectedState}
                  onChange={(e) => {
                    setSelectedState(e.target.value);
                    setSelectedCity(''); // Reset city when state changes
                  }}
                >
                  <option value="">All States</option>
                  {availableStates.map(state => (
                    <option key={state} value={state}>{state}</option>
                  ))}
                </select>
                
                <select
                  className="w-full p-2 border rounded-lg"
                  value={selectedCity}
                  onChange={(e) => setSelectedCity(e.target.value)}
                  disabled={!selectedState}
                >
                  <option value="">All Cities</option>
                  {availableCities.map(city => (
                    <option key={city} value={city}>{city}</option>
                  ))}
                </select>
              </div>

              {/* Amenities Filter */}
              <div className="mb-6">
                <div className="flex items-center justify-between mb-2">
                  <h3 className="font-medium">Amenities</h3>
                  <div className="flex items-center bg-gray-100 rounded-lg p-1">
                    <button
                      className={`px-3 py-1 rounded-md transition-colors ${
                        amenityFilterType === 'AND'
                          ? 'bg-aegis-blue text-white'
                          : 'text-gray-600 hover:bg-gray-200'
                      }`}
                      onClick={() => setAmenityFilterType('AND')}
                    >
                      AND
                    </button>
                    <button
                      className={`px-3 py-1 rounded-md transition-colors ${
                        amenityFilterType === 'OR'
                          ? 'bg-aegis-blue text-white'
                          : 'text-gray-600 hover:bg-gray-200'
                      }`}
                      onClick={() => setAmenityFilterType('OR')}
                    >
                      OR
                    </button>
                  </div>
                </div>
                <p className="text-sm text-gray-500 mb-3">
                  {amenityFilterType === 'AND' 
                    ? 'Communities must have all selected amenities'
                    : 'Communities must have at least one selected amenity'
                  }
                </p>
                <div className="space-y-2 max-h-64 overflow-y-auto">
                  {availableAmenities.map(amenity => (
                    <label key={amenity} className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        checked={selectedAmenities.includes(amenity)}
                        onChange={(e) => {
                          setSelectedAmenities(prev =>
                            e.target.checked
                              ? [...prev, amenity]
                              : prev.filter(a => a !== amenity)
                          );
                        }}
                        className="rounded text-aegis-blue focus:ring-aegis-blue"
                      />
                      <span className="text-sm">{amenity}</span>
                    </label>
                  ))}
                </div>
              </div>

              {/* Clear Filters Button */}
              {(searchTerm || selectedAmenities.length > 0 || selectedState || selectedCity) && (
                <button
                  onClick={clearFilters}
                  className="w-full px-4 py-2 text-sm text-aegis-blue hover:bg-aegis-blue/5 rounded-lg border border-aegis-blue flex items-center justify-center gap-2"
                >
                  <X size={16} />
                  Clear All Filters
                </button>
              )}
            </div>
          </div>

          {/* Mobile Search and Filters */}
          <div className="md:hidden space-y-4 mb-6">
            {/* Search Box - Mobile */}
            <div className="relative">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
              <input
                type="text"
                placeholder="Search communities..."
                className="w-full pl-10 pr-4 py-2 border rounded-lg"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>

            {/* Filter Button - Mobile */}
            <button
              onClick={() => setShowMobileFilters(true)}
              className="w-full flex items-center justify-center gap-2 px-4 py-2 bg-white border rounded-lg text-gray-700"
            >
              <Filter size={20} />
              <span>Filter Communities</span>
            </button>
          </div>

          {/* Communities Grid */}
          <div className="flex-1">
            <div className="mb-6 flex justify-between items-center">
              <h1 className="text-2xl font-semibold">Our Communities</h1>
              <p className="text-gray-600 hidden md:block">
                Showing {filteredCommunities.length} of {communities.length} communities
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {filteredCommunities.map(community => (
                <div
                  key={community._id}
                  className="bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow"
                >
                  <div className="relative h-48">
                    <img
                      src={community.headerImage}
                      alt={community.name}
                      className="w-full h-full object-cover"
                    />
                    {userLocation && community.distance && (
                      <div className="absolute top-4 right-4 bg-white/90 backdrop-blur-sm px-3 py-1 rounded-full shadow-md">
                        <span className="text-sm font-medium text-aegis-blue">
                          {community.distance < 1 
                            ? 'Less than a mile away'
                            : `${community.distance.toFixed(1)} miles away`
                          }
                        </span>
                      </div>
                    )}
                    <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/60 to-transparent p-4">
                      <div className="text-white">
                        <h3 className="text-lg font-semibold">
                          Aegis Living {community.name}
                        </h3>
                        <div className="flex items-center gap-1 text-white/90 text-sm">
                          <MapPin size={14} />
                          <span>
                            {community.location.city}, {community.location.state}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="p-6">
                    <Link
                      to={`/community/${community.slug}`}
                      className="block"
                    >
                      <h2 className="text-xl font-semibold mb-2">{community.name}</h2>
                      {community.location && (
                        <div className="flex items-center gap-1 text-gray-600 mb-4">
                          <MapPin size={16} />
                          <span className="text-sm">
                            {community.location.city}, {community.location.state}
                          </span>
                        </div>
                      )}
                      <p className="text-gray-600 mb-4">{community.subtitle}</p>
                    </Link>

                    {/* Amenities Grid */}
                    <div className="grid grid-cols-3 gap-2 mb-4">
                      {community.amenities.slice(0, 6).map((amenity, index) => (
                        <div 
                          key={`${amenity.name}-${index}`}
                          className="flex flex-col items-center p-2 text-center bg-gray-50 rounded-lg"
                        >
                          {renderIcon(amenity.iconName)}
                          <span className="text-xs font-medium mt-1 text-gray-600">
                            {amenity.name}
                          </span>
                        </div>
                      ))}
                    </div>
                    
                    {/* Show count of additional amenities */}
                    {community.amenities.length > 6 && (
                      <p className="text-sm text-gray-500 mb-4">
                        +{community.amenities.length - 6} more amenities
                      </p>
                    )}

                    {/* View Location Button */}
                    <Link
                      to={`/community/${community.slug}`}
                      className="w-full bg-aegis-blue text-white px-4 py-2 rounded-lg flex items-center justify-center gap-2 hover:bg-aegis-blue/90 transition-colors"
                    >
                      View Location
                      <ArrowRight size={16} />
                    </Link>
                  </div>
                </div>
              ))}
            </div>

            {filteredCommunities.length === 0 && (
              <div className="text-center py-12">
                <p className="text-gray-600">
                  No communities found matching your criteria.
                </p>
                <button
                  onClick={clearFilters}
                  className="mt-4 text-aegis-blue hover:underline"
                >
                  Clear all filters
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Mobile Filters Modal */}
      {showMobileFilters && (
        <div className="fixed inset-0 z-50 bg-white">
          <div className="p-4 flex justify-between items-center border-b">
            <h3 className="text-lg font-semibold">Filters</h3>
            <button
              onClick={() => setShowMobileFilters(false)}
              className="p-2 text-gray-600"
            >
              <X size={24} />
            </button>
          </div>
          <div className="p-4 overflow-y-auto h-[calc(100vh-64px)]">
            {/* Location Filters */}
            <div className="mb-6">
              <h3 className="font-medium mb-2">Location</h3>
              <select
                className="w-full p-2 border rounded-lg mb-2"
                value={selectedState}
                onChange={(e) => {
                  setSelectedState(e.target.value);
                  setSelectedCity('');
                }}
              >
                <option value="">All States</option>
                {availableStates.map(state => (
                  <option key={state} value={state}>{state}</option>
                ))}
              </select>
              
              <select
                className="w-full p-2 border rounded-lg"
                value={selectedCity}
                onChange={(e) => setSelectedCity(e.target.value)}
                disabled={!selectedState}
              >
                <option value="">All Cities</option>
                {availableCities.map(city => (
                  <option key={city} value={city}>{city}</option>
                ))}
              </select>
            </div>

            {/* Amenities Filter */}
            <div className="mb-6">
              <div className="flex items-center justify-between mb-2">
                <h3 className="font-medium">Amenities</h3>
                <div className="flex items-center bg-gray-100 rounded-lg p-1">
                  <button
                    className={`px-3 py-1 rounded-md transition-colors ${
                      amenityFilterType === 'AND'
                        ? 'bg-aegis-blue text-white'
                        : 'text-gray-600 hover:bg-gray-200'
                    }`}
                    onClick={() => setAmenityFilterType('AND')}
                  >
                    AND
                  </button>
                  <button
                    className={`px-3 py-1 rounded-md transition-colors ${
                      amenityFilterType === 'OR'
                        ? 'bg-aegis-blue text-white'
                        : 'text-gray-600 hover:bg-gray-200'
                    }`}
                    onClick={() => setAmenityFilterType('OR')}
                  >
                    OR
                  </button>
                </div>
              </div>
              <p className="text-sm text-gray-500 mb-3">
                {amenityFilterType === 'AND' 
                  ? 'Communities must have all selected amenities'
                  : 'Communities must have at least one selected amenity'
                }
              </p>
              <div className="space-y-2 max-h-64 overflow-y-auto">
                {availableAmenities.map(amenity => (
                  <label key={amenity} className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      checked={selectedAmenities.includes(amenity)}
                      onChange={(e) => {
                        setSelectedAmenities(prev =>
                          e.target.checked
                            ? [...prev, amenity]
                            : prev.filter(a => a !== amenity)
                        );
                      }}
                      className="rounded text-aegis-blue focus:ring-aegis-blue"
                    />
                    <span className="text-sm">{amenity}</span>
                  </label>
                ))}
              </div>
            </div>

            {/* Apply Filters Button */}
            <button
              onClick={() => setShowMobileFilters(false)}
              className="w-full bg-aegis-blue text-white px-4 py-3 rounded-lg"
            >
              Apply Filters
            </button>
          </div>
        </div>
      )}
      
      <ClickToCall />
    </div>
  );
};

export default CommunitiesArchive; 