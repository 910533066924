import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { OverlayProvider } from '@react-aria/overlays';
import { useAuth, AuthProvider } from './contexts/AuthContext';
import AdminToolbar from './components/AdminToolbar';
import AegisFunnel from './components/AegisFunnel';
import WaterfrontFunnel from './components/WaterfrontFunnel';
import LeadsPage from './components/LeadsPage';
import AdminPanel from './components/AdminPanel';
import SuccessPage from './components/SuccessPage';
import CommunitiesList from './components/CommunitiesList';
import CommunityForm from './components/CommunityForm';
import CommunityPage from './components/CommunityPage';
import CommunitiesArchive from './components/CommunitiesArchive';
import Navigation from './components/Navigation';
import Settings from './components/Settings';
import AboutPage from './components/AboutPage';
import ContactPage from './components/ContactPage';
import LoginForm from './components/LoginForm';
import ClickToCall from './components/ClickToCall';
import PublicFooter from './components/PublicFooter';
import AdminFooter from './components/AdminFooter';
import Accessibility from './components/Accessibility';
import ChatWidgetWrapper from './components/ChatWidgetWrapper';
import CallLogPage from './components/CallLogPage';
import RegionsArchive from './components/RegionsArchive';
import RegionPage from './components/RegionPage';
import RegionsList from './components/RegionsList';
import RegionForm from './components/RegionForm';
import VideoLP from './components/VideoLP';
// Create a wrapper component to handle global layout
const AppLayout = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  const isAdminRoute = location.pathname.startsWith('/admin');
  const isVideoLP = location.pathname === '/video' || location.pathname === '/video/';
  
  // Get community data from URL if it's a community page
  const isCommunityPage = location.pathname.startsWith('/community/');
  const communitySlug = isCommunityPage ? location.pathname.split('/')[2] : null;

  // Check if current route has video button
  const hasVideoButton = location.pathname === '/' || 
                        location.pathname === '/waterfront-kirkland' ||
                        (isCommunityPage && communitySlug);

  return (
    <div className="flex flex-col min-h-screen overflow-x-hidden">
      {isAuthenticated && <AdminToolbar communitySlug={communitySlug} />}
      <div className="flex-grow overflow-x-hidden">
        {children}
      </div>
      {!isAdminRoute && !isVideoLP && <PublicFooter />}
      {!isAdminRoute && (
        <>
          <ClickToCall />
          <Accessibility />
        </>
      )}
    </div>
  );
};

// Protected Route component
const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <LoginForm />;
  }

  return children;
};

// Create a component to handle route changes
const RouteChangeTracker = () => {
  const location = useLocation();

  useEffect(() => {
    // Track Google Analytics pageview
    if (window.gtag) {
      window.gtag('event', 'page_view', {
        page_path: location.pathname + location.search,
        page_title: document.title,
        page_location: window.location.href
      });
      console.log('GA Event - Pageview:', location.pathname);
    }

    // Track Facebook Pixel pageview
    if (window.fbq) {
      window.fbq('track', 'PageView');
      console.log('FB Event - Pageview:', location.pathname);
    }

    // Track HubSpot pageview
    if (window._hsq) {
      window._hsq.push(['setPath', location.pathname + location.search]);
      window._hsq.push(['trackPageView']);
      console.log('HubSpot Event - Pageview:', location.pathname);
    }
  }, [location]); // Re-run when location changes

  return null;
};

// Main App component
function App() {
  return (
    <AuthProvider>
      <Router>
        <OverlayProvider>
          <RouteChangeTracker />
          <AppLayout>
            <Routes>
              {/* Public Routes */}
              <Route path="/" element={<AegisFunnel />} />
              <Route path="/waterfront-kirkland" element={<WaterfrontFunnel />} />
              <Route path="/community/:slug" element={<CommunityPage />} />
              <Route path="/locations" element={
                <>
                  <Navigation />
                  <CommunitiesArchive />
                </>
              } />
              <Route path="/about" element={
                <>
                  <Navigation />
                  <AboutPage />
                </>
              } />
              <Route path="/contact" element={
                <>
                  <Navigation />
                  <ContactPage />
                </>
              } />
              <Route path="/success" element={
                <>
                  <Navigation />
                  <SuccessPage />
                </>
              } />
              <Route path="/community/region" element={
                <>
                  <Navigation />
                  <RegionsArchive />
                </>
              } />
              <Route path="/community/region/:slug" element={
                <>
                  <Navigation />
                  <RegionPage />
                </>
              } />
              <Route path="/video" element={<VideoLP />} />

              {/* Protected Routes */}
              <Route path="/admin" element={
                <ProtectedRoute>
                  <AdminPanel />
                </ProtectedRoute>
              } />
              <Route path="/admin/leads" element={
                <ProtectedRoute>
                  <LeadsPage />
                </ProtectedRoute>
              } />
              <Route path="/admin/communities" element={
                <ProtectedRoute>
                  <CommunitiesList />
                </ProtectedRoute>
              } />
              <Route path="/admin/communities/add" element={
                <ProtectedRoute>
                  <CommunityForm />
                </ProtectedRoute>
              } />
              <Route path="/admin/communities/edit/:slug" element={
                <ProtectedRoute>
                  <CommunityForm />
                </ProtectedRoute>
              } />
              <Route path="/admin/settings" element={
                <ProtectedRoute>
                  <Settings />
                </ProtectedRoute>
              } />
              <Route path="/admin/calllog" element={
                <ProtectedRoute>
                  <CallLogPage />
                </ProtectedRoute>
              } />
              <Route path="/admin/regions" element={
                <ProtectedRoute>
                  <RegionsList />
                </ProtectedRoute>
              } />
              <Route path="/admin/regions/add" element={
                <ProtectedRoute>
                  <RegionForm />
                </ProtectedRoute>
              } />
              <Route path="/admin/regions/edit/:id" element={
                <ProtectedRoute>
                  <RegionForm />
                </ProtectedRoute>
              } />
            </Routes>
          </AppLayout>
        </OverlayProvider>
      </Router>
    </AuthProvider>
  );
}

export default App; 