import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { MapPin, ArrowRight } from 'lucide-react';
import { calculateDistance } from '../utils/distance';

const NearbyLocations = ({ currentCommunity, allCommunities }) => {
  const navigate = useNavigate();

  const handleLocationClick = (slug) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    navigate(`/community/${slug}`);
  };

  // Filter out current community and calculate distances
  const nearbyLocations = allCommunities
    .filter(community => 
      community.slug !== currentCommunity.slug &&
      community.location?.coordinates?.lat &&
      community.location?.coordinates?.lng
    )
    .map(community => ({
      ...community,
      distance: calculateDistance(
        currentCommunity.location.coordinates.lat,
        currentCommunity.location.coordinates.lng,
        community.location.coordinates.lat,
        community.location.coordinates.lng
      )
    }))
    .sort((a, b) => a.distance - b.distance)
    .slice(0, 3);

  if (nearbyLocations.length === 0) return null;

  return (
    <div className="max-w-7xl mx-auto px-4 py-12">
      <div className="text-center mb-12">
        <h2 className="text-3xl font-semibold text-gray-800 mb-4">Nearby Locations</h2>
        <p className="text-xl text-gray-600">
          Discover other Aegis Living communities in your area
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {nearbyLocations.map(community => (
          <div
            key={community._id}
            className="bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow"
          >
            <button
              onClick={() => handleLocationClick(community.slug)}
              className="block w-full text-left"
            >
              <div className="relative h-48">
                <img
                  src={community.headerImage}
                  alt={community.name}
                  className="w-full h-full object-cover"
                />
                <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/60 to-transparent p-4">
                  <div className="text-white">
                    <h3 className="text-lg font-semibold">
                      Aegis Living {community.name}
                    </h3>
                    <div className="flex items-center gap-1 text-white/90 text-sm">
                      <MapPin size={14} />
                      <span>
                        {community.location.city}, {community.location.state}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-4">
                <p className="text-gray-600 mb-4">{community.subtitle}</p>
                <div className="flex items-center justify-between">
                  <div className="text-sm text-aegis-blue">
                    {community.distance < 1 
                      ? 'Less than a mile away'
                      : `${community.distance.toFixed(1)} miles away`
                    }
                  </div>
                  <div
                    className="inline-flex items-center gap-1 bg-aegis-blue text-white px-3 py-1 rounded hover:bg-aegis-blue/90 transition-colors"
                  >
                    Get Details
                    <ArrowRight size={14} />
                  </div>
                </div>
              </div>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NearbyLocations; 