import React from 'react';

const PhoneInput = ({ value, onChange, className, onKeyPress, autoFocus }) => {
  const formatPhoneNumber = (input) => {
    // Remove all non-numeric characters
    let cleaned = input.replace(/\D/g, '');
    
    // Remove leading '1' if it exists (country code)
    if (cleaned.startsWith('1')) {
      cleaned = cleaned.substring(1);
    }
    
    // Ensure we only use the last 10 digits if longer
    if (cleaned.length > 10) {
      cleaned = cleaned.slice(-10);
    }
    
    // Format the number as (xxx) xxx-xxxx
    if (cleaned.length >= 10) {
      return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6, 10)}`;
    } else if (cleaned.length > 6) {
      return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
    } else if (cleaned.length > 3) {
      return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3)}`;
    } else if (cleaned.length > 0) {
      return `(${cleaned}`;
    }
    return cleaned;
  };

  const handleChange = (e) => {
    const formatted = formatPhoneNumber(e.target.value);
    onChange(formatted);
  };

  return (
    <input
      type="tel"
      placeholder="Phone Number"
      className={className}
      value={value}
      onChange={handleChange}
      onKeyPress={onKeyPress}
      autoComplete="tel"
      autoFocus={autoFocus}
    />
  );
};

export default PhoneInput; 